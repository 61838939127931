import { getValue } from "@utils/lodash";
import React from "react";

function OrganisationsSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        // width="18"
        // height="18"
        viewBox="0 0 18 18"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_17497"
              data-name="Rectangle 17497"
              width="18"
              height="18"
              transform="translate(2364 -17797)"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g
          id="Mask_Group_162"
          data-name="Mask Group 162"
          transform="translate(-2364 17797)"
          clip-path="url(#clip-path)"
        >
          <g id="corporate_fare_black_24dp" transform="translate(2364 -17797)">
            <rect
              id="Rectangle_17498"
              data-name="Rectangle 17498"
              width="18"
              height="18"
              fill="none"
            />
            <path
              id="Path_46810"
              data-name="Path 46810"
              d="M9,5.25v-3H1.5v13.5h15V5.25Zm-1.5,9H3v-1.5H7.5Zm0-3H3V9.75H7.5Zm0-3H3V6.75H7.5Zm0-3H3V3.75H7.5Zm7.5,9H9V6.75h6Zm-1.5-6h-3v1.5h3Zm0,3h-3v1.5h3Z"
              //   fill="#3c4043"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#3c4043"
              }
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default OrganisationsSvgComponent;
