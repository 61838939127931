import { Modal, ModalFooter } from "reactstrap";
import { getValue } from "@utils/lodash";
import InfoSvgComponent from "@assets/svg-components/info";
import { getColorStyle, getStepColors } from "@common/color";
import { formatText, sortJSONObjectArray } from "@common/text-helpers";
import { convertCurrentDate } from "@common/date-helpers";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";

const SubmitAdminConfirmModal = (props: any) => {
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      //   toggle={handleModal}
    >
      <div
        className="p-3"
        style={{
          background: "#f8f8fd",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center w-100">
            <InfoSvgComponent size={28} color={"#FD9933"} />
            <h6 className="header_text__18 ms-2">Confirm</h6>
          </div>
          <div onClick={props.handleModal}>
            <CloseSmallSvgComponent size={18} />
          </div>
        </div>
      </div>
      <div className="mt-2 px-3">
        <h6 className="header_text__17">Approvers</h6>
      </div>
      <div className="approver_flow_main_container px-3">
        {getValue(props, `approvers.length`, 0) > 0 &&
          sortJSONObjectArray(
            getValue(props, `approvers`, []),
            "approval_level"
          ).map((item: object) => {
            return (
              <div className="approver_flow_container m-3 p-3 position-relative ">
                <div className="d-flex ms-3 align-items-center">
                  <div
                    className="profile_circle"
                    style={{
                      background: getStepColors(
                        getValue(item, `approval_level`, "")
                      ),
                    }}
                  >
                    <h5 className="title">
                      {getValue(item, `approval_level`, "")}
                    </h5>
                  </div>
                  <div className="ms-2">
                    <div>
                      <div>
                        <h6 className="header_text__17">
                          {getValue(item, `approver.first_name`, "")}{" "}
                          {getValue(item, `approver.middle_name`, "")}{" "}
                          {getValue(item, `approver.last_name`, "")}
                        </h6>
                        <p className="small_text">
                          {getValue(item, `approver.email`, "")}
                        </p>
                      </div>
                    </div>
                    {(getValue(item, `submitted_at`, "") ||
                      getValue(item, `approved_at`, "")) && (
                      <div className="border-radius__5 mt-3">
                        {getValue(item, `submitted_at`, "") && (
                          <div className="d-flex align-items-center">
                            <p className=" small_text__12">Submitted On:</p>
                            <p className=" header_text__12 ms-1">
                              {convertCurrentDate(
                                getValue(item, `submitted_at`, "")
                              )}
                            </p>
                          </div>
                        )}
                        {getValue(item, `approved_at`, "") && (
                          <div className="d-flex align-items-center mt-1">
                            <p className=" small_text__12">Approved On:</p>
                            <p className=" header_text__12 ms-1">
                              {convertCurrentDate(
                                getValue(item, `approved_at`, "")
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <h6
                  className="header_text status-poistion-absolute"
                  style={getColorStyle(getValue(item, `approval_status`, ""))}
                >
                  {formatText(getValue(item, `approval_status`, ""))}
                </h6>
              </div>
            );
          })}
      </div>
      <ModalFooter>
        <div className="delete-popup-wrapper__cta-wrapper">
          <button
            onClick={() => props.handleSubmit("current")}
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--primary"
          >
            Skip Current Approver
          </button>
          <button
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--primary ms-2"
            onClick={() => props.handleSubmit("all")}
          >
            Skip All Approvers
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SubmitAdminConfirmModal;
