import InputField from "@common/InputRuleFeild";
import { formHandleBooleanArrayChange } from "@common/handleChange";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";

function ExpenseLimitPage(props: any) {
  const { request, setRequest, request2, setRequest2, commentRequest, setCommentRequest } = props;
  const handleNavigate = async (item: object) => {
    setRequest2({
      ...request2,
      expense_type: getValue(item, `expense_type.id`, "")
        ? getValue(item, `expense_type.id`, "")
        : getValue(item, `id`, ""),
      expense_amount_limit: getValue(item, `expense_amount_limit`, ""),
      receipt_required_limit: getValue(item, `receipt_required_limit`, ""),
      is_description_required: getValue(item, `is_description_required`, false)
        ? true
        : false,
      id: getValue(item, `expense_type.id`, "") ? getValue(item, `id`, "") : "",
    });
    props.handleModal1();
  };

  const handleNavigateComment = async (item: object) => {
    console.log(item, "---ITEM---");
    setCommentRequest({
      ...commentRequest,
      comments: getValue(item, `comments`, ""),
      expense_type: getValue(item, `expense_type.id`, "")
        ? getValue(item, `expense_type.id`, "")
        : getValue(item, `id`, ""),
      id: getValue(item, `expense_type.id`, "") ? getValue(item, `id`, "") : "",
    });
    props.handleModalComment();
  };
  return (
    <div className="expense-limits-tabs-content-wrap ">
      {/* m-5 px-3 */}
      <div className="expense-limits-header-text">
        <h6 className="header_text">General Limits</h6>
        {/* desc_text */}
        <p className="sub-text">
          General limits will be applicable for all the categories that are
          enabled.
        </p>
      </div>

      <div className="modules_fields_container_plociy">
        <table className="modules_fields_table">
          <thead className="modules_fields_thead">
            <th className="modules_fields_th" style={{ width: "200px" }}>
              EXPENSE AMOUNT LIMIT
            </th>
            <th className="modules_fields_th" style={{ width: "200px" }}>
              RECEIPT REQUIRED LIMIT
            </th>
            <th className="modules_fields_th" style={{ width: "200px" }}>
              DESCRIPTION REQUIRED
            </th>
            <th className="modules_fields_th"></th>
            {/* style={{ border: "none" }} */}
          </thead>
          <tbody className="modules_fields_tbody">
            <tr className="modules_fields_tr">
              <td className="modules_fields_td_checkbox  p-4">
                {getValue(request, `expense_amount_limit`, "-")}
              </td>
              <td className="modules_fields_td_checkbox">
                {getValue(request, `receipt_required_limit`, "-")}
              </td>
              <td className="modules_fields_td_checkbox">
                {getValue(request, `is_description_required`, "")
                  ? "Yes"
                  : "No"}
              </td>
              {getValue(props, `permissions`, []).includes("update") ? (
                <td
                  className="modules_fields_td_checkbox"
                  onClick={props.handleModal}
                >
                  <div className="close-image_container ms-4">
                    <img
                      src="/images/edit-gray.svg"
                      className="close_image img-fluid"
                    />
                  </div>
                </td>
              ) : (
                <td className="modules_fields_td_checkbox"></td>
              )}
            </tr>
          </tbody>
        </table>
      </div>

      {/* {getValue(props, `params.id`, "") && ( */}
      <>
        <div className="expense-limits-header-text">
          <h6 className="header_text">Expense Limits</h6>
          {/* desc_text */}
          <p className="sub-text">
            Set up a limit for each expense type based on your business needs.
            Also, you can enable the expense type with which your employees can
            create expenses
          </p>
        </div>
        <div className="modules_fields_container_plociy">
          <table className="modules_fields_table">
            <thead className="modules_fields_thead">
              {/* <th className="datatable_table_th" style={{ width: "40px" }}>
              <label
                className="m-0 position-relative"
                htmlFor="header-checkbox"
              >
                <input
                  type="checkbox"
                  className="default-checkbox"
                  id="header-checkbox"
                  onChange={() =>
                    props.handleCheckAll(
                      getValue(props, `list.length`, 0) ===
                        getValue(props, `selectedIDs.length`, 0)
                        ? "All"
                        : "None"
                    )
                  }
                  checked={
                    getValue(props, `list.length`, 0) ===
                    getValue(props, `selectedIDs.length`, 0)
                  }
                />
                <div className="custom-checkbox">
                  <img
                    src="/images/uploaded-details/tick.svg"
                    className="img-fluid uploaded-tick"
                  />
                </div>
              </label>
            </th> */}
              <th
                className="modules_fields_th text-center"
                style={{ width: "120px" }}
              >
                Expense <br /> Type Name
              </th>
              <th
                className="modules_fields_th text-center"
                style={{ width: "100px" }}
              >
                Expense <br /> Amount Limit
              </th>
              <th
                className="modules_fields_th text-center"
                style={{ width: "100px" }}
              >
                Receipt <br /> Required Limit
              </th>
              <th
                className="modules_fields_th text-center"
                style={{ width: "120px" }}
              >
                Description <br /> Required
              </th>
              <th
                className="modules_fields_th text-center"
                style={{ width: "120px" }}
              >
                Document <br /> Required
              </th>
              <th
                className="modules_fields_th text-center"
                style={{ width: "50px" }}
              >
                  Hard <br /> Stop
              </th>
              <th
                className="modules_fields_th text-center"
                style={{ width: "120px" }}
              >
                Comments
              </th>
              <th className="modules_fields_th" style={{ width: "80px" }} ></th>
            </thead>
            <tbody className="modules_fields_tbody">
              {getValue(props, `list.length`, 0) > 0 &&
                getValue(props, `list`, []).map(
                  (item: object, index: number) => {
                    return (
                      <tr className="modules_fields_tr">
                        <td className="modules_fields_td_checkbox  p-4" style={{ width: "120px", maxWidth: "120px"}}>
                          {getValue(item, `expense_type_name`, "")}
                        </td>
                        <td className="modules_fields_td_checkbox">
                          {getValue(item, `expense_amount_limit`, "-")}
                        </td>
                        <td className="modules_fields_td_checkbox">
                          {getValue(item, `receipt_required_limit`, "-")}
                        </td>
                        <td className="modules_fields_td_checkbox">
                          {getValue(request, `is_general_limit`, false)
                            ? getValue(item, `is_description_required`, "")
                              ? "Yes"
                              : "No"
                            : null}
                        </td>
                        <td className="modules_fields_td_checkbox">
                          {getValue(props, `permissions`, []).includes(
                            "update"
                          ) ? (
                            <input
                              type="checkbox"
                              className="common-checkbox"
                              id={`header-checkbox-${index}1`}
                              onChange={(e) => {
                                e.stopPropagation();
                                props.handleSubmitDocument(
                                  !getValue(
                                    item,
                                    `is_receipt_mandatory`,
                                    false
                                  ),
                                  getValue(item, `expense_type.id`, "")
                                    ? getValue(item, `expense_type.id`, "")
                                    : getValue(item, `id`, ""),
                                  getValue(item, `expense_amount_limit`, ""),
                                  getValue(item, `receipt_required_limit`, ""),
                                  getValue(item, `expense_type.id`, "")
                                    ? getValue(item, `id`, "")
                                    : "",
                                    getValue(item, `is_hard_stop`, false),
                                    getValue(item, `comments`, "")
                                );
                              }}
                              checked={getValue(
                                item,
                                `is_receipt_mandatory`,
                                false
                              )}
                            />
                          ) : null}
                        </td>

                        <td className="modules_fields_td_checkbox">
                          {getValue(props, `permissions`, []).includes(
                            "update"
                          ) ? (
                            <input
                              type="checkbox"
                              className="common-checkbox"
                              id={`header-checkbox-${index}1`}
                              onChange={(e) => {
                                e.stopPropagation();
                                props.handleSubmitDocument(
                                  getValue(item, `is_receipt_mandatory`, false),
                                  getValue(item, `expense_type.id`, "")
                                    ? getValue(item, `expense_type.id`, "")
                                    : getValue(item, `id`, ""),
                                  getValue(item, `expense_amount_limit`, ""),
                                  getValue(item, `receipt_required_limit`, ""),
                                  getValue(item, `expense_type.id`, "")
                                    ? getValue(item, `id`, "")
                                    : "",
                                  !getValue(
                                    item,
                                    `is_hard_stop`,
                                    false
                                  ),
                                  getValue(item, `comments`, "")
                                );
                              }}
                              checked={getValue(
                                item,
                                `is_hard_stop`,
                                false
                              )}
                              disabled={!getValue(item, `expense_amount_limit`, "")}
                            />
                          ) : null}
                        </td>
                        <td className="modules_fields_td">
                          {getValue(item, `comments`, "")}
                        </td>
                        <td className="modules_fields_td">
                          {getValue(props, `permissions`, []).includes(
                            "update"
                          ) ? (
                            <div className="d-flex">
                              <div
                                className="close-image_container ms-3"
                                onClick={() => handleNavigate(item)}
                              >
                                <img
                                  src="/images/edit-gray.svg"
                                  className="close_image img-fluid"
                                />
                              </div>

                              <div
                                className="close-image_container ms-3"
                                onClick={() => handleNavigateComment(item)}
                              >
                                <img
                                  src="/images/chat.svg"
                                  className="close_image img-fluid"
                                />
                              </div>
                              {getValue(item, `expense_amount_limit`, "") && (
                                <div
                                  className="close-image_container ms-2"
                                  onClick={() =>
                                    props.handleDeleteEntity(
                                      getValue(item, `main_id`, ""),
                                      getValue(item, `expense_type_name`, "")
                                    )
                                  }
                                >
                                  <img
                                    src="/images/delete-gray.svg"
                                    className="close_image img-fluid"
                                  />
                                </div>
                              )}
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
      </>
      {/* )} */}
    </div>
  );
}

export default ExpenseLimitPage;
