import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import InfoSection from "./components/info";
import TaskSection from "./components/tasksection";
import ReportsummarySection from "./components/reportsummary";
import { convertDate } from "@common/date-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import { dashboardUsereSummary } from "@services/dashboard.service";
import { getValue } from "@utils/lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import Loader from "@components/common/Loader";
import DashboardQuickAddSection from "./components/quick-add";
import NoAccessPage from "@components/common/NoAccess";

function ClientDashboard(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [info, setInfo] = React.useState<[]>([]);
  const [tripInfo, setTripInfo] = useState({});
  const [expenseCategoryInfo, setExpenseCategoryInfo] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      const currentMonth = moment().startOf("month");
      const firstDay: any = currentMonth.format("YYYY-MM-DD");
      const lastDay: any = currentMonth.endOf("month").format("YYYY-MM-DD");
      setFirstDay(firstDay);
      setLastDay(lastDay);
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      const currentMonth = moment().startOf("month");
      const firstDay: any = currentMonth.format("YYYY-MM-DD");
      const lastDay: any = currentMonth.endOf("month").format("YYYY-MM-DD");
      let from = urlParams.from_date
        ? new Date(urlParams.from_date)
        : new Date(firstDay);
      let to = urlParams.to_date
        ? new Date(urlParams.to_date)
        : new Date(lastDay);
      setFirstDay(from);
      setLastDay(to);
      getData();
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const monthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [dashboardInfo, setDashboardInfo] = useState({});
  const getData = async () => {
    const currentMonth = moment().startOf("month");
    const firstDay = currentMonth.format("YYYY-MM-DD");
    const lastDay = currentMonth.endOf("month").format("YYYY-MM-DD");

    let from = urlParams.from_date ? new Date(urlParams.from_date) : firstDay;
    let to = urlParams.to_date ? new Date(urlParams.to_date) : lastDay;
    try {
      setIsLoading(true);
      let payload = {
        // from_date: convertDate(from),
        // to_date: convertDate(to),
      };
      let queryRequest = QueryRequestHelper(payload);
      let userResponse = await dashboardUsereSummary(queryRequest);
      if (userResponse) {
        setDashboardInfo(getValue(userResponse, `data`, {}));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                              Date Routes                                   */
  /* -------------------------------------------------------------------------- */
  const [from_date, setFirstDay] = React.useState(new Date());
  const [to_date, setLastDay] = React.useState(new Date());

  const handleDate = (date: any) => {
    setFirstDay(date[0]);
    setLastDay(date[1]);
  };
  const handleSubmitDate = () => {
    let first = moment(from_date).format("YYYY-MM-DD");
    let last = moment(to_date).format("YYYY-MM-DD");
    if (first && last && first !== "Invalid date" && last !== "Invalid date") {
      let payload: any = {
        from_date: first,
        to_date: last,
      };
      if (payload.from_date && payload.to_date) {
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
      } else {
        toast.error("Start Date and End Date Required");
      }
    } else {
      toast.error("Please select date");
    }
  };

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "dashboard"
      ) ? (
        <NoAccessPage />
      )  : (
        <>
          <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
            <h4 className="dashbaord-wrapper__header-title">Dashboard</h4>
            <div>
              <h3 className="dashboard-compliance__date dashboard-compliance__date--compliance overview-wrapper__header-date d-flex align-items-center">
                <div className="d-flex">
                  {/* <DatePicker
                selectsRange={true}
                startDate={from_date}
                endDate={to_date}
                className="react-date-picker__inputGroup__input_dashboard"
                onChange={(update: any) => {
                  handleDate(update);
                }}
                dateFormat="d MMM yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <button
                className="dashboard-wrapper__apply-btn"
                onClick={handleSubmitDate}
              >
                Apply
              </button> */}
                </div>
              </h3>
            </div>
          </div>
          <div className="dashbaord-wrapper_section_container">
            {isLoading || !props.permissionAPITriggered ? (
              <Loader />
            ) : (
              <div className="">
                {/* <InfoSection /> */}
                <DashboardQuickAddSection />
                <div className="border_bottom mt-3 mb-3"></div>
                <TaskSection dashboardInfo={dashboardInfo} />
                {/* <ReportsummarySection /> */}
              </div>
            )}
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default ClientDashboard;
