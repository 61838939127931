import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import DateSvgComponent from "@assets/svg-components/trips/date";
import {
  formHandleDateArrayChangeByKey,
  formHandleSelectArrayChangeByKey,
} from "@common/handleChange";
import { getValue } from "@utils/lodash";
import DatePicker from "react-datepicker";

function TripDateComponent(props: any) {
  const datePickerStyles = {
    borderRadius: "0", // Remove border radius
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    transition: "none", // Remove transition effect
    border: "0px",
  };
  const { item, index, validator, date_key, label, null_date } = props;
  return (
    <div>
      <div className="d-flex">
        <label className="trip-label ms-0">{label}</label>
        <span className="form-label-error_red">*</span>
      </div>
      <div
        className={`d-flex justify-content-between align-items-center ${
          validator &&
          validator.current.message(
            "From Date",
            getValue(item, `${date_key}`, ""),
            "required"
          )
            ? "border_red"
            : "border"
        } border-radius__5  cursor-pointer flight_date_section`}
        style={{
          position: "relative",
          display: "inline-block",
        }}
      >
        <div className="d-flex align-items-center w-100">
          <DateSvgComponent />
          <DatePicker
            selected={
              getValue(item, `${date_key}`, "")
                ? new Date(getValue(item, `${date_key}`, ""))
                : null
            }
            onChange={(date: any) => {
              // Handle the selected date
              formHandleDateArrayChangeByKey(
                index,
                date,
                date_key,
                props.setItenaries
              );
              null_date &&
                formHandleDateArrayChangeByKey(
                  index,
                  "",
                  null_date,
                  props.setItenaries
                );
            }}
            className={
              "trips_multi_city_datepicker react-datepicker-wrapper ml-8"
            }
            dateFormat="dd MMM yyyy"
            placeholderText={`Select ${label}`}
            showMonthDropdown
            showYearDropdown
            customInput={
              <input
                className="trips_multi_city_datepicker"
                style={datePickerStyles}
              />
            }
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            popperPlacement="bottom-start"
            popperClassName="no-hover-border-datepicker"
            minDate={
              getValue(props, `minDate`, "") ? props.minDate : new Date()
            }
          />
          {getValue(item, `${date_key}`, "") ? (
            <div
              className=""
              onClick={() =>
                formHandleSelectArrayChangeByKey(
                  index,
                  "",
                  date_key,
                  props.setItenaries
                )
              }
            >
              <CloseSmallSvgComponent />
            </div>
          ) : (
            <ArrowDownSvgComponent color={"gray"} />
          )}
        </div>
      </div>
    </div>
  );
}

export default TripDateComponent;
