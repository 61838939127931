import { config } from "../../env";

export function authHeaderEmail(token) {  
  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      "x-tenant-origin":
        window.location.host === "localhost:3000"
          ? config.SOURCE
          : window.location.host,
    };
  } else {
    return {
      "Content-Type": "application/json",
      "x-tenant-origin":
        window.location.host === "localhost:3000"
          ? config.SOURCE
          : window.location.host,
    };
  }
}
