import "./Header.scss";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { clientLogout } from "@services/auth.service";
import { useNavigate } from "react-router-dom";
import MyViewSvgComponent from "@assets/svg-components/my-view";
import AdminViewSvgComponent from "@assets/svg-components/admin-view";
import { getAllOrganisation } from "@services/organisation.service";
import { formatText } from "@common/text-helpers";
import { useNavigationData } from "../LeftNavbar/NavigationContext";
import PersonSvgComponent from "@assets/svg-components/person";
import { getColorStyle } from "@common/color";
import UsercontrolSvgComponent from "@assets/svg-components/sidebar/usercontrol";
import { Tooltip } from "react-tooltip";
function HeaderLogin(props: any) {
  const navigate = useNavigate();
  const handleNavigateHome = (view: string) => {
    if (view === "admin") {
      navigate(`/admin/dashboard`);
    } else {
      navigate(`/`);
    }
  };
  return (
    <div>
      <header className="header-wrapper d-flex align-items-center">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center cursor-pointer">
            <a
              //   onClick={() =>
              //     handleNavigateHome(getValue(localStorage, `view`, ""))
              //   }
              className="d-inline-block"
            >
              <img
                src={
                  getValue(localStorage, `organisation_logo`, "")
                    ? getValue(localStorage, `organisation_logo`, "")
                    : "/images/tripx_logo_new.png"
                }
                // src="/images/demo.svg"
                width="100%"
                height="100%"
                className="img-fluid header_logo"
              />
            </a>
          </div>
          {getValue(localStorage, `user`, "") && <div></div>}
        </div>
      </header>
    </div>
  );
}

export default HeaderLogin;
