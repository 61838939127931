import { QueryRequestHelper } from "@common/query-request-helper";
import FormBuilder from "@components/common/FormBuilder";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../reports.scss";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import { mediaUpload } from "@services/upload.service";
import SimpleReactValidator from "simple-react-validator";
import {
  FormAppendDataRequestHelper,
  FormRequestHelper,
} from "@components/common/FormBuilder/request-helper";
import { toast } from "react-toastify";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import DocumentImageList from "@common/FileUpload/document-image-list";
import {
  createExpenseReport,
  getAllExpenseFormReport,
  getAllExpenseReportFormEdit,
  updateExpenseReport,
} from "@services/expense-reports.service";
import NoAccessPage from "@components/common/NoAccess";
import Loader from "@components/common/Loader";

function ClientReportCreate(props: any) {
  let navigate = useNavigate();
  let params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [form, setForm] = React.useState<[]>([]);
  const [formInfo, setFormInfo] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    // if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
    getData();
    // }
  }, [props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    let payload: any = {};
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp;
      if (getValue(params, `id`, "")) {
        resp = await getAllExpenseReportFormEdit(getValue(params, `id`, ""));
      } else {
        resp = await getAllExpenseFormReport(queryRequest);
      }
      if (resp) {
        setFormInfo(getValue(resp, `data`, {}));
        if (getValue(params, `id`, "")) {
          setForm(getValue(resp, `data.form_layout`, []));
          let length = getValue(resp, `data.form_layout.length`, 0);
          let array = getValue(resp, `data.form_layout`, []);
          for (let i = 0; i < length; i++) {
            FormAppendDataRequestHelper(
              array[i],
              getValue(resp, `data.expense`, {})
            );
          }
        } else {
          setForm(getValue(resp, `data.form_layout`, []));
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        requestPayload["receipt_ids"] =
          getValue(documents, `length`, 0) > 0
            ? documents.map((item: object) => getValue(item, `id`, ""))
            : [];
        let resp;
        if (getValue(params, `id`, "")) {
          resp = await updateExpenseReport(
            getValue(params, `id`, ""),
            removeNullOrUndefinedProperties(requestPayload)
          );
        } else {
          resp = await createExpenseReport(
            removeNullOrUndefinedProperties(requestPayload)
          );
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          navigate(`/client/reports`);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  const [documents, setDocuments] = useState<any>([]);
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          e = null;
        } else {
          e = null;
        }
      } catch (error) {
        e = null;
      }
    });
  };

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "reports"
      ) ? (
        <NoAccessPage />
      )  : (
        <>
          <div className="expense-create-wrapper">
            <div className="expense-create-wrapper__header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <NavLink
                  to={
                    getValue(params, `id`, "")
                      ? `/client/reports/detail/${getValue(params, `id`, "")}`
                      : "/client/reports"
                  }
                  className="expense-create-wrapper__header-back-link"
                >
                  <div className="common_image_container">
                    <img
                      src="/images/back-link.svg"
                      className="img-fluid common_image_big"
                    />
                  </div>
                </NavLink>
                <a href="#0" className="expense-create-wrapper__add-user-info ">
                  {getValue(params, `id`, "") ? "Update" : "New"} Expense
                </a>
              </div>
              <div className="expense-create-wrapper__cta-btns">
                <NavLink
                  to={
                    getValue(params, `id`, "")
                      ? `/client/reports/detail/${getValue(params, `id`, "")}`
                      : "/client/reports"
                  }
                >
                  <button className="ascent-button ascent-button--header-buttons ascent-button--primary">
                    Cancel
                  </button>
                </NavLink>
                <button
                  className="ascent-button ascent-button--header-buttons ascent-button--secondary"
                  onClick={handleSubmit}
                  disabled={submitLoading}
                >
                  {submitLoading
                    ? "Please wait..."
                    : getValue(params, `id`, "")
                    ? "Update"
                    : "Save"}
                </button>
              </div>
            </div>

            <div className="expense-create-wrapper_container">
              <div className="expense-create-wrapper_left">
                <div className="expense-create-wrapper_left_container">
                  <h6 className="expense-create-wrapper_left_container_header">
                    Attach Report from Computer or Cloud
                  </h6>
                  <DragAndDropFileComponent
                    uploadFile={handleUploadDocuments}
                    name="file"
                    types={fileTypes}
                    multiple
                    classes="bulk-upload_drag-n-drop-wrapper position-absolute"
                    fileName={getValue(props, `importedFile.name`, "")}
                    setImportTedFile={props.setImportTedFile}
                  />
                </div>
                <div className="expense-detail-wrapper_report_documentsection w-100">
                  <div className="d-flex  mt-3" style={{ flexWrap: "wrap" }}>
                    <DocumentImageList documents={documents} />
                  </div>
                </div>
              </div>
              <div className="expense-create-wrapper_right">
                <FormBuilder
                  fields={form}
                  setFields={setForm}
                  formOptions={formInfo}
                  simpleValidator={simpleValidator}
                />
              </div>
            </div>
          </div>

          {/* <div className="fixed_footer_wrapper d-flex justify-content-between"></div> */}
        </>
      )}
    </DashboardLayout>
  );
}

export default ClientReportCreate;
