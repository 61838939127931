import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import { Nav, NavItem, NavLink } from "reactstrap";

function NavViews(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  return (
    <div className="d-flex" style={{ width: "100%" }}>
      <Nav tabs className="navbar_policy">
        {getValue(props, `views.length`, 0) > 0
          ? getValue(props, `views`, []).map((item: object, index: number) => {
              return (
                <NavItem className="navitem_policy">
                  <NavLink
                    className={`navlink_policy ${
                      getValue(props, `activeTab`, "") === index + 1
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      getValue(urlParams, `policy_id`, "") ||
                      getValue(props, `params.id`, "")
                        ? props.handleChangeActiveTab(index + 1)
                        : toast.error("Please add policy settings to continue!")
                    }
                  >
                    <div className="icon-holder d-flex justify-content-center">
                      {getValue(item, `icon`, "")}
                    </div>
                    <p>{getValue(item, `name`, "")}</p>
                    
                  </NavLink>
                </NavItem>
              );
            })
          : null}
      </Nav>
    </div>
  );
}

export default NavViews;
