import React, { useRef, useState, useEffect } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
// import { NavLink } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { useParams } from "react-router-dom";
import InputField from "@common/InputRuleFeild";
import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import countries from "@common/json/contry.json";
import _ from "lodash";
import PromptModal from "@prompt/modal";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import {
  getAllOrganisation,
  getClientToken,
  getSpecificOrganisation,
  updateOrganisation,
} from "@services/organisation.service";
import { mediaPublicUpload, mediaUpload } from "@services/upload.service";
import "./org-profile.scss";
import GearLoader from "@components/common/GearLoader";
import {
  getlocationManagementCities,
  getlocationManagementCountries,
  getlocationManagementStates,
} from "@services/list-management.service";
import timezones from "@common/json/timezone.json";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  DateFormatList,
  DateSeperator,
  FiscalYearList,
} from "@common/date-format";
import { StylesConfig } from "react-select";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import StickyHeader from "./StickyHeader";
import NoAccessPage from "@components/common/NoAccess";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { CopyToClipboard } from "@common/text-helpers";
import OrgTokenConfirm from "./token-confirm";

interface IAdminOrganisationProfileProps {}

const AdminOrganisationProfile: React.FunctionComponent<IAdminOrganisationProfileProps> = (
  props: any
) => {
  let params = useParams();
  //   let navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = useState<any>({
    country: "",
    state: "",
    city: "",

    organisation_name: "",
    organisation_logo: "",
    domain_name: "",
    address1: "",
    address2: "",
    pincode: "",
    phone_no: "",
    fax: "",
    website: "",
    primary_email: "",
    support_email: "",
    base_currency: "",
    fiscal_year: "",
    time_zone: "",
    date_format: "",
    date_separator: "",
    is_active: true,
    travel_managers: [],
  });
  const [dirtyFields, setDirtyFields] = useState<any>({
    country: "",
    state: "",
    city: "",

    organisation_name: "",
    organisation_logo: "",
    domain_name: "",
    address1: "",
    address2: "",
    pincode: 1,
    phone_no: "",
    fax: "",
    website: "",
    primary_email: "",
    support_email: "",
    base_currency: "",
    fiscal_year: "",
    time_zone: "",
    date_format: "",
    date_separator: "",
    is_active: true,
    travel_managers: [],
  });

  const [countryList, setCountryList] = useState([]);
  const [states, setStates] = useState([]);
  const [citiesList, setCityList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [edit_id, setEdit_id] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.permissionAPITriggered) {
      getCountriesList();
      // let user = JSON.parse(getValue(localStorage, `user`, ""));
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (props.permissionAPITriggered) {
      if (request.country && !request.state && !request.city) {
        getStatesList(request.country);
      }
      if (request.state) {
        getCitiesList(request.state);
      }
    }
  }, [request.country, request.state, props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [logo, setLogo] = useState({});
  const getData = async () => {
    try {
      setLoading(true);
      let resp = await getAllOrganisation("");
      if (resp) {
        setEdit_id(getValue(resp, `data.organisations[${0}].id`, ""));
        setLogo(
          getValue(resp, `data.organisations[${0}].organisation_logo`, "")
        );

        let obj = {
          country: getValue(resp, `data.organisations[${0}].country`, ""),
          state: getValue(resp, `data.organisations[${0}].state`, ""),
          city: getValue(resp, `data.organisations[${0}].city`, ""),
          organisation_name: getValue(
            resp,
            `data.organisations[${0}].organisation_name`,
            ""
          ),
          organisation_logo: getValue(
            resp,
            `data.organisations[${0}].organisation_logo.id`,
            ""
          ),
          domain_name: getValue(
            resp,
            `data.organisations[${0}].domain_name`,
            ""
          ),
          address1: getValue(resp, `data.organisations[${0}].address1`, ""),
          address2: getValue(resp, `data.organisations[${0}].address2`, ""),
          pincode: getValue(resp, `data.organisations[${0}].pincode`, ""),
          phone_no: getValue(resp, `data.organisations[${0}].phone_no`, ""),
          fax: getValue(resp, `data.organisations[${0}].fax`, ""),
          website: getValue(resp, `data.organisations[${0}].website`, ""),
          primary_email: getValue(
            resp,
            `data.organisations[${0}].primary_email`,
            ""
          ),
          support_email: getValue(
            resp,
            `data.organisations[${0}].support_email`,
            ""
          ),
          base_currency: getValue(
            resp,
            `data.organisations[${0}].base_currency.id`,
            ""
          ),
          fiscal_year: getValue(
            resp,
            `data.organisations[${0}].fiscal_year`,
            ""
          ),
          time_zone: getValue(resp, `data.organisations[${0}].time_zone`, ""),
          date_format: getValue(
            resp,
            `data.organisations[${0}].date_format`,
            ""
          ),
          date_separator: getValue(
            resp,
            `data.organisations[${0}].date_separator`,
            ""
          ),
          is_active: getValue(resp, `data.organisations[${0}].is_active`, ""),
          travel_managers: getValue(
            resp,
            `data.organisations[${0}].travel_managers`,
            ""
          ),
        };
        setRequest({
          ...request,
          ...obj,
        });

        setDirtyFields({
          ...dirtyFields,
          ...obj,
        });
        if (getValue(resp, `data.organisations[${0}].country`, "")) {
          getStatesList(getValue(resp, `data.organisations[${0}].country`, ""));
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCountriesList = async () => {
    try {
      let resp = await getlocationManagementCountries("");
      if (resp) {
        setCountryList(
          getValue(resp, `data.countries`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };
  const getStatesList = async (countryId: any) => {
    let payload = {
      country_name: countryId,
    };
    let queryRequest = QueryRequestHelper(payload);
    if (countryId)
      try {
        let resp = await getlocationManagementStates(queryRequest);
        if (resp) {
          setStates(
            getValue(resp, `data.states`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `name`, ""),
              label: getValue(item, `name`, ""),
            }))
          );
        } else {
        }
      } catch (error) {}
  };
  const getCitiesList = async (stateId: any) => {
    let payload = {
      state_name: stateId,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await getlocationManagementCities(queryRequest);
      if (resp) {
        setCityList(
          getValue(resp, `data.cities`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      let payload = JSON.parse(JSON.stringify(request));

      payload.pincode = parseInt(payload.pincode);
      payload.country = payload.country;
      payload.state = payload.state;
      payload.city = payload.city;
      Object.keys(payload).forEach((key) => {
        if (!payload[key] || payload[key] === undefined) {
          delete payload[key];
        }
      });
      // if (getValue(params, `id`, "")) {
      try {
        setIsLoading(true);
        let resp = await updateOrganisation(edit_id, payload);
        if (resp) {
          setIsLoading(false);
          confirmNavigation();
          toast.success(getValue(resp, "message", ""));
          getData();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSelect = (e: any, type: string) => {
    if (type === "country") {
      // getStatesList(getValue(e, `id`, ""));
      setRequest({
        ...request,
        country: getValue(e, `id`, ""),
        state: null,
        city: null,
      });
    }
    if (type === "state") {
      // getCitiesList(getValue(e, `id`, ""));
      setRequest({
        ...request,
        state: getValue(e, `id`, ""),
        city: null,
      });
    }
    if (type === "city") {
      setRequest({
        ...request,
        city: getValue(e, `id`, ""),
      });
    }
    if (type === "time_zone") {
      setRequest({
        ...request,
        time_zone: e.value,
      });
    }
  };

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  //   const handleChangeDate = (e: any) => {
  //     setRequest({
  //       ...request,
  //       commencement_date: e,
  //     });
  //   };

  //   const handleChangeCountryCode = (e: any) => {};

  const handleChangeFile = async (e: any) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      let resp = await mediaPublicUpload(formData);
      if (resp) {
        let id = getValue(resp, `data.id`, "");
        let payload: any = {
          ...request,
          organisation_logo: id,
        };
        Object.keys(payload).forEach((key) => {
          if (!payload[key] || payload[key] === undefined) {
            delete payload[key];
          }
        });
        let response = await updateOrganisation(edit_id, payload);
        if (response) {
          localStorage.setItem(
            "organisation_logo",
            getValue(resp, `data.organisation_logo.url`, "")
          );
          window.location.reload();
        }
      }
    } catch (error) {}
  };

  const handleChangeTextColor = (e: any, value: string) => {
    if (e.hex) {
      setRequest({
        ...request,
        [value]: e.hex,
      });
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });
  const [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
  ]: any = useCallbackPrompt(!isChanged);

  const customStyles: StylesConfig = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#dadce0",
      };
    },
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: 43,
      border: state.isFocused ? "1px solid #dadce0" : "1px solid #dadce0",
      boxShadow: state.isFocused ? "0px 0px 0px #dadce0" : "none",
      "&:hover": {
        // border: "1px solid #ff8b67",
        // boxShadow: "0px 0px 6px #ff8b67"
      },
    }),
  };
  const [focused, setFocused] = React.useState(false);

  const handleDownloadClientToken = async () => {
    try {
      let resp = await getClientToken("");
      if (resp) {
        CopyToClipboard(getValue(resp, `data.client_token`, ""));
        handleModal()
      }
    } catch (error) {}
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      <>
        <PromptModal
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <StickyHeader
          title={"Organization Profile"}
          isChanged={isChanged}
          handleSubmit={handleSubmit}
          params={params}
          submitLoading={isLoading}
          permissions={getValue(props, `permissions`, [])}
        />
        <div className="organization_container">
          <div className="d-flex justify-content-between align-items-center px-4 mt-3">
            <div></div>
            <h6
              className="header_text__16 color_primary cursor-pointer"
              onClick={handleModal}
            >
              Download Client Token
            </h6>
          </div>
          {!loading &&
          !getValue(props, `subTabPermissionList`, []).includes(
            "admin_organisations_org_profile"
          ) ? (
            <NoAccessPage />
          ) : loading || !props.permissionAPITriggered ? (
            <GearLoader />
          ) : (
            <>
              <div className="user-details-wrapper m-0">
                <div className="org-profile-wrapper d-flex align-items-flex-start justify-content-between">
                  <label className="org-profile-wrapper-image cursor-pointer">
                    <img
                      src={
                        getValue(request, `organisation_logo`, "")
                          ? getValue(logo, `url`, "imagespharmeasy-image.png")
                          : "https://juliaja.nl/wp-content/uploads/2021/04/noimagefound24-1024x576.png"
                      }
                      className="img=fluid"
                      width={364}
                      height={119}
                    />
                    <input
                      type={"file"}
                      onChange={handleChangeFile}
                      className="d-none"
                    />
                    <span className="org-profile-wrapper-change-text">
                      Change
                    </span>
                  </label>
                  <div className="org-profile-wrapper__details">
                    <h4 className="org-profile-wrapper__details-heading">
                      Organization Logo
                    </h4>
                    <p className="org-profile-wrapper__details-text">
                      This logo will appear on the tool bar for all users.{" "}
                    </p>

                    <p className="org-profile-wrapper__prefered-text">
                      Preferred Image Size: 240px x 240px @ 72 DPI Maximum size
                      of 1MB.
                    </p>
                  </div>
                </div>
                <div className="checkpoint-active-details-wrapper__form-wrapper">
                  <div className="checkpoint-active-details-wrapper__form-content">
                    <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile">
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label-error">
                          Organization Name <span>*</span>
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Organization Name"
                          className="form-control w-100"
                          name="organisation_name"
                          id="organisation_name"
                          value={getValue(request, `organisation_name`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          label="Organisation Name"
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label-error">
                          Domain Name <span>*</span>
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Domain Name"
                          className="form-control w-100"
                          name="domain_name"
                          id="domain_name"
                          value={getValue(request, `domain_name`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          label="Domain Name"
                          validator={simpleValidator}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="user-details-wrapper__form-group form-group mb-3">
                      <label className="checkpoint-active-details__label form-label-error">
                        Company Address (Line 1) <span>*</span>
                      </label>
                      <InputField
                        inputType="TEXTAREA"
                        placeholder="Enter Company Address (Line 1)"
                        className="form-control w-100"
                        name="address1"
                        id="address1"
                        label="Address Line1"
                        value={getValue(request, `address1`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        validator={simpleValidator}
                      />
                    </div>
                    <div className="user-details-wrapper__form-group form-group mb-3">
                      <label className="checkpoint-active-details__label">
                        Company Address (Line 2)<span></span>
                      </label>
                      <InputField
                        inputType="TEXTAREA"
                        placeholder="Enter Company Address (Line 2)"
                        className="form-control w-100"
                        name="address2"
                        id="address2"
                        label="Address Line2"
                        value={getValue(request, `address2`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        // validator={simpleValidator}
                      />
                    </div>
                    <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile">
                      <div className="user-details-wrapper__form-group form-group mb-3">
                        <label className="checkpoint-active-details__label form-label-error">
                          Country <span>*</span>
                        </label>
                        <InputField
                          inputType="INPUT_REACT_SELECT"
                          placeholder="Choose Country"
                          className="form-control w-100"
                          name="country"
                          id="country"
                          options={countryList}
                          value={
                            getValue(countryList, `length`, 0) > 0
                              ? countryList.filter(
                                  (item: object) =>
                                    getValue(item, `name`, "") ==
                                    getValue(request, `country`, "")
                                )[0]
                              : {}
                          }
                          onChange={(e: any) =>
                            handleChangeSelect(e, "country")
                          }
                          label="Country"
                          validator={simpleValidator}
                          styles={customStyles}
                          isClear
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label-error">
                          State <span>*</span>
                        </label>
                        <InputField
                          inputType="INPUT_REACT_SELECT"
                          placeholder="Choose State"
                          className="form-control w-100"
                          name="state"
                          id="state"
                          label="Last Name"
                          options={states}
                          value={states.filter(
                            (item: object) =>
                              getValue(item, `name`, "") ==
                              getValue(request, `state`, "")
                          )}
                          onChange={(e: any) => handleChangeSelect(e, "state")}
                          validator={simpleValidator}
                          styles={customStyles}
                          isClear
                        />
                        {/* <SingleSelectSearchDropdown
                        label={"label"}
                        name="State"
                        value={getValue(request, `state`, "")}
                        onChange={(e: any) => handleChangeSelect(e, "state")}
                        placeholder={"Choose State"}
                        data={states}
                        width={"358px"}
                        validator={simpleValidator}
                      /> */}
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label-error">
                          City <span>*</span>
                        </label>
                        <InputField
                          inputType="INPUT_REACT_SELECT"
                          placeholder="Choose State"
                          className="form-control w-100"
                          name="city"
                          id="city"
                          label="City"
                          options={citiesList}
                          value={citiesList.filter(
                            (item: object) =>
                              getValue(item, `name`, "") ==
                              getValue(request, `city`, "")
                          )}
                          onChange={(e: any) => handleChangeSelect(e, "city")}
                          validator={simpleValidator}
                          styles={customStyles}
                          isClear
                        />
                        {/* <SingleSelectSearchDropdown
                        label={"label"}
                        name="City"
                        value={getValue(request, `city`, "")}
                        onChange={(e: any) => handleChangeSelect(e, "city")}
                        placeholder={"Choose City"}
                        data={citiesList}
                        width={"358px"}
                        validator={simpleValidator}
                      /> */}
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label-error">
                          Pincode <span>*</span>
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Pincode"
                          className="form-control w-100"
                          name="pincode"
                          id="pincode"
                          value={getValue(request, `pincode`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          label="Pincode"
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label-error">
                          Phone Number <span>*</span>
                        </label>
                        <InputField
                          inputType="PHONE"
                          placeholder="Enter Phone Number 1"
                          className="form-control w-100"
                          name="phone_no"
                          id="phone_no"
                          label="Mobile Number"
                          value={getValue(request, `phone_no`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label">
                          Fax
                          {/* <span>*</span> */}
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Fax Number"
                          className="form-control w-100"
                          name="fax"
                          id="fax"
                          label="FAX Number"
                          value={getValue(request, `fax`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          // validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label-error">
                          Primary Email Address <span>*</span>
                        </label>
                        <InputField
                          inputType="EMAIL"
                          placeholder="Enter Primary Email Address"
                          className="form-control w-100"
                          name="primary_email"
                          id="primary_email"
                          value={getValue(request, `primary_email`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          label="Primary Email"
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label-error">
                          Support Email Address <span>*</span>
                        </label>
                        <InputField
                          inputType="EMAIL"
                          placeholder="Enter Email Address"
                          className="form-control w-100"
                          name="support_email"
                          id="support_email"
                          value={getValue(request, `support_email`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          label="Support Email Address"
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label">
                          Website
                          {/* <span>*</span> */}
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Website"
                          className="form-control w-100"
                          name="website"
                          id="website"
                          label="Website"
                          value={getValue(request, `website`, "")}
                          onChange={(e: any) => handleChangeText(e)}
                          // validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group mb-3">
                        <label className="checkpoint-active-details__label form-label-error">
                          Base Currency <span>*</span>
                        </label>
                        <SingleSelectSearchDropdown
                          label={"label"}
                          name="Base Currency"
                          value={getValue(request, `base_currency`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              base_currency: getValue(e, `id`, ""),
                            })
                          }
                          placeholder={"Choose Base Currency"}
                          lookup_api={"base_currency"}
                          width={"358px"}
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label-error">
                          Fiscal Year <span>*</span>
                        </label>
                        <SingleSelectSearchDropdown
                          label={"label"}
                          selectKey={"value"}
                          name="Fiscal Year"
                          value={getValue(request, `fiscal_year`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              fiscal_year: getValue(e, `value`, ""),
                            })
                          }
                          placeholder={"Choose Fiscal Year"}
                          data={FiscalYearList}
                          width={"358px"}
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group mb-3">
                        <label className="checkpoint-active-details__label form-label-error">
                          Time Zone <span>*</span>
                        </label>
                        <SingleSelectSearchDropdown
                          label={"label"}
                          selectKey={"value"}
                          name="Timezone"
                          value={getValue(request, `time_zone`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              time_zone: getValue(e, `value`, ""),
                            })
                          }
                          placeholder={"Choose Timezone"}
                          data={timezones}
                          width={"358px"}
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group mb-3">
                        <label className="checkpoint-active-details__label form-label-error">
                          Date Format <span>*</span>
                        </label>
                        <SingleSelectSearchDropdown
                          label={"label"}
                          selectKey={"value"}
                          name="Date Format"
                          value={getValue(request, `date_format`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              date_format: getValue(e, `value`, ""),
                            })
                          }
                          placeholder={"Choose Date Format"}
                          data={DateFormatList}
                          width={"358px"}
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label-error">
                          Date Separator <span>*</span>
                        </label>
                        <SingleSelectSearchDropdown
                          label={"label"}
                          selectKey={"value"}
                          name="Date Separator"
                          value={getValue(request, `date_separator`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              date_separator: getValue(e, `value`, ""),
                            })
                          }
                          placeholder={"Choose Date Separator"}
                          data={DateSeperator}
                          width={"358px"}
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label">
                          Travel Desk <span></span>
                        </label>
                        <ReactMultiEmail
                          placeholder="Input travel desk email/s"
                          emails={getValue(request, `travel_managers`, [])}
                          onChange={(_emails: string[]) => {
                            setRequest({
                              ...request,
                              travel_managers: _emails,
                            });
                          }}
                          // autoFocus={true}
                          onFocus={() => setFocused(true)}
                          onBlur={() => setFocused(false)}
                          getLabel={(email, index, removeEmail) => {
                            return (
                              <div data-tag key={index}>
                                <div data-tag-item>{email}</div>
                                <span
                                  data-tag-handle
                                  onClick={() => removeEmail(index)}
                                >
                                  ×
                                </span>
                              </div>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <img src="/images/footer-image.svg" className="img-fluid" />
              </div>
            </>
          )}
        </div>
      </>
      <OrgTokenConfirm
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDownloadClientToken}
      />
    </DashboardLayout>
  );
};

export default AdminOrganisationProfile;
