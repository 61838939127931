import ClientDashboard from "@pages/Client/Dashboard";

export const ClientDashboardRoutes = [
  { path: "/", name: "dashboard", component: ClientDashboard },
  {
    path: "/client/dashboard",
    name: "dashboard",
    component: ClientDashboard,
  },
];
