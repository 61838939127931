import { getValue } from "@utils/lodash";

function PolicyMileageSvgComponent(props: any) {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" id="speed_black_24dp" 
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        viewBox="0 0 24 24">
        <path id="Path_46862" data-name="Path 46862" d="M0,0H24V24H0Z" fill="none"/>
        <path id="Path_46863" data-name="Path 46863" d="M20.38,8.57l-1.23,1.85A8,8,0,0,1,18.93,18H5.07A8,8,0,0,1,15.58,6.85l1.85-1.23A10,10,0,0,0,3.35,19a2,2,0,0,0,1.72,1H18.92a2,2,0,0,0,1.74-1,10,10,0,0,0-.27-10.44Z" fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#5f6368"
            }
            />
        <path id="Path_46864" data-name="Path 46864" d="M10.59,15.41a2,2,0,0,0,2.83,0l5.66-8.49-8.49,5.66a2,2,0,0,0,0,2.83Z" fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#5f6368"
            }
            />
      </svg>
    </>
  );
}

export default PolicyMileageSvgComponent;
