import React, { useEffect, useState } from "react";
import { getValue } from "@utils/lodash";
import moment from "moment";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import SearchHeader from "@components/common/SearchHeader";
import PaginationPage from "@components/pagination/pagination";
import ScrollableReactTable from "@components/ScrollableDatatable";
import NavigationBar from "@components/NotificationBar";
import { toast } from "react-toastify";
import Notfound from "@components/Not-Found";
import Loader from "@components/common/Loader";
import { QueryRequestHelper } from "@common/query-request-helper";
import DeleteModal from "@common/popup/DeleteModal";
import GearLoader from "@components/common/GearLoader";
import { deleteUser, getAllUsers, inviteUser } from "@services/users.service";
import RolesTable from "./table";
import {
  deleteRole,
  getAllRoles,
  updateRoleStatusBulk,
} from "@services/roles.service";
import { formatText } from "@common/text-helpers";
import { useParams } from "react-router-dom";
import AnalyticsTable from "./table";
import { getAnalyticsReports } from "@services/analytics.service";
import BackSvgComponent from "@assets/svg-components/back";
import NoAccessPage from "@components/common/NoAccess";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";
interface IAnalyticsDetailListProps {}

const AnalyticsDetailList: React.FunctionComponent<IAnalyticsDetailListProps> = (
  props: any
) => {
  let navigate = useNavigate();
  let mainParams = useParams();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setList] = React.useState<[]>([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let payload: any = {
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      search_text: params.search_text,
      report_type: mainParams.type,
      sort_by: params.sort_by,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAnalyticsReports(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.reports`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const sendInviteUser = async (id: string) => {
    try {
      let resp = await inviteUser(id);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      let queryRequest = QueryRequestHelper(payload);

      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setSearchInput(e.target.value);

    let payload: any = {
      ...params,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);

  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteRole(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeActiveStatus = async (value: string) => {
    try {
      let resp = await updateRoleStatusBulk({
        ids: selectedIDs,
        is_active: value === "active" ? true : false,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      } else {
        getData();
      }
    } catch (error) {}
  };
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };

  const handleNavigate = (id: string) => {
    // navigate(
    //   `/admin/analytics/${getValue(mainParams, `type`, "")}/${getValue(
    //     mainParams,
    //     `name`,
    //     ""
    //   )}/create`
    // );
  };

  return (
    <>
      <DashboardLayout
        permissions={getValue(props, `tabPermissionList`, [])}
        subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
      >
        {!isLoading &&
        !getValue(props, `subTabPermissionList`, []).includes(
          "admin_dashboard"
        ) ? (
          <NoAccessPage />
        ) : isLoading || !props.permissionAPITriggered ? (
          <Loader />
        ) : (
          <>
            <div className="position-relative">
              <div className="dashboard-wrapper__header d-flex align-items-center">
                <div onClick={() => navigate(`/admin/analytics`)}>
                  <BackSvgComponent />
                </div>
                <h4 className="dashbaord-wrapper__header-title">
                  {formatText(getValue(mainParams, `type`, ""))} Reports
                </h4>
              </div>
              {getValue(selectedIDs, `length`, 0) > 0 && (
                <NavigationBar
                  selectedIDs={selectedIDs}
                  // isMarkInactiveSectionVisible={true}
                  handleRemoveNavItems={handleRemoveNavItems}
                  multi={false}
                  permissions={getValue(props, `permissions`, [])}
                />
              )}
              <div className="admin-wrapper position-relative">
                <div className="admin-wrapper position-relative">
                  <SearchHeader
                    route={`/admin/analytics/${getValue(
                      mainParams,
                      `type`,
                      ""
                    )}/${getValue(mainParams, `name`, "")}/create`}
                    searchInput={search_text}
                    showSearchTextbox={showSearchTextbox}
                    toggleSearch={toggleSearch}
                    toggleSearchClose={toggleSearchClose}
                    handleChangeSearch={handleChangeSearch}
                    add={
                      getValue(props, `permissions`, []).includes("create")
                        ? "true"
                        : "false"
                    }
                  />
                </div>
              </div>
              <div
                className={
                  getValue(selectedIDs, `length`, 0) > 0
                    ? "datatable_main_container_notifiction"
                    : "datatable_main_container"
                }
                style={{ overflowY: isLoading ? "hidden" : "auto" }}
              >
                {isLoading || !props.permissionAPITriggered ? (
                  // <GearLoader />
                  <SkeletonTable />
                ) : getValue(list, `length`, 0) > 0 ? (
                  <AnalyticsTable
                    list={list}
                    handleNavigate={handleNavigate}
                    handleDeleteFun={handleDeleteFun}
                    sendInviteUser={sendInviteUser}
                    selectedIDs={selectedIDs}
                    handleCheckUsers={handleCheckUsers}
                    handleCheckAll={handleCheckAll}
                    isLoading={isLoading}
                    permissions={getValue(props, `permissions`, [])}
                  />
                ) : (
                  <>
                    <>
                      {Object.keys(params).length !== 0 ? (
                        <Notfound />
                      ) : (
                        <Notfound />
                      )}
                    </>
                  </>
                )}
              </div>
              <div className="fixed_footer_wrapper d-flex justify-content-between">
                {list.length > 0 && (
                  <img
                    src="/images/building-illustration.svg"
                    className="img-fluid"
                  />
                )}
                {totalCount > 10 ? (
                  <PaginationPage
                    totalCount={totalCount}
                    limit={limit}
                    pageNumber={page_no}
                    handleChangePagination={handleChangePagination}
                    handleChangeLimit={handleChangeLimit}
                  />
                ) : (
                  <p className="show-rows-count mt-3 d-flex">
                    Total Count:&nbsp;&nbsp;{" "}
                    <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
                  </p>
                )}
              </div>
              <DeleteModal
                isOpen={isDelete}
                handleModal={handleDelete}
                handleSubmit={handleDeleteFunction}
                deleteValue={deleteValue}
              />
            </div>
          </>
        )}
      </DashboardLayout>{" "}
    </>
  );
};

export default AnalyticsDetailList;
