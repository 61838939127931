import React from "react";
import { getValue } from "@utils/lodash";

function MealSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="restaurant_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          id="Path_46307"
          data-name="Path 46307"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_46308"
          data-name="Path 46308"
          d="M16,6v8h3v8h2V2C18.24,2,16,4.24,16,6ZM11,9H9V2H7V9H5V2H3V9a4,4,0,0,0,4,4v9H9V13a4,4,0,0,0,4-4V2H11Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#fd9933"
          }
        />
      </svg>
    </div>
  );
}

export default MealSvgComponent;
