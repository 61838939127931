import { NavLink } from "react-router-dom";
import "./inner-page-navigation.scss";
import { getValue } from "@utils/lodash";

export default function ReportTabNavigation(props: any) {
  return (
    <div className="common-sticky-header report_details_navigation_wrapper">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative ">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <ul className="inner-page-navigation-with-back-cta-buttons__navigation_left_temp d-inline-flex position-absolute cursor-pointer">
            <li
              className={`position-relative inner-page-navigation-with-back-cta-buttons__nav-list-item ${"active"}`}
            >
              Expenses
              {getValue(props, `length`, 0) > 0 && (
                <p className="report_count">{getValue(props, `length`, "")}</p>
              )}
            </li>
          </ul>
          {/* <div>
            <ul className="inner-page-navigation-with-back-cta-buttons__navigation_left d-inline-flex position-absolute cursor-pointer">
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${"active"}`}
              >
                Expenses
              </li>
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  props.activeTab == 2 ? "active" : ""
                }`}
              >
                Advances & Refunds
              </li>
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  props.activeTab == 3 ? "active" : ""
                }`}
              >
                History
              </li>
            </ul>
          </div> */}
          {/* <button
            className={`ascent-button ascent-button--header-buttons ascent-button-secondary`}
          ></button> */}
        </div>
      </section>
    </div>
  );
}
