import { QueryRequestHelper } from "@common/query-request-helper";
import NoAccessPage from "@components/common/NoAccess";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { getAllCurrency } from "@services/currency.service";
import { getValue } from "@utils/lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import InputField from "@common/InputRuleFeild";
import SimpleReactValidator from "simple-react-validator";
import { getAllOrganisation } from "@services/organisation.service";
import { toast } from "react-toastify";
import { updateReminders } from "@services/reminders.service";
import { Tooltip } from "react-tooltip";
import InfoSvgComponent from "@assets/svg-components/info";
import InfoBoxSvgComponent from "@assets/svg-components/info-box";


function AdminUserControlsReminders(props: any) {
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [reminderData, setReminderData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [orgID, setOrgID] = useState<any>([]);

  const [request, setRequest] = useState({
    report_approver_reminder_within: "",
    report_approver_reminder_limit: "",
    report_requestor_reminder_within: "",
    report_requestor_reminder_limit: "",
    trip_approver_reminder_within: "",
    trip_approver_reminder_limit: "",
    sent_back_reminder_within: "",
    sent_back_reminder_limit: "",
  });

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const getData = async () => {
    try {
      setIsLoading(true);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let resp = await getAllOrganisation("");
      if (resp) {
        setOrgID(getValue(resp, `data.organisations[${0}].id`, ""));
        setIsLoading(false);
        let obj = {
          report_approver_reminder_within: getValue(resp, `data.organisations[${0}].report_approver_reminder_within`, ""),
          report_approver_reminder_limit: getValue(resp, `data.organisations[${0}].report_approver_reminder_limit`, ""),
          report_requestor_reminder_within: getValue(resp, `data.organisations[${0}].report_requestor_reminder_within`, ""),
          report_requestor_reminder_limit: getValue(resp, `data.organisations[${0}].report_requestor_reminder_limit`, ""),
          trip_approver_reminder_within: getValue(resp, `data.organisations[${0}].trip_approver_reminder_within`, ""),
          trip_approver_reminder_limit: getValue(resp, `data.organisations[${0}].trip_approver_reminder_limit`, ""),      
        };

        setRequest({
          ...request,
          ...obj
        })
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubmit =async () => {

    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      let payload = JSON.parse(JSON.stringify(request));
      payload.report_approver_reminder_limit = parseInt(payload.report_approver_reminder_limit);
      payload.report_approver_reminder_within = parseInt(payload.report_approver_reminder_within);

      payload.report_requestor_reminder_limit = parseInt(payload.report_requestor_reminder_limit);
      payload.report_requestor_reminder_within = parseInt(payload.report_requestor_reminder_within);

      payload.trip_approver_reminder_limit = parseInt(payload.trip_approver_reminder_limit);
      payload.trip_approver_reminder_within = parseInt(payload.trip_approver_reminder_within);


      let resp = await updateReminders(orgID, payload);
      if (resp) {
        setIsLoading(false);
        toast.success(getValue(resp, "message", ""));
        getData();
      } else {
        setIsLoading(false);
      }

      try {
        
      } catch (error) {
        
      }
    }
  }


  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <DashboardLayout
        permissions={getValue(props, `tabPermissionList`, [])}
        subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
      >
        {!isLoading &&
        !getValue(props, `subTabPermissionList`, []).includes(
          "admin_user_controls_email_templates"
        ) ? (
          <NoAccessPage />
        ) : (
          <>
            <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
              <h4 className="dashbaord-wrapper__header-title">Reminders</h4>
              <div>
                {getValue(props, `permissions`, []).includes("create") && (
                  <>
                    <button
                      className={`m-0 ascent-button ascent-button--header-buttons ascent-button--${"secondary"}`}
                      onClick={handleSubmit}
                      disabled={submitLoading}
                    >
                      {submitLoading ? "Please wait..." : "Save"}
                    </button>{" "}
                    <button className="ms-2 my-0 ascent-button ascent-button--header-buttons ascent-button--primary " >
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </div>

            <div className="currency_container">
              <div className="currency_table_container">
                {isLoading || !props.permissionAPITriggered ? (
                  <SkeletonTable />
                ) : (
                  <>
                    <div className="reminders-wrap">
                      <div className="reminders-content-wrap d-flex align-items-center mb-4 border-bottom pb-4">
                      <div data-tooltip-id="reminderTooltip1" className="me-2">
                          <InfoBoxSvgComponent  />
                        </div>
                        Make all Reports due for approval within &nbsp;
                        <InputField
                          inputType="NUMBER"
                          value={getValue(
                            request,
                            `report_approver_reminder_within`,
                            ""
                          )}
                          name={"report_approver_reminder_within"}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              report_approver_reminder_within: e.target.value,
                            })
                          }
                          label={"Report Approver Reminder"}
                          placeholder="Enter Days"
                          // validator={SimpleReactValidator}
                        />{" "}
                        &nbsp; Days and send &nbsp;
                        <InputField
                          inputType="NUMBER"
                          value={getValue(
                            request,
                            `report_approver_reminder_limit`,
                            ""
                          )}
                          name={"report_approver_reminder_limit"}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              report_approver_reminder_limit: e.target.value,
                            })
                          }
                          label={"Report Approver Reminder"}
                          placeholder="Enter Reminders"
                          // validator={SimpleReactValidator}
                        />{" "}
                        &nbsp; reminders.
                        
                        {/* <Tooltip id="reminderTooltip1" content="Text" place="bottom" /> */}
                      </div>
                        
                      <div className="reminders-content-wrap d-flex align-items-center mb-4 border-bottom pb-4">
                      <div data-tooltip-id="reminderTooltip2" className="me-2">
                          <InfoBoxSvgComponent  />
                        </div>
                        Make all the Trip due for approval within &nbsp;
                        <InputField
                          inputType="NUMBER"
                          value={getValue(
                            request,
                            `report_requestor_reminder_within`,
                            ""
                          )}
                          name={"report_requestor_reminder_within"}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              report_requestor_reminder_within: e.target.value,
                            })
                          }
                          label={"Report Requester Reminder"}
                          placeholder="Enter Days"
                          // validator={SimpleReactValidator}
                        />{" "}
                        &nbsp; Days and send &nbsp;
                        <InputField
                          inputType="NUMBER"
                          value={getValue(
                            request,
                            `report_requestor_reminder_limit`,
                            ""
                          )}
                          name={"report_requestor_reminder_limit"}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              report_requestor_reminder_limit: e.target.value,
                            })
                          }
                          label={"Report Requester Reminder"}
                          placeholder="Enter Reminders"
                          // validator={SimpleReactValidator}
                        />{" "}
                        &nbsp; reminders.
                        {/* <Tooltip id="reminderTooltip2" content="Text" place="bottom" /> */}
                      </div>

                      <div className="reminders-content-wrap d-flex align-items-center mb-4 border-bottom pb-4">
                        <div data-tooltip-id="reminderTooltip3" className="me-2" >
                          <InfoBoxSvgComponent  />
                        </div>
                        Send Reminders for unreported reports &nbsp;
                        <InputField
                          inputType="NUMBER"
                          value={getValue(
                            request,
                            `trip_approver_reminder_within`,
                            ""
                          )}
                          name={"trip_approver_reminder_within"}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              trip_approver_reminder_within: e.target.value,
                            })
                          }
                          label={"Trip Approver Reminder"}
                          placeholder="Enter Days"
                          // validator={SimpleReactValidator}
                        />{" "}
                        &nbsp; Days and send &nbsp;
                        <InputField
                          inputType="NUMBER"
                          value={getValue(
                            request,
                            `trip_approver_reminder_limit`,
                            ""
                          )}
                          name={"trip_approver_reminder_limit"}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              trip_approver_reminder_limit: e.target.value,
                            })
                          }
                          label={"Trip Approver Reminder"}
                          placeholder="Enter Reminders"
                          // validator={SimpleReactValidator}
                        />{" "}
                        &nbsp; reminders.
                        
                        {/* <Tooltip id="reminderTooltip3" content="Text" place="bottom" /> */}
                      </div>

                      <div className="reminders-content-wrap d-flex align-items-center mb-4 border-bottom pb-4 d-none">
                        <div data-tooltip-id="reminderTooltip3" className="me-2" >
                          <InfoBoxSvgComponent  />
                        </div>
                        Send back to employee after &nbsp;
                        <InputField
                          inputType="NUMBER"
                          value={getValue(
                            request,
                            `sent_back_reminder_within`,
                            ""
                          )}
                          name={"sent_back_reminder_within"}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              sent_back_reminder_within: e.target.value,
                            })
                          }
                          label={"Trip Approver Reminder"}
                          placeholder="Enter Days"
                          // validator={SimpleReactValidator}
                        />{" "}
                        &nbsp; Days and send &nbsp;
                        <InputField
                          inputType="NUMBER"
                          value={getValue(
                            request,
                            `sent_back_reminder_limit`,
                            ""
                          )}
                          name={"sent_back_reminder_limit"}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              sent_back_reminder_limit: e.target.value,
                            })
                          }
                          label={"Trip Approver Reminder"}
                          placeholder="Enter Reminders"
                          // validator={SimpleReactValidator}
                        />{" "}
                        &nbsp; reminders.
                        
                        {/* <Tooltip id="reminderTooltip3" content="Text" place="bottom" /> */}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="fixed_footer_wrapper d-flex justify-content-between">
                <img
                  src="/images/building-illustration.svg"
                  className="img-fluid"
                />
            </div>
          </>
        )}
      </DashboardLayout>
    </>
  );
}

export default AdminUserControlsReminders;
