import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllExpenseReports = (queryRequest: string) =>
  get(`${config.API_URL}/expense-report?${queryRequest}`);

export const getAllExpenseReportsAdmin = (queryRequest: string) =>
  get(`${config.API_URL}/expense-report/admin?${queryRequest}`);

export const getSpecificExpenseReport = (id: string) =>
  get(`${config.API_URL}/expense-report/${id}`);

export const createExpenseReport = (payload: object) =>
  post(`${config.API_URL}/expense-report`, payload);

export const updateExpenseReport = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-report/${id}`, payload);

export const deleteSpecificExpenseReport = (id: string) =>
  Delete(`${config.API_URL}/expense-report/${id}`);

export const getAllExpenseFormReport = (queryRequest: string) =>
  get(`${config.API_URL}/expense-report/form?${queryRequest}`);

export const getAllExpenseReportFormEdit = (id: string) =>
  get(`${config.API_URL}/expense-report/${id}/form-edit`);

export const deleteExpenseEntryFromReport = (id: string, queryString: string) =>
  Delete(
    `${config.API_URL}/expense-report/${id}/remove/expense?${queryString}`
  );

export const attachExpenseEntryInReport = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-report/${id}/attach/expense`, payload);

//File Upload documents
export const attachDocumentsToExpenseReport = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-report/${id}/upload/documents`, payload);

export const deleteDocumentsFromExpenseReport = (
  id: string,
  queryString: string
) => Delete(`${config.API_URL}/expense-report/${id}/document?${queryString}`);

export const submitExpenseReport = (payload: object) =>
  post(`${config.API_URL}/expense-report/submit`, payload);

export const approveExpenseReport = (id: string) =>
  get(`${config.API_URL}/expense-report/${id}/approve`);

// export const rejectExpenseReport = (id: string) =>
//   get(`${config.API_URL}/expense-report/${id}/reject`);

export const recallExpenseReport = (id: string) =>
  get(`${config.API_URL}/expense-report/${id}/recall`);

export const rejectExpenseReport = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-report/${id}/reject`, payload);

  export const sentbackExpenseReport = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-report/${id}/sent-back`, payload);


export const expenseReportValidateApprovalFlowType = (id: string) =>
  get(`${config.API_URL}/expense-report/${id}/validate/approver-flow-type`);

//trip submission Admin
export const adminApproveExpenseReport = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-report/${id}/admin/approve`, payload);

export const adminRejectExpenseReport = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-report/${id}/admin/reject`, payload);

//comments

export const expenseReportComments = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/expense-report/${id}/comment?${queryRequest}`);

//reimbursement
export const expenseRecordReimbursement = (payload: object) =>
  post(`${config.API_URL}/expense-report/admin/record-reimbursement`, payload);
