import CashAdvanceSvgComponent from "@assets/svg-components/dashboard/advance";
import TripsSvgComponent from "@assets/svg-components/dashboard/trips";
import UploadSvgComponent from "@assets/svg-components/dashboard/upload";
import DoneSvgComponent from "@assets/svg-components/done";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import ReportsSvgComponent from "@assets/svg-components/sidebar/reports";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

function TaskSection(props: any) {
  const navigate = useNavigate();
  const handleNavigate = (url: string) => {
    navigate(url);
  };
  return (
    <div className="dashbaord-wrapper_info-section2 mb-4">
      <div className="dashbaord-wrapper_info-section2_left">
        <div className="dashbaord-wrapper_info-section2_left_header">
          <h6 className="header_text p-2 ms-3">Expense Overview</h6>
        </div>

        <div className="dashbaord-wrapper_info-section2_left_section mt-3 mb-3">
          <div className="d-flex align-items-center justify-content-between ">
            <div className="d-flex align-items-center">
              <DoneSvgComponent color={"#ff8a00"} />
              <p className="small_text__15 ms-2">
                Awaiting Manager Approval
              </p>
            </div>
            <p className="small_text__15 ms-2">
              {getValue(
                props,
                `dashboardInfo.awaiting_manager_approval_expenses`,
                ""
              )}
            </p>
          </div>

          <div className="border"></div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <ExpenseSvgComponent color={"#5f6368"} />
              <p className="small_text__15 ms-2">My Approval Pending</p>
            </div>
            <p className="small_text__14 ms-2">
              {" "}
              {getValue(
                props,
                `dashboardInfo.my_approval_pending_expenses`,
                ""
              )}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="d-flex align-items-center">
              <ExpenseSvgComponent color={"#5f6368"} />
              <p className="small_text__15 ms-2">Unsubmitted Expenses</p>
            </div>
            <p className="small_text__14 ms-2">
              {" "}
              {getValue(props, `dashboardInfo.unsubmitted_expenses`, "")}
            </p>
          </div>
        </div>
      </div>

      <div className="dashbaord-wrapper_info-section2_left ms-4">
        <div className="dashbaord-wrapper_info-section2_left_header">
          <h6 className="header_text p-2 ms-3">Trips Overview</h6>
        </div>

        <div className="dashbaord-wrapper_info-section2_left_section mt-3 mb-3">
          <div className="d-flex align-items-center justify-content-between mt-2">
            <div className="d-flex align-items-center">
              <DoneSvgComponent color={"#ff8a00"} />
              <p className="small_text__15 ms-2">
                Awaiting Manager Approval
              </p>
            </div>
            <p className="small_text__15 ms-2">
              {getValue(
                props,
                `dashboardInfo.awaiting_manager_approval_trips`,
                ""
              )}
            </p>
          </div>
          <div className="border"></div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="d-flex align-items-center ">
              <TripsSvgComponent color={"#5f6368"} size={16} />
              <p className="small_text__15 ms-2">My Approval Pending</p>
            </div>
            <p className="small_text__14 ms-2">
              {getValue(props, `dashboardInfo.my_approval_pending_trips`, "")}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="d-flex align-items-center ">
              <TripsSvgComponent color={"#5f6368"} size={16} />
              <p className="small_text__15 ms-2">Unsubmitted Trips</p>
            </div>
            <p className="small_text__14 ms-2">
              {getValue(props, `dashboardInfo.unsubmitted_trips`, "")}
            </p>
          </div>
        </div>
      </div>

      {/* <div className="dashbaord-wrapper_info-section2_right w-100">
        <div className="dashbaord-wrapper_info-section2_left_header">
          <h6 className="header_text p-2 ms-3">Quick Add</h6>
        </div>
        <div className="dashbaord-wrapper_info-section2_right_body">
          <div className="dashbaord-wrapper_info-section2_right_body_1">
            <div
              className="flex-container"
              onClick={() => handleNavigate(`/client/expenses/add`)}
            >
              <div className="dashbaord-wrapper_info-section2_right_body_circle">
                <div className="mt-2">
                  <UploadSvgComponent size={24} color={"#57d390"} />
                </div>
              </div>
              <p className="desc_text">Drag Receipts</p>
            </div>
          </div>
          <div className="dashbaord-wrapper_info-section2_right_body_1">
            <div
              className="flex-container"
              onClick={() => handleNavigate(`/client/expenses/add`)}
            >
              <div className="dashbaord-wrapper_info-section2_right_body_circle">
                <ExpenseSvgComponent size={24} color={"#7ab0fb"} />
              </div>
              <p className="desc_text">Create Expense</p>
            </div>
          </div>
          <div className="dashbaord-wrapper_info-section2_right_body_1">
            <div
              className="flex-container"
              onClick={() => handleNavigate(`/client/reports`)}
            >
              <div className="dashbaord-wrapper_info-section2_right_body_circle">
                <ReportsSvgComponent size={24} color={"#fdb763"} />
              </div>
              <p className="desc_text">Create Report</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default TaskSection;
