import { getValue } from "@utils/lodash";
import React from "react";

function MyViewSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="face_retouching_natural_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        // width="24"
        // height="24"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <g id="Group_9786" data-name="Group 9786">
          <rect
            id="Rectangle_17085"
            data-name="Rectangle 17085"
            width="24"
            height="24"
            fill="none"
          />
        </g>
        <g id="Group_9788" data-name="Group 9788">
          <g id="Group_9787" data-name="Group 9787">
            <path
              id="Path_45614"
              data-name="Path 45614"
              d="M19.89,10.75A7.355,7.355,0,0,1,20,12,8,8,0,0,1,4,12a3.944,3.944,0,0,1,.565-2.385C5.295,8.438,16.01,2.86,16.01,2.86A9.62,9.62,0,0,0,12,2a10,10,0,1,0,9.16,5.99Z"
              //   fill="#d7242e"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#3c4043"
              }
            />
            <circle
              id="Ellipse_3620"
              data-name="Ellipse 3620"
              cx="1.25"
              cy="1.25"
              r="1.25"
              transform="translate(13.75 11.75)"
              //   fill="#d7242e"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#3c4043"
              }
            />
            <circle
              id="Ellipse_3621"
              data-name="Ellipse 3621"
              cx="1.25"
              cy="1.25"
              r="1.25"
              transform="translate(7.75 11.75)"
              //   fill="#d7242e"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#3c4043"
              }
            />
            <path
              id="Path_45615"
              data-name="Path 45615"
              d="M23,4.5,20.6,3.4,19.5,1,18.4,3.4,16,4.5l2.4,1.1L19.5,8l1.1-2.4Z"
              //   fill="#d7242e"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#3c4043"
              }
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default MyViewSvgComponent;
