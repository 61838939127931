import AddSvgComponent from "@assets/svg-components/add";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

function ReportExpenseHeaderSection(props: any) {
  const { params, setShowUpdatePopup, showUpdatePopup, formInfo } = props;
  const navigate = useNavigate();
  return (
    <>
      {getValue(formInfo, `approval_status`, "") !== "reimbursed" && (
        <div className="header_button_container d-flex justify-content-between reports-details-under-navtab-header">
          <div></div>
          <div className="d-flex">
            <div
              className={`text_button_container d-flex justify-content-between align-items-center ${
                props.disabled ? "bg-disabled" : ""
              }`}
              onClick={() =>
                !props.disabled &&
                navigate(`/client/expenses/add?expense_report_id=${params.id}`)
              }
            >
              <AddSvgComponent color={!props.disabled ? "gray" : "#d5d5d5"} />
              <p className="">New Expense</p>
            </div>
            <div
              className={`text_button_container d-flex justify-content-between align-items-center  ${
                props.disabled ? "bg-disabled" : ""
              }`}
              onClick={() => {
                !props.disabled && setShowUpdatePopup(!showUpdatePopup);
              }}
            >
              <ExpenseSvgComponent
                color={!props.disabled ? "gray" : "#d5d5d5"}
              />
              <p className="">Add Unreported Expense</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ReportExpenseHeaderSection;
