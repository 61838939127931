import { getValue } from "@utils/lodash";
import React from "react";

function AddLightGreySvgComponent(props: any) {
  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" id="add_black_24dp" 
            width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
            height={
            getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
            }
            viewBox="0 0 24 24">
            <path id="Path_46263" data-name="Path 46263" d="M0,0H24V24H0Z" fill="none"/>
            <path id="Path_46264" data-name="Path 46264" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z" fill={
            getValue(props, `color`, "")
                ? getValue(props, `color`, "")
                : "#e8f0fe"
            } />
        </svg>
    </>
  );
}

export default AddLightGreySvgComponent;
