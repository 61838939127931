import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default () => {
  return (
    <div className="login-wrapper flex-1">
      <div className="image-wrapper">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          modules={[Pagination]}
          pagination={{ clickable: true }}

          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper: any) => console.log(swiper)}
        >
          <SwiperSlide>
            <img src="/images/login-home.png" className="img-fluid" />
            <div className="login-left-content-holder">
              <h3>Travel and expense management the easy way</h3>
              <p>
                Your go-to software to streamline T&E from end to end, control
                spending, ensure compliance, and collaborate better
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/login-home.png" className="img-fluid" />
            <div className="login-left-content-holder">
              <h3>Travel and expense management the easy way</h3>
              <p>
                Your go-to software to streamline T&E from end to end, control
                spending, ensure compliance, and collaborate better
            </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/login-home.png" className="img-fluid" />
            <div className="login-left-content-holder">
              <h3>Travel and expense management the easy way</h3>
              <p>
                Your go-to software to streamline T&E from end to end, control
                spending, ensure compliance, and collaborate better
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/images/login-home.png" className="img-fluid" />
            <div className="login-left-content-holder">
              <h3>Travel and expense management the easy way</h3>
              <p>
                Your go-to software to streamline T&E from end to end, control
                spending, ensure compliance, and collaborate better
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
