import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { formatText } from "@common/text-helpers";
import AddSvgComponent from "@assets/svg-components/add";
import { useNavigate } from "react-router-dom";
import {
  getAllApprovalConfigList,
  updateApprovalConfigStatusBulk,
} from "@services/settings.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import NavigationBar from "@components/NotificationBar";
import SearchHeader from "@components/common/SearchHeader";
import GearLoader from "@components/common/GearLoader";
import PreferenceTable from "./table";
import { toast } from "react-toastify";
import Notfound from "@components/Not-Found";
import PaginationPage from "@components/pagination/pagination";
import DeleteModal from "@common/popup/DeleteModal";
import BackSvgComponent from "@assets/svg-components/back";
import { NavLink } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useDraggableInPortal from "@common/useDraggableInPortal";
import DragIndicatorSvgComponent from "@assets/svg-components/drag-indicator";
import { convertCurrentDate } from "@common/date-helpers";
import EditSvgComponent from "@assets/svg-components/edit";
import { updateApprovalConfigPriority } from "@services/settings.service";
import { getColorStyle } from "@common/color";
import NoAccessPage from "@components/common/NoAccess";

function ApprovalPreferences(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState("2");

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (props.permissionAPITriggered) {
      getData(true);
    }
  }, [props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async (status: boolean) => {
    try {
      let payload = {
        approval_config_type: getValue(params, `name`, "").toUpperCase(),
        page_size: limit,
        page_no: page_no,
      };
      setIsLoading(status);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllApprovalConfigList(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.data`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      let queryRequest = QueryRequestHelper(payload);

      navigate(`${window.location.pathname}?${queryRequest}`);
      getData(true);
    }
  };

  const handleChangeSearch = async (e: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setSearchInput(e.target.value);

    let payload: any = {
      ...params,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData(true);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);

  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    // try {
    //   let resp = await deleteUser(deleteId);
    //   if (resp) {
    //     toast.success(getValue(resp, `message`, ""));
    //     handleDelete();
    //     getData();
    //   }
    // } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(100);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeActiveStatus = async (value: string) => {
    try {
      let resp = await updateApprovalConfigStatusBulk({
        ids: selectedIDs,
        is_active: value === "active" ? true : false,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData(false);
      } else {
        getData(false);
      }
    } catch (error) {}
  };

  const handleNavigate = (id: string) => {
    navigate(
      `/admin/settings/approval/preferences/${getValue(
        params,
        `name`,
        ""
      )}/detail/${id}`
    );
  };
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Drag section                               */
  /* -------------------------------------------------------------------------- */
  const renderDraggable = useDraggableInPortal();

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    // Moving from same area in top to botomm direction
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "source") {
        handleChangePriorityOrder(source.index, destination.index);
        const sourceOpenArrayCopy: any = [...list];
        const [removed] = sourceOpenArrayCopy.splice(source.index, 1);
        sourceOpenArrayCopy.splice(destination.index, 0, removed);
        setList(sourceOpenArrayCopy);
      }
    }
  };

  const handleChangePriorityOrder = async (
    source: number,
    destination: number
  ) => {
    let sourceItem = list.filter(
      (_item: any, index: number) => index === source
    );
    let destinationItem = list.filter(
      (_item: any, index: number) => index === destination
    );

    try {
      let payload = {
        data: [
          {
            id: getValue(sourceItem, `[${0}].id`, ""),
            priority_order: destination + 1,
          },
          {
            id: getValue(destinationItem, `[${0}].id`, ""),
            priority_order: source + 1,
          },
        ],
      };
      let resp = await updateApprovalConfigPriority(payload);
      if (resp) {
        getData(false);
      }
    } catch (error) {}
  };
  return (
    <div>
      <DashboardLayout
        permissions={getValue(props, `tabPermissionList`, [])}
        subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
      >
        <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <NavLink
              to={`/admin/settings/approval`}
              className="user-details-wrapper__header-back-link"
            >
              <BackSvgComponent />
            </NavLink>

            <h4 className="dashbaord-wrapper__header-title">
              {formatText(getValue(params, `name`, ""))}
            </h4>
          </div>
          <div></div>
        </div>

        {getValue(selectedIDs, `length`, 0) > 0 && (
          <NavigationBar
            selectedIDs={selectedIDs}
            // isMarkInactiveSectionVisible={true}
            handleChangeActiveStatus={handleChangeActiveStatus}
            text={"MARK INACTIVE"}
            handleRemoveNavItems={handleRemoveNavItems}
            multi={
              getValue(props, `permissions`, []).includes("update")
                ? true
                : false
            }
          />
        )}
        <div className="admin-wrapper position-relative">
          <div className="admin-wrapper position-relative">
            <SearchHeader
              route={`/admin/settings/approval/preferences/${getValue(
                params,
                `name`,
                ""
              )}/detail`}
              searchInput={search_text}
              showSearchTextbox={showSearchTextbox}
              toggleSearch={toggleSearch}
              toggleSearchClose={toggleSearchClose}
              handleChangeSearch={handleChangeSearch}
              add={
                getValue(props, `permissions`, []).includes("create")
                  ? "true"
                  : "false"
              }
            />
          </div>
        </div>
        {!isLoading &&
        !getValue(props, `subTabPermissionList`, []).includes(
          "admin_settings_approval_flows"
        ) ? (
          <NoAccessPage />
        ) : (
          <>
            <div
              className={
                getValue(selectedIDs, `length`, 0) > 0
                  ? "datatable_main_container_notifiction"
                  : "datatable_main_container"
              }
              style={{ overflowY: isLoading ? "hidden" : "auto" }}
            >
              {isLoading || !props.permissionAPITriggered ? (
                <GearLoader />
              ) : getValue(list, `length`, 0) > 0 ? (
                <div>
                  {getValue(list, `length`, 0) > 0 ? (
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="source">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="m-3"
                          >
                            {list.map((item: any, index: number) => {
                              return (
                                <Draggable
                                  key={index}
                                  draggableId={`${index}open`}
                                  index={index}
                                  // isDragDisabled={!index}
                                >
                                  {renderDraggable((provided: any) => (
                                    <div className="d-flex align-items-center">
                                      <p className="header_text drag_seq_text text-center">
                                        {getValue(item, `priority_order`, 0)}
                                      </p>
                                      <div
                                        className={`d-flex align-items-center mb-2 drag_common_card ms-2 width__95`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className="ms-2">
                                          <DragIndicatorSvgComponent
                                            color={"lightgray"}
                                          />
                                        </div>
                                        <div className="ms-3">
                                          <label
                                            className="m-0 position-relative"
                                            htmlFor={`header-checkbox-${index}`}
                                          >
                                            <input
                                              type="checkbox"
                                              className="default-checkbox"
                                              id={`header-checkbox-${index}`}
                                              onChange={() =>
                                                handleCheckUsers(
                                                  getValue(item, `id`, "")
                                                )
                                              }
                                              checked={selectedIDs.includes(
                                                getValue(item, `id`, "")
                                              )}
                                            />
                                            <div className="custom-checkbox">
                                              <img
                                                src="/images/uploaded-details/tick.svg"
                                                className="img-fluid uploaded-tick"
                                              />
                                            </div>
                                          </label>
                                        </div>
                                        <div
                                          className="ms-4"
                                          onClick={() =>
                                            handleNavigate(
                                              getValue(item, `id`, "")
                                            )
                                          }
                                        >
                                          <h6 className="header_text__14 header_section color_primary">
                                            {getValue(
                                              item,
                                              `approval_name`,
                                              ""
                                            )}
                                          </h6>
                                          <p className="small_text">
                                            {getValue(item, `id`, "")}
                                          </p>
                                        </div>
                                        <div
                                          className="ms-4"
                                          onClick={() =>
                                            handleNavigate(
                                              getValue(item, `id`, "")
                                            )
                                          }
                                        >
                                          <p className="small_text">
                                            Approval Type
                                          </p>
                                          <h6 className="header_text__14 header_section__200">
                                            {formatText(
                                              getValue(
                                                item,
                                                `approval_type`,
                                                ""
                                              )
                                            )}
                                          </h6>
                                        </div>
                                        <div
                                          className="ms-4"
                                          onClick={() =>
                                            handleNavigate(
                                              getValue(item, `id`, "")
                                            )
                                          }
                                        >
                                          <p className="small_text">
                                            Created At
                                          </p>
                                          <h6 className="header_text__14 header_section__150">
                                            {convertCurrentDate(
                                              getValue(item, `created_at`, "")
                                            )}
                                          </h6>
                                        </div>
                                        <div
                                          className="ms-4"
                                          onClick={() =>
                                            handleNavigate(
                                              getValue(item, `id`, "")
                                            )
                                          }
                                        >
                                          <p className="small_text">
                                            Updated At
                                          </p>
                                          <h6 className="header_text__14 header_section__150">
                                            {convertCurrentDate(
                                              getValue(item, `updated_at`, "")
                                            )}
                                          </h6>
                                        </div>
                                        <div
                                          onClick={() =>
                                            handleNavigate(
                                              getValue(item, `id`, "")
                                            )
                                          }
                                        >
                                          <h6
                                            className=""
                                            style={getColorStyle(
                                              getValue(item, `is_active`, false)
                                                ? "Active"
                                                : "Inactive"
                                            )}
                                          >
                                            {getValue(item, `is_active`, false)
                                              ? "Active"
                                              : "Inactive"}
                                          </h6>
                                        </div>
                                        {getValue(
                                          props,
                                          `permissions`,
                                          []
                                        ).includes("update") && (
                                          <div
                                            onClick={() =>
                                              handleNavigate(
                                                getValue(item, `id`, "")
                                              )
                                            }
                                            className="ms-4"
                                          >
                                            <EditSvgComponent />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    <Notfound />
                  )}
                </div>
              ) : (
                <>
                  <>
                    {Object.keys(params).length !== 0 ? (
                      <Notfound />
                    ) : (
                      <Notfound />
                    )}
                  </>
                </>
              )}
            </div>
            <div className="fixed_footer_wrapper d-flex justify-content-between">
              {list.length > 0 && (
                <img
                  src="/images/building-illustration.svg"
                  className="img-fluid"
                />
              )}
              {totalCount > 100 ? (
                <PaginationPage
                  totalCount={totalCount}
                  limit={limit}
                  pageNumber={page_no}
                  handleChangePagination={handleChangePagination}
                  handleChangeLimit={handleChangeLimit}
                />
              ) : (
                <p className="show-rows-count mt-3 d-flex">
                  Total Count:&nbsp;&nbsp;{" "}
                  <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
                </p>
              )}
            </div>
            <DeleteModal
              isOpen={isDelete}
              handleModal={handleDelete}
              handleSubmit={handleDeleteFunction}
              deleteValue={deleteValue}
            />

            {/* <div className="approval-preference__container">
          <h6 className="header_text__18">Approval Types</h6>

          <div className="d-flex align-items-center">
            <div
              className={`approval-preference__container__section ${
                type === "1" ? "active" : ""
              }`}
              onClick={() => setType("1")}
            >
              <input
                type="radio"
                className="approval-preference__container__radio"
                checked={type === "1" ? true : false}
              />
              <div className="ms-2">
                <h6 className="header_text__18">Hierarchical Approval</h6>
                <p className="small_text__14 mt-2 lh-base">
                  Approval flow will be based on the approvers configured in the
                  Submits To, Approves and Forwards To list of the users.{" "}
                  <span className=" text-primary">Learn More</span>
                </p>
              </div>
            </div>
            <div
              className={`approval-preference__container__section ms-3 ${
                type === "2" ? "active" : ""
              }`}
              onClick={() => setType("2")}
            >
              <input
                type="radio"
                className="approval-preference__container__radio"
                checked={type === "2" ? true : false}
              />
              <div className="ms-2">
                <h6 className="header_text__18">Custom Approval</h6>
                <p className="small_text__14 mt-2 lh-base">
                  {" "}
                  You can set up an approval flow based on one or more criteria.
                  These criteria can be chosen by you.{" "}
                  <span className=" text-primary">Learn More</span>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-4 border-bottom"></div>
          {type === "2" && (
            <button
              onClick={() =>
                navigate(
                  `/admin/settings/approval/preferences/${getValue(
                    params,
                    `name`,
                    ""
                  )}/detail`
                )
              }
              className="ascent-button ascent-button--header-buttons ascent-button--secondary d-flex align-items-center"
            >
              <AddSvgComponent color={"white"} />
              <p className="ms-2">New Custom Approval</p>
            </button>
          )}
        </div> */}
          </>
        )}
      </DashboardLayout>
    </div>
  );
}

export default ApprovalPreferences;
