import CloseSvgComponent from "@assets/svg-components/close";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import { concatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React, { useCallback, useRef } from "react";
import "./index.scss";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import { handleDownloadOnClick } from "@common/downloadfile";
import LightGalleryPreview from "@common/lightgallery-preview";

function DocumentImageList(props: any) {
  const { documents } = props;
  const dummy = () => {};
  /* -------------------------------------------------------------------------- */
  /*                            Image Preview section                           */
  /* -------------------------------------------------------------------------- */
  // const lightGallery = useRef<any>(null);
  // const openGallery = useCallback((index:number) => {
  //   lightGallery.current.openGallery(index);
  // }, []);
  // const onInit = useCallback((detail: any) => {
  //   if (detail) {
  //     lightGallery.current = detail.instance;
  //   }
  // }, []);
  return (
    <>
      {getValue(documents, `length`, 0) > 0
        ? documents.map((item: object, index: number) => {
            return (
              <div className="image_container_document mb-3" key={index}>
                <div
                  className="image_container_body d-flex justify-content-center align-items-center"
                  // style={props.containerStayle}
                  style={{
                    ...props.containerStayle,
                    border: "1px solid #efefef",
                  }}
                  // onClick={() => {
                  //   getValue(documents, `length`, 0) > 0
                  //     ? setTimeout(() => {
                  //         openGallery(index);
                  //       }, 100)
                  //     : dummy();
                  // }}
                  onClick={() =>
                    handleDownloadOnClick(
                      getValue(item, `url`, ""),
                      getValue(item, `name`, "")
                    )
                  }
                >
                  {getValue(item, `extension`, "") === ".png" ||
                  getValue(item, `extension`, "") === ".jpg" ||
                  getValue(item, `extension`, "") === ".jpeg" ? (
                    <img
                      // src={getValue(item, `url`, "")}
                      src="/images/image.svg"
                      className="img-fluid cursor-pointer static_image_main"
                    />
                  ) : getValue(item, `extension`, "") === ".pdf" ? (
                    <img
                      src="/images/pdf.svg"
                      className="img-fluid cursor-pointer static_image"
                    />
                  ) : getValue(item, `extension`, "") === ".xls" ||
                    getValue(item, `extension`, "") === ".xlsx" ? (
                    <img
                      src="/images/excel.svg"
                      className="img-fluid cursor-pointer static_image"
                    />
                  ) : (
                    <img
                      src="/images/expense.svg"
                      className="img-fluid cursor-pointer static_image"
                    />
                  )}
                </div>
                <div
                  className="cursor-pointer image_containertext_body d-flex justify-content-between align-items-center px-3"
                  style={{
                    ...props.textContainerStayle,
                    border: "1px solid #efefef",
                    borderTop: "none",
                  }}
                >
                  <p
                    className="header_tex"
                    style={props.textStyle}
                    onClick={() =>
                      handleDownloadOnClick(
                        getValue(item, `url`, ""),
                        getValue(item, `name`, "")
                      )
                    }
                  >
                    {concatString(
                      getValue(item, `name`, ""),
                      props.length ? props.length : 15
                    )}
                  </p>
                  <div
                    className=""
                    onClick={() =>
                      props.handleRemoveDocuments &&
                      props.handleRemoveDocuments(
                        getValue(props, `travelDocuments`, false)
                          ? index
                          : getValue(item, `id`, "")
                      )
                    }
                  >
                    <CloseSmallSvgComponent />
                    {/* <img
                      src="/images/close-black.svg"
                      width="24px"
                      height="24px"
                      className="img-fluid cursor-pointer close_image"
                    /> */}
                  </div>
                </div>
                {props.ai && (
                  <div
                    className="cursor-pointer border mt-2 border-radius__5 d-flex justify-content-center align-items-center bg-white box_shadow"
                    onClick={() => props.handleScanDoc(item)}
                  >
                    <div className="d-flex align-items-center justify-content-center p-2">
                      <ExpenseSvgComponent />
                      <h6 className="small_text__14 ms-2 ">Auto Scan</h6>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        : null}
      {/* {getValue(documents, `length`, 0) > 0 && (
        <LightGalleryPreview
          onInit={onInit}
          imageList={documents.map((item: any) => ({
            id: getValue(item, `id`, ""),
            size: "1400-933",
            src: getValue(item, `url`, ""),
            thumb: getValue(item, `url`, ""),
            subHtml: `<div class="lightGallery-captions">
                    <h4><a href="">${getValue(item, `name`, "")}</a></h4>
                </div>`,
          }))}
        />
      )} */}
    </>
  );
}

export default DocumentImageList;
