import * as React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { getValue } from "@utils/lodash";
import InfoSvgComponent from "@assets/svg-components/info";
import { convertElementaryArrayToJSONArray } from "@common/text-helpers";
import ReportsSvgComponent from "@assets/svg-components/sidebar/reports";
import InputField from "@common/InputRuleFeild";

interface IScheduleModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
}
const ScheduleModal = (props: any) => {
  const [list, setList] = React.useState(
    convertElementaryArrayToJSONArray([
      "weekly",
      "Biweekly",
      "monthly",
      "quarterly",
    ])
  );
  const [files, setFiles] = React.useState(
    convertElementaryArrayToJSONArray(["pdf", "csv", "xls"])
  );

  const [request, setRequest] = React.useState({
    type: "",
    date: "",
    email: "",
    add_email: "",
    file: "",
  });
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      //   toggle={handleModal}
    >
      <ModalHeader style={{ background: "#f8f8fd" }}>
        <div className="d-flex align-items-center w-100">
          <ReportsSvgComponent size={28} color={"#FD9933"} />
          <h6 className="header_text__18 ms-2">Schedule Report</h6>
        </div>
      </ModalHeader>
      <div className="m-4">
        <h6 className="header_text mb-3">Schedule Frequency</h6>

        <div className="d-flex align-items-center">
          {list.map((item: object) => {
            return (
              <div
                className={`d-flex align-items-center border p-2 border-radius__5 mx-2 cursor-pointer`}
                onClick={() => {
                  setRequest({
                    ...request,
                    type: getValue(item, `value`, ""),
                  });
                }}
              >
                <input
                  type="radio"
                  className="form-radio"
                  checked={
                    getValue(item, `value`, "") ===
                    getValue(props, `request.type`, "")
                      ? true
                      : false
                  }
                  onChange={(e: any) =>
                    setRequest({
                      ...request,
                      type: getValue(item, `value`, ""),
                    })
                  }
                />
                <p className="small_text__14 ms-2">
                  {getValue(item, `label`, "")}
                </p>
              </div>
            );
          })}
        </div>

        <div className="mt-3">
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              Start Date <span>*</span>{" "}
            </label>
            <InputField
              inputType="DATETYPE-DOB"
              placeholder="Enter Start Date"
              className="form-control release-date-wrapper__vendor-form-control--calendar"
              name="date"
              id="date"
              value={getValue(request, `date`, "")}
              selected={getValue(request, `date`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  date: e,
                })
              }
              label="Start Date"
              minDate={new Date()}
            />
          </div>
        </div>

        <div className="user-details-wrapper__form-group form-group mt-3">
          <label className="checkpoint-active-details__label">
            Email Recipients<span>*</span>{" "}
          </label>
          <InputField
            inputType="TEXT"
            placeholder="Enter Email"
            className="form-control w-100"
            name="email"
            id="email"
            label="Email"
            value={getValue(request, `email`, "")}
            // onChange={(e: any) => handleChangeText(e)}
            onChange={(e: any) =>
              setRequest({
                ...request,
                email: e.target.value,
              })
            }
          />
        </div>

        <div className="user-details-wrapper__form-group form-group mt-3">
          <label className="checkpoint-active-details__label">
            Additional Recipients <span></span>{" "}
          </label>
          <InputField
            inputType="TEXTAREA"
            placeholder="Enter multiple email addresses, seperated by commas"
            className="form-control w-100"
            name="add_email"
            id="add_email"
            label="Email"
            value={getValue(request, `add_email`, "")}
            // onChange={(e: any) => handleChangeText(e)}
            onChange={(e: any) =>
              setRequest({
                ...request,
                add_email: e.target.value,
              })
            }
          />
        </div>

        <div className="mt-3 bg-body p-3">
          <h6 className="header_text__14 mb-2">Attach report as</h6>
          <div className="d-flex align-items-center">
            {files.map((item: object) => {
              return (
                <div
                  className={`d-flex align-items-center border p-2 border-radius__5 mx-2 cursor-pointer bg-white`}
                  onClick={() => {
                    setRequest({
                      ...request,
                      file: getValue(item, `value`, ""),
                    });
                  }}
                >
                  <input
                    type="radio"
                    className="form-radio"
                    checked={
                      getValue(item, `value`, "") ===
                      getValue(props, `request.file`, "")
                        ? true
                        : false
                    }
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        file: getValue(item, `value`, ""),
                      })
                    }
                  />
                  <p className="small_text__14 ms-2">
                    {getValue(item, `label`, "").toUpperCase()}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ModalFooter>
        <div className="delete-popup-wrapper__cta-wrapper">
          <button
            onClick={() => {
              props.handleModal();
            }}
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--secondary"
          >
            Cancel
          </button>
          <button
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--primary"
            onClick={props.handleSubmit}
            disabled={props.submitLoading}
          >
            {props.submitLoading ? "Please wait..." : "Submit"}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ScheduleModal;
