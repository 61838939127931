import { convertCurrentDate } from "@common/date-helpers";
import { formatAmount } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";

function ReportTitleHeader(props: any) {
  const { formInfo } = props;
  return (
    <div>
      <div className="report-detail-wrapper_left_container_header_section">
        <div className="d-flex justify-content-between w-100">
          <div>
            <h6 className="header_text">
              {getValue(formInfo, `report_name`, "")} 
              {/* ({getValue(formInfo, `report_id`, "")}) */}
            </h6>
            <p className="desc_text">
              Duration :{" "}
              {convertCurrentDate(getValue(formInfo, `duration_from`, ""))} -{" "}
              {convertCurrentDate(getValue(formInfo, `duration_to`, ""))}
            </p>
            {getValue(formInfo, `approval_config.id`, "") && (
              <p className="small_text__12">
                Approval ID: {getValue(formInfo, `approval_config.id`, "")}
              </p>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <h6 className="header_text text-right">
                {getValue(formInfo, `currency_symbol`, "")}{" "}
                {formatAmount(getValue(formInfo, `total`, ""))}
              </h6>
              <p className="desc_text text-right">Total</p>
            </div>
            {/* <div className="ms-5">
              <h6 className="header_text">{"INR 0.00"}</h6>
              <p className="desc_text">Amount to be reimbursed</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportTitleHeader;
