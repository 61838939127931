import HotelCityPopup from "../../Dropdown/hotel-city-popup";
import { getValue } from "@utils/lodash";
import HotelSvgComponent from "@assets/svg-components/trips/hotel";
import {
  formHandleSelectArrayChangeByKey,
  formHandleSelectArrayChangeByKeyValue,
} from "@common/handleChange";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";
import LocationSvgComponent from "@assets/svg-components/trips/location";

function TripCityComponent(props: any) {
  const { item, index, city_name_key, city_key, label } = props;
  return (
    <div>
      <div className="d-flex">
        <label className="trip-label ms-0">{label}</label>
        <span className="form-label-error_red">*</span>
      </div>
      <HotelCityPopup
        header={
          <div
            className={`d-flex align-items-center mb-3 ${
              props.validator &&
              props.validator.current.message(
                "City",
                getValue(item, `${city_name_key}`, ""),
                "required"
              )
                ? "border_red"
                : "border"
            } justify-content-between border-radius__5 cursor-pointer flight_search_section hotel_search_section`}
            style={{ width: getValue(props, `width`, "") }}
          >
            <div className="d-flex align-items-center">
              <div className="">
                {props.locationIcon ? (
                  <LocationSvgComponent />
                ) : (
                  <HotelSvgComponent />
                )}
              </div>
              <p className="small_text__14 ml-8">
                {getValue(item, `${city_name_key}`, "")
                  ? getValue(item, `${city_name_key}`, "")
                  : "Select City"}
              </p>
            </div>
            <div className="">
              {getValue(item, `${city_name_key}`, "") ? (
                <div
                  className=""
                  onClick={() => {
                    formHandleSelectArrayChangeByKey(
                      index,
                      "",
                      city_key,
                      props.setItenaries
                    );
                    formHandleSelectArrayChangeByKey(
                      index,
                      "",
                      city_name_key,
                      props.setItenaries
                    );
                  }}
                >
                  <CloseSmallSvgComponent />
                </div>
              ) : (
                <ArrowDownSvgComponent color={"gray"} />
              )}
            </div>
          </div>
        }
        value={getValue(item, `${city_key}`, "")}
        onChange={(e: object) => {
          formHandleSelectArrayChangeByKey(
            index,
            e,
            city_key,
            props.setItenaries
          );
          formHandleSelectArrayChangeByKeyValue(
            index,
            e,
            city_name_key,
            "label",
            props.setItenaries
          );
        }}
      />
    </div>
  );
}

export default TripCityComponent;
