import { getValue } from "@utils/lodash";
import React from "react";

function FlightLandSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="flight_land-24px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path id="Path_11" data-name="Path 11" d="M0,0H24V24H0Z" fill="none" />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M2.5,19h19v2H2.5Zm16.84-3.15a1.5,1.5,0,0,0,.78-2.9l-5.31-1.42L12.05,2.51,10.12,2v8.28L5.15,8.95,4.22,6.63,2.77,6.24v5.17Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#fd9933"
          }
        />
      </svg>
    </div>
  );
}

export default FlightLandSvgComponent;
