import LoginPage from "@pages/Auth/Login";
import PasswordReset from "@pages/Auth/PasswordReset";
import ResetPasswordPage from "@pages/Auth/ResetPassword";
import SentEmail from "@pages/Auth/SentEmail";
// import ExpenseReportApprove from "@pages/Auth/TripsReport/ExpenseApprove";
// import ExpenseReportReject from "@pages/Auth/TripsReport/ExpenseReject";
// import TripApprove from "@pages/Auth/TripsReport/TripApprove";
// import TripReject from "@pages/Auth/TripsReport/TripReject";
import SSOLoginPage from "@pages/SSO";

export const AuthRoutes = [
  // { path: "/", name: "Login", component: LoginPage  },
  { path: "/login", name: "Login", component: LoginPage },
  { path: "/login/stohrm-sso", name: "Storhm SSO Login", component: SSOLoginPage },
  {
    path: "/auth/forgot-password",
    name: "ResetPassword",
    component: PasswordReset,
  },
  {
    path: "/auth/set-password",
    name: "PasswordReset",
    component: ResetPasswordPage,
  },
  { path: "/sent-email", name: "SentEmail", component: SentEmail },
  // {
  //   path: "/email-approval/trip/:id/approve",
  //   name: "SentEmail",
  //   component: TripApprove,
  // },
  // {
  //   path: "/email-approval/trip/:id/reject",
  //   name: "SentEmail",
  //   component: TripReject,
  // },
  // {
  //   path: "/email-approval/expense-report/:id/approve",
  //   name: "SentEmail",
  //   component: ExpenseReportApprove,
  // },
  // {
  //   path: "/email-approval/expense-report/:id/reject",
  //   name: "SentEmail",
  //   component: ExpenseReportReject,
  // },
];
