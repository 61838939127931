import MyViewsTrips from "@pages/Client/Trips";
import ClientTripsCreate from "@pages/Client/Trips/create";
import ClientTripsDetail from "@pages/Client/Trips/detail";

export const ClientTripsRoutes = [
  //expenses
  {
    path: "/client/trips",
    name: "trips",
    component: MyViewsTrips,
  },
  {
    path: "/client/trips/add",
    name: "trips",
    component: ClientTripsCreate,
  },
  {
    path: "/client/trips/edit/:id",
    name: "trips",
    component: ClientTripsCreate,
  },
  {
    path: "/client/trips/detail/:id",
    name: "trips",
    component: ClientTripsDetail,
  },
];
