import { getValue } from "@utils/lodash";
import React from "react";

function AdminViewSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="admin_panel_settings_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        // width="24"
        // height="24"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <g id="Group_9783" data-name="Group 9783">
          <rect
            id="Rectangle_17084"
            data-name="Rectangle 17084"
            width="24"
            height="24"
            fill="none"
          />
        </g>
        <g id="Group_9785" data-name="Group 9785">
          <g id="Group_9784" data-name="Group 9784">
            <circle
              id="Ellipse_3619"
              data-name="Ellipse 3619"
              cx="1.12"
              cy="1.12"
              r="1.12"
              transform="translate(15.88 14.38)"
              //   fill="#d7242e"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#3c4043"
              }
            />
            <path
              id="Path_45612"
              data-name="Path 45612"
              d="M17,17.5c-.73,0-2.19.36-2.24,1.08a2.729,2.729,0,0,0,4.48,0C19.19,17.86,17.73,17.5,17,17.5Z"
              //   fill="#d7242e"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#3c4043"
              }
              fillRule="evenodd"
            />
            <path
              id="Path_45613"
              data-name="Path 45613"
              d="M18,11.09V6.27L10.5,3,3,6.27v4.91A10.213,10.213,0,0,0,10.5,21a9.166,9.166,0,0,0,1.6-.55A6,6,0,1,0,18,11.09ZM11,17a5.766,5.766,0,0,0,.23,1.62,6.587,6.587,0,0,1-.73.3A8.208,8.208,0,0,1,5,11.18V7.58l5.5-2.4L16,7.58v3.51A6,6,0,0,0,11,17Zm6,4a4,4,0,1,1,4-4A4,4,0,0,1,17,21Z"
              //   fill="#d7242e"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#3c4043"
              }
              fillRule="evenodd"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default AdminViewSvgComponent;
