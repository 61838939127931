import { getValue } from "@utils/lodash";
import React from "react";

function SeatSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="airline_seat_recline_extra_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          id="Path_45634"
          data-name="Path 45634"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_45635"
          data-name="Path 45635"
          d="M5.35,5.64a2,2,0,1,1,2.79-.49,2.013,2.013,0,0,1-2.79.49ZM16,19H8.93a2.991,2.991,0,0,1-2.96-2.54L4,7H2l1.99,9.76A5.01,5.01,0,0,0,8.94,21H16Zm.23-4H11.35l-1.03-4.1a7.737,7.737,0,0,0,5.15,1.22V9.99a6.093,6.093,0,0,1-4.69-1.25L9.14,7.47a2.262,2.262,0,0,0-.76-.38,2.209,2.209,0,0,0-.99-.06H7.37A2.268,2.268,0,0,0,5.53,9.64l1.35,5.92A3.008,3.008,0,0,0,9.83,18h6.85l3.82,3L22,19.5Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#fd9933"
          }
        />
      </svg>
    </div>
  );
}

export default SeatSvgComponent;
