import React, { useEffect, useState } from "react";
import { getValue } from "@utils/lodash";
import "./user.scss";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import PaginationPage from "@components/pagination/pagination";
import Notfound from "@components/Not-Found";
import Loader from "@components/common/Loader";
import moment from "moment";
import ScrollableReactTable from "@components/ScrollableDatatable";
import { useParams } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getImports } from "@services/import.service";
import { formatText } from "@common/text-helpers";
import FileLogsTable from "./table";
import SearchHeader from "@components/common/SearchHeader";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import GearLoader from "@components/common/GearLoader";
interface IAdminUsersProps {}

const FileLogs: React.FunctionComponent<IAdminUsersProps> = (props: any) => {
  let navigate = useNavigate();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [usersList, setUsersList] = React.useState<[]>([]);
  const [group_name, setGroup_name] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");

  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleModalCancel = () => {
    setIsOpen(!isOpen);
    setEdit(false);
    setEditId("");
    setGroup_name("");
  };
  const handleModalEdit = (item: object) => {
    setEdit(true);
    setGroup_name(getValue(item, `group_name`, ""));
    setEditId(getValue(item, `id`, ""));
    handleModal();
  };
  const [allStatus] = useState([
    { value: "", label: "All" },
    { value: "STARTED", label: "Started" },
    { value: "STOPPED", label: "Stopped" },
    { value: "SUCCESS", label: "Success" },
    { value: "FAILED", label: "Failed" },
  ]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const mainParam = useParams();
  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let payload: any = {
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      search_text: params.search_text,
      status: params.status ? params.status : "",
      sort_by: params.sort_by,
      type: getValue(mainParam, `type`, ""),
      sortBy: "newest",
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getImports(queryRequest);
      if (resp) {
        setUsersList(
          getValue(resp, `data.imports`, []).map((item: object) => ({
            ...item,
            status: getValue(item, `queue_status`, "")
              ? formatText(getValue(item, `queue_status`, ""))
              : "",
            type_formatted: getValue(item, `type`, "")
              ? formatText(getValue(item, `type`, ""))
              : "",
            created_at: convertCurrentDateWithTime(
              getValue(item, `created_at`, "")
            ),
          }))
        );
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSortBy = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.sort_by = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };

  const handleChangeStatus = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (value) {
      let payload = {
        ...params,
        status: value,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    } else {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      delete params.status;
      let payload = {
        ...params,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const getTableState = (value: object) => {
    let selectedIndexList = Object.keys(
      getValue(value, `selectedRowIds`, {})
    ).map((item) => parseInt(item));
    let selectedIds =
      usersList.length > 0
        ? usersList.filter((_item: object, index: number) =>
            selectedIndexList.includes(index)
          )
        : [];
    if (selectedIds.length > 0) {
      setSelectedIDs(
        selectedIds.map((item: object) => getValue(item, `id`, ""))
      );
    } else {
      setSelectedIDs([]);
    }
  };
  const rowClick = (obj: object) => {
    handleModalEdit(getValue(obj, `original`, ""));
  };

  const rowClickUpdate = () => {};

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);

    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleEdit = (id: string, module: string) => {
    navigate(`/admin/schedules/logs/${id}/${module}`);
  };
  const getTrProps = (rowInfo: any) => {
    if (getValue(rowInfo, `id`, "")) {
      return {
        style: {
          //   background:  'red',
          //   color: 'black'
        },
      };
    }
    return {};
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [check, setCheck] = useState(false);
  return (
    <>
      <DashboardLayout
        permissions={getValue(props, `tabPermissionList`, [])}
        subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
      >
        <>
          <div className="dashboard-wrapper__header">
            <h4 className="dashbaord-wrapper__header-title">Logs</h4>
          </div>
          <div className="admin-wrapper position-relative">
            <div className="admin-wrapper position-relative">
              <SearchHeader
                route={"/client/expenses/add"}
                add="false"
                search={"false"}
              />
            </div>
          </div>
          <div
            className={
              getValue(selectedIDs, `length`, 0) > 0
                ? "datatable_main_container_notifiction"
                : "datatable_main_container"
            }
            style={{ overflowY: isLoading ? "hidden" : "auto" }}
          >
            {isLoading || !props.permissionAPITriggered ? (
              <GearLoader />
            ) : getValue(usersList, `length`, 0) > 0 ? (
              <FileLogsTable list={usersList} />
            ) : (
              <>
                <Notfound />
              </>
            )}
          </div>
          <div className="footer-wrapper d-flex justify-content-between">
            {usersList.length > 0 && (
              <img
                src="/images/building-illustration.svg"
                className="img-fluid"
              />
            )}
            {totalCount > 10 ? (
              <PaginationPage
                totalCount={totalCount}
                limit={limit}
                pageNumber={page_no}
                handleChangePagination={handleChangePagination}
                handleChangeLimit={handleChangeLimit}
              />
            ) : (
              <p className="show-rows-count mt-3 d-flex">
                Total Count:&nbsp;&nbsp;{" "}
                <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
              </p>
            )}
          </div>
        </>
        {/* )}  */}
      </DashboardLayout>{" "}
    </>
  );
};

export default FileLogs;
