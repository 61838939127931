import React from "react";
import { getValue } from "@utils/lodash";
import { concatString, formatText } from "@common/text-helpers";
import EditSvgComponent from "@assets/svg-components/edit";
import DeleteSvgComponent from "@assets/svg-components/delete";
import { getColorStyle } from "@common/color";
import DownloadSvgComponent from "@assets/svg-components/download";
import { handleDownloadOnClick } from "@common/downloadfile";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

function AnalyticsTable(props: any) {
  return (
    <div>
      <table className="datatable_table">
        <thead className="datatable_table_thead">
          <th className="datatable_table_th" style={{ width: "70px" }}>
            <label className="m-0 position-relative" htmlFor="header-checkbox">
              <input
                type="checkbox"
                className="default-checkbox"
                id="header-checkbox"
                onChange={() =>
                  props.handleCheckAll(
                    getValue(props, `list.length`, 0) ===
                      getValue(props, `selectedIDs.length`, 0)
                      ? "All"
                      : "None"
                  )
                }
                checked={
                  getValue(props, `list.length`, 0) ===
                  getValue(props, `selectedIDs.length`, 0)
                }
              />
              <div className="custom-checkbox">
                <img
                  src="/images/uploaded-details/tick.svg"
                  className="img-fluid uploaded-tick"
                />
              </div>
            </label>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Job ID</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Report Name</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Report Description</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Report Type</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">User</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Action</h6>
          </th>
        </thead>
        <tbody className="datatable_table_tbody">
          {getValue(props, `list`, []).map((item: any, index: number) => {
            return (
              <tr key={index} className="datatable_table_tr">
                <td className="datatable_table_td" style={{ width: "70px" }}>
                  <label
                    className="m-0 position-relative "
                    htmlFor={`header-checkbox-${index}`}
                  >
                    <input
                      type="checkbox"
                      className="default-checkbox"
                      id={`header-checkbox-${index}`}
                      onChange={() =>
                        props.handleCheckUsers(getValue(item, `id`, ""))
                      }
                      checked={getValue(props, `selectedIDs`, []).includes(
                        getValue(item, `id`, "")
                      )}
                    />
                    <div className="custom-checkbox">
                      <img
                        src="/images/uploaded-details/tick.svg"
                        className="img-fluid uploaded-tick"
                      />
                    </div>
                  </label>
                </td>
                <td
                  className="datatable_table_td"
                  onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
                >
                  <h6 className=" color_primary">
                    {getValue(item, `job_id`, "")}
                  </h6>
                </td>
                <td
                  className="datatable_table_td"
                  onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
                >
                  <h6 className="">
                    {concatString(getValue(item, `report_name`, ""), 30)}
                  </h6>
                </td>
                <td
                  className="datatable_table_td"
                  onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
                >
                  <h6 className="">
                    {concatString(getValue(item, `report_description`, ""), 30)}
                  </h6>
                </td>
                <td
                  className="datatable_table_td"
                  onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
                >
                  <h6 className="">
                    {formatText(getValue(item, `report_type`, ""))}
                  </h6>
                </td>
                <td
                  className="datatable_table_td"
                  onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
                >
                  <h6 className="">
                    {getValue(item, `user.first_name`, "")}{" "}
                    {getValue(item, `user.last_name`, "")}
                  </h6>
                  <p>{getValue(item, `user.email`, "")}</p>
                </td>
                <td className="datatable_table_td action-icon-td">
                  {getValue(props, `permissions`, []).includes("update") && (
                    <div className="d-flex align-items-center  ">
                      {/* <DownloadSvgComponent color={"#408dfb"} size={22} />
                    <h6 className="header_text__14 color_primary">Download</h6> */}

                      <UncontrolledDropdown
                        className="table-action-icon-wrap me-4"
                        direction="start"
                        container={"body"}
                      >
                        <DropdownToggle>
                          <div className="switch_header__dropdown m-0 align-items-center">
                            <div className="close-image_container action-icon-wrap">
                              <img
                                src={"/images/more.svg"}
                                className=" close_image"
                              />
                            </div>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>
                            <div
                              className={`more-dropdown-main-item `}
                              onClick={() =>
                                handleDownloadOnClick(
                                  getValue(item, `report_url`, ""),
                                  "report"
                                )
                              }
                            >
                              Download
                            </div>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AnalyticsTable;
