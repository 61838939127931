import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllMileages = (queryRequest: string) =>
  get(`${config.API_URL}/mileage?${queryRequest}`);

export const getMileageByPolicy = (id: any) =>
  get(`${config.API_URL}/mileage/policy/${id}`);

export const getSpecificMileage = (id: string) =>
  get(`${config.API_URL}/mileage/${id}`);

export const createMileage = (payload: object) =>
  post(`${config.API_URL}/mileage`, payload);

export const updateMileage = (id: string, payload: object) =>
  patch(`${config.API_URL}/mileage/${id}`, payload);

export const deleteMileage = (id: string) =>
  Delete(`${config.API_URL}/mileage/${id}`);

  export const getSpecificMileageAmount = (queryRequest: string) =>
  get(`${config.API_URL}/mileage/expense/mileage-rates?${queryRequest}`);