import { getValue } from "@utils/lodash";
import { NavLink } from "react-router-dom";

export default function TripsTabNavigation(props: any) {
  return (
    <div className="common-sticky-header trips_details_navigation_wrapper">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative ">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <ul className="inner-page-navigation-with-back-cta-buttons__navigation_left_temp d-inline-flex position-absolute cursor-pointer">
            {getValue(props, `flightList.length`, 0) > 0 && (
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  getValue(props, `activeTab`, "") === "flight" ? "active" : ""
                }`}
                onClick={() => props.setActiveTab("flight")}
              >
                Flight
              </li>
            )}
            {getValue(props, `hotelList.length`, 0) > 0 && (
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  getValue(props, `activeTab`, "") === "hotel" ? "active" : ""
                }`}
                onClick={() => props.setActiveTab("hotel")}
              >
                Hotel
              </li>
            )}
            {getValue(props, `carList.length`, 0) > 0 && (
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  getValue(props, `activeTab`, "") === "car" ? "active" : ""
                }`}
                onClick={() => props.setActiveTab("car")}
              >
                Car Rental
              </li>
            )}
            {getValue(props, `trainList.length`, 0) > 0 && (
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  getValue(props, `activeTab`, "") === "train" ? "active" : ""
                }`}
                onClick={() => props.setActiveTab("train")}
              >
                Train
              </li>
            )}
            {getValue(props, `busList.length`, 0) > 0 && (
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  getValue(props, `activeTab`, "") === "bus" ? "active" : ""
                }`}
                onClick={() => props.setActiveTab("bus")}
              >
                Bus
              </li>
            )}
          </ul>
          {/* <button
            className={`ascent-button ascent-button--header-buttons ascent-button-secondary`}
          ></button> */}
        </div>
      </section>
    </div>
  );
}
