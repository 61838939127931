import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import "./email-template.scss";
import { getValue } from "@utils/lodash";
import {
  deleteEmailTemplate,
  getEmailTemplateKeys,
  getSpecificEmailTemplate,
} from "@services/email-template.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { useNavigate } from "react-router-dom";
import Notfound from "@components/Not-Found";
import { formatText } from "@common/text-helpers";
import PaginationPage from "@components/pagination/pagination";
import { toast } from "react-toastify";
import SearchHeader from "@components/common/SearchHeader";
import DeleteModal from "@common/popup/DeleteModal";
import GearLoader from "@components/common/GearLoader";
import EmailTemplateTable from "./table";
import NavigationBar from "@components/NotificationBar";
import {
  createPaidThroughAccount,
  getAllPaidThroughAccount,
  getSpecificPaidThroughAccount,
  updatePaidThroughAccount,
  updateaidThroughAccountBulk,
} from "@services/paid-through-accounts.service";
import PaidThroughAccountPopup from "./add-popup";
import PaidThroughAccountTable from "./table";
import NoAccessPage from "@components/common/NoAccess";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";

export default function PaidThroughAccount(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const navigate = useNavigate();

  const [paidThroughAccountList, setPaidThroughAccountList] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      let payload = {
        page_no: params.page_no ? parseInt(params.page_no) : 1,
        page_size: params.limit ? parseInt(params.limit) : limit,
        search_text: params.search_text,
      };
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading(true);
      let resp = await getAllPaidThroughAccount(queryRequest);
      if (resp) {
        setPaidThroughAccountList(getValue(resp, `data.data`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [keysInfo, setKeys] = useState({});
  const getKeys = async (type: string) => {
    let payload = {
      // type: type ? type : "EXECUTOR_REMAINDER_TEMPLATE",
      type: type,
    };
    if (type) {
      let queryRequest = QueryRequestHelper(payload);
      try {
        let resp = await getEmailTemplateKeys(queryRequest);
        if (resp) {
          setKeys(getValue(resp, `data`, {}));
        } else {
        }
      } catch (error) {}
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);

    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      // removing null values
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteEntity = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteEmailTemplate(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Edit section                             */
  /* -------------------------------------------------------------------------- */

  const [editId, setEditId] = useState("");
  const handleEditEntity = async (id: string) => {
    setEditId(id);
    try {
      let resp = await getSpecificPaidThroughAccount(id);
      if (resp) {
        setRequest({
          ...request,
          account_name: getValue(resp, `data.account_name`, ""),
          account_code: getValue(resp, `data.account_code`, ""),
          account_type: getValue(resp, `data.account_type`, ""),
          description: getValue(resp, `data.description`, ""),
        });
        setShowUpdatePopup(true);
      }
    } catch (error) {}
  };

  /* ---------------------------  Insert Modal  ----------------------------- */

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const handlePopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
    setRequest({
      account_name: "",
      account_code: "",
      account_type: "CASH",
      description: "",
    });
  };
  const [typeList] = useState([
    {
      value: "CASH",
      label: "Cash",
    },
    {
      value: "COMPANY_CARD",
      label: "Company Card",
    },
    {
      value: "PERSONAL_CARD",
      label: "Personal Card",
    },
  ]);
  const [request, setRequest] = useState({
    account_name: "",
    account_code: "",
    account_type: "CASH",
    description: "",
  });
  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      let payload = request;
      setSubmitLoading(true);
      if (editId) {
        let resp = await updatePaidThroughAccount(editId, payload);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoading(false);
          setShowUpdatePopup(false);
          getData();
          setEditId("");
        } else {
          setSubmitLoading(false);
        }
      } else {
        let resp = await createPaidThroughAccount(payload);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoading(false);
          setShowUpdatePopup(false);
          getData();
        } else {
          setSubmitLoading(false);
        }
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);

  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(paidThroughAccountList, `length`, 0) > 0
          ? paidThroughAccountList.map((item: object) =>
              getValue(item, `id`, "")
            )
          : [];
      setSelectedIDs(userIDs);
    }
  };
  const handleChangeActiveStatus = async (value: string) => {
    try {
      let resp = await updateaidThroughAccountBulk({
        ids: selectedIDs,
        is_active: value === "active" ? true : false,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      } else {
        getData();
      }
    } catch (error) {}
  };
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  const handleNavigateUserEntity = () => {
    if (getValue(selectedIDs, `[${0}]`, "")) {
      // navigate(
      //   `/admin/user/entity-mapping/${getValue(selectedIDs, `[${0}]`, "")}`
      // );
    } else {
      toast.error("Please select Email Template");
    }
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      <>
        <div className="dashboard-wrapper__header">
          <h4 className="dashbaord-wrapper__header-title">
            Paid Through Accounts
          </h4>
        </div>
        {getValue(selectedIDs, `length`, 0) > 0 && (
          <NavigationBar
            selectedIDs={selectedIDs}
            // isMarkInactiveSectionVisible={true}
            handleChangeActiveStatus={handleChangeActiveStatus}
            text={"MARK INACTIVE"}
            handleRemoveNavItems={handleRemoveNavItems}
            multi={
              getValue(props, `permissions`, []).includes("update")
                ? true
                : false
            }
            permissions={getValue(props, `permissions`, [])}
            entityMapping={
              getValue(props, `products`, []).includes("SELFCOMP")
                ? true
                : false
            }
            handleNavigateUserEntity={handleNavigateUserEntity}
          />
        )}
        <div className="admin-wrapper position-relative">
          <div className="admin-wrapper position-relative">
            <SearchHeader
              searchInput={search_text}
              showSearchTextbox={showSearchTextbox}
              toggleSearch={toggleSearch}
              toggleSearchClose={toggleSearchClose}
              handleChangeSearch={handleChangeSearch}
              isAdd={
                getValue(props, `permissions`, []).includes("create")
                  ? true
                  : false
              }
              handleAddFun={() => handlePopup()}
              add={
                getValue(props, `permissions`, []).includes("create")
                  ? "true"
                  : "false"
              }
            />
          </div>
        </div>
        <div
          className={
            getValue(selectedIDs, `length`, 0) > 0
              ? "datatable_main_container_notifiction"
              : "datatable_main_container"
          }
          style={{ overflowY: isLoading ? "hidden" : "auto" }}
        >
          {!isLoading &&
          !getValue(props, `subTabPermissionList`, []).includes(
            "admin_modules_paid_through_accounts"
          ) ? (
            <NoAccessPage />
          ) : isLoading || !props.permissionAPITriggered ? (
            // <GearLoader />
            <SkeletonTable />
          ) : (
            <>
              {" "}
              {getValue(paidThroughAccountList, `length`, 0) > 0 ? (
                <>
                  <PaidThroughAccountTable
                    list={paidThroughAccountList}
                    selectedIDs={selectedIDs}
                    handleCheckUsers={handleCheckUsers}
                    handleCheckAll={handleCheckAll}
                    handleEditEntity={handleEditEntity}
                    permissions={getValue(props, `permissions`, [])}
                  />
                </>
              ) : (
                <>
                  {Object.keys(params).length !== 0 ? (
                    <Notfound />
                  ) : (
                    <Notfound />
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className="fixed_footer_wrapper d-flex justify-content-between">
          {paidThroughAccountList.length > 0 && (
            <img
              src="/images/building-illustration.svg"
              className="img-fluid"
            />
          )}
          {totalCount > 10 ? (
            <PaginationPage
              totalCount={totalCount}
              limit={limit}
              pageNumber={page_no}
              handleChangePagination={handleChangePagination}
              handleChangeLimit={handleChangeLimit}
            />
          ) : (
            <p className="show-rows-count mt-3 d-flex">
              Total Count:&nbsp;&nbsp;{" "}
              <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
            </p>
          )}
        </div>
      </>

      <PaidThroughAccountPopup
        showUpdatePopup={showUpdatePopup}
        setShowUpdatePopup={setShowUpdatePopup}
        keysInfo={keysInfo}
        request={request}
        handleChangeText={handleChangeText}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
        editId={editId}
        setRequest={setRequest}
        typeList={typeList}
      />
      <DeleteModal
        isOpen={isDelete}
        handleModal={handleDelete}
        handleSubmit={handleDeleteFunction}
        deleteValue={deleteValue}
      />
    </DashboardLayout>
  );
}
