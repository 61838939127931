import { getValue } from "@utils/lodash";
import React from "react";

function SettingsSvgComponent(props) {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        // width="18"
        // height="18"
        // throwIfNamespace={false}
        viewBox="0 0 18 18"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_17088"
              data-name="Rectangle 17088"
              width="18"
              height="18"
              transform="translate(-14074 -7757)"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g
          id="Mask_Group_3"
          data-name="Mask Group 3"
          transform="translate(14074 7757)"
          clip-path="url(#clip-path)"
        >
          <g id="tune_black_24dp" transform="translate(-14074 -7757)">
            <path
              id="Path_45620"
              data-name="Path 45620"
              d="M0,0H18V18H0Z"
              fill="none"
            />
            <path
              id="Path_45621"
              data-name="Path 45621"
              d="M2.25,12.75v1.5h4.5v-1.5Zm0-9v1.5h7.5V3.75Zm7.5,12v-1.5h6v-1.5h-6v-1.5H8.25v4.5Zm-4.5-9v1.5h-3v1.5h3v1.5h1.5V6.75Zm10.5,3V8.25H8.25v1.5Zm-4.5-3h1.5V5.25h3V3.75h-3V2.25h-1.5Z"
              // fill="#3c4043"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#3c4043"
              }
            />
          </g>
        </g>
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // height="24px"
        viewBox="0 0 24 24"
        // width="24px"
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#3c4043"
        }
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z" />
      </svg>
    </>
  );
}

export default SettingsSvgComponent;
