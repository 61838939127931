import React, { useEffect, useState, useRef } from "react";
import "./department-info.scss";
import AdminNavigation from "@components/Admin/admin-navigation";
import { toast } from "react-toastify";
import InputField from "@common/InputRuleFeild";
import { getValue, setValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import _ from "lodash";
import PromptModal from "@prompt/modal";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import GearLoader from "@components/common/GearLoader";
import { StylesConfig } from "react-select";
import {
  createDepartment,
  getSpecificDepartment,
  updateDepartment,
} from "@services/departments.service";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import NoAccessPage from "@components/common/NoAccess";

interface IAdminUserControlUserDetailsProps {}

const AdminUserControlDepartmentDetails: React.FunctionComponent<IAdminUserControlUserDetailsProps> = (
  props: any
) => {
  let orgParams = useParams();
  let params = useParams();
  let navigate = useNavigate();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = useState({
    department_name: "",
    department_code: "",
    description: "",
    department_head_id: 0,
  });

  const [dirtyFields, setDirtyFields] = useState<any>({
    department_name: "",
    department_code: "",
    description: "",
    department_head_id: 0,
  });

  const reset = () => {
    setRequest({
      ...request,
      department_name: "",
      department_code: "",
      description: "",
      department_head_id: 0,
    });
    setDirtyFields({
      ...dirtyFields,
      department_name: "",
      department_code: "",
      description: "",
      department_head_id: 0,
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (props.permissionAPITriggered) {
      if (getValue(params, `id`, "")) {
        getDepartment(getValue(params, `id`, ""));
      } else {
        setLoading(false);
      }
    }
  }, [props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getDepartment = async (id: string) => {
    try {
      setLoading(true);
      let resp = await getSpecificDepartment(id);
      if (resp) {
        setRequest({
          ...request,
          department_name: getValue(resp, `data.department_name`, ""),
          department_code: getValue(resp, `data.department_code`, ""),
          description: getValue(resp, `data.description`, ""),
          department_head_id: getValue(resp, `data.department_head.id`, ""),
        });
        setDirtyFields({
          ...dirtyFields,
          department_name: getValue(resp, `data.department_name`, ""),
          department_code: getValue(resp, `data.department_code`, ""),
          description: getValue(resp, `data.description`, ""),
          department_head_id: getValue(resp, `data.department_head.id`, ""),
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let payload = {
          department_name: getValue(request, `department_name`, ""),
          department_code: getValue(request, `department_code`, ""),
          description: getValue(request, `description`, ""),
          department_head_id: getValue(request, `department_head_id`, ""),
        };
        if (getValue(params, `id`, "")) {
          let resp = await updateDepartment(
            getValue(params, `id`, ""),
            payload
          );
          if (resp) {
            setIsLoading(false);
            confirmNavigation();
            toast.success("Updated successfully");
            navigate(`/admin/user-controls/departments`);
          } else {
            setIsLoading(false);
          }
        } else {
          let resp = await createDepartment(payload);
          if (resp) {
            setIsLoading(false);
            reset();
            confirmNavigation();
            toast.success("Created successfully");
            navigate(`/admin/user-controls/departments`);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });
  const [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
  ]: any = useCallbackPrompt(!isChanged);

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const customStyles: StylesConfig = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#dadce0",
      };
    },
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: 43,
      border: state.isFocused ? "1px solid #dadce0" : "1px solid #dadce0",
      boxShadow: state.isFocused ? "0px 0px 0px #dadce0" : "none",
      "&:hover": {
        // border: "1px solid #ff8b67",
        // boxShadow: "0px 0px 6px #ff8b67"
      },
    }),
  };

  return (
    <>
      {/* <Header /> */}
      <Layout>
        {!loading &&
        !getValue(props, `subTabPermissionList`, []).includes(
          "admin_user_controls_departments"
        ) ? (
          <NoAccessPage />
        ) : (
          <>
            <PromptModal
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
            <AdminNavigation
              id={orgParams.id}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              url={`/admin/user-controls/departments`}
              isChanged={isChanged}
              hideHeaderMenu
              permissions={getValue(props, `permissions`, [])}
            />
            {
              <div className="user-details-wrapper mt-0 department-details-wrapper">
                {loading || !props.permissionAPITriggered ? (
                  <GearLoader />
                ) : (
                  <div className="user-details-wrapper-form m-0">
                    <div className="checkpoint-active-details-wrapper__form-wrapper">
                      <div className="checkpoint-active-details-wrapper__form-content">
                        {/* <h4 className="mb-3">
                        {getValue(params, `id`, "") ? "Update" : "Add"} Location
                      </h4> */}
                        <div className="user-details-wrapper__form user-details-wrapper__form--org-profile">
                          <div className="user-details-wrapper__form-group form-group">
                            <label className="checkpoint-active-details__label form-label-error">
                              Department Name <span>*</span>
                            </label>
                            <InputField
                              inputType="TEXT"
                              placeholder="Enter Department Name"
                              className="form-control w-100"
                              name="department_name"
                              id="department_name"
                              value={getValue(request, `department_name`, "")}
                              onChange={(e: any) => handleChangeText(e)}
                              label="Department Name"
                              validator={simpleValidator}
                            />
                          </div>

                          <div className="user-details-wrapper__form-group form-group">
                            <label className="checkpoint-active-details__label form-label-error">
                              Department Code <span>*</span>
                            </label>
                            <InputField
                              inputType="TEXT"
                              placeholder="Enter Department Code"
                              className="form-control w-100"
                              name="department_code"
                              id="department_code"
                              value={getValue(request, `department_code`, "")}
                              onChange={(e: any) => handleChangeText(e)}
                              label="Department Code"
                              validator={simpleValidator}
                            />
                          </div>
                        </div>
                        <div className="user-details-wrapper__form-group form-group department-head-select-wrap">
                          <label className="checkpoint-active-details__label form-label-error">
                            Department Head <span>*</span>
                          </label>
                          <SingleSelectSearchDropdown
                            label={"label"}
                            name="Department Head"
                            value={getValue(request, `department_head_id`, "")}
                            onChange={(e: any) =>
                              setRequest({
                                ...request,
                                department_head_id: getValue(e, `id`, ""),
                              })
                            }
                            lookup_id={getValue(
                              request,
                              `department_head_id`,
                              ""
                            )}
                            placeholder={"Choose Department Head "}
                            lookup_api={"users"}
                            width={"745px"}
                            validator={simpleValidator}
                          />
                        </div>
                        <div className="user-details-wrapper__form-group form-group mt-3">
                          <label className="checkpoint-active-details__label">
                            Description
                          </label>
                          <InputField
                            inputType="TEXTAREA"
                            placeholder="Enter Description"
                            className="form-control w-100"
                            name="description"
                            id="description"
                            value={getValue(request, `description`, "")}
                            onChange={(e: any) => handleChangeText(e)}
                            label="Description"
                            // validator={simpleValidator}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            }
          </>
        )}
      </Layout>
      {/* <div className="map-vendor__footer w-100">
        <img src="/images/footer-image.svg" className="img-fluid" />
      </div> */}
    </>
  );
};

export default AdminUserControlDepartmentDetails;
