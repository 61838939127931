import { getValue } from "@utils/lodash";
import { NavLink } from "react-router-dom";
import BackSvgComponent from "@assets/svg-components/back";
import { formatText } from "@common/text-helpers";
import { getColorStyle } from "@common/color";

function TripsStickyHeader(props: any) {
  const {
    route,
    handleSubmitTrip,
    submitTripLoading,
    handleRejectTrip,
    handleCompleteTrip,
  } = props;

  const findCurrentApproverStausEnum = (arr: any) => {
    let user = getValue(props, `formInfo.current_approver`, "");
    let app =
      getValue(arr, `length`, 0) > 0
        ? arr.filter(
            (item: object) =>
              getValue(item, `approver.id`, "") === getValue(user, `id`, "")
          )
        : [];
    return getValue(app, `length`, 0) > 0
      ? getValue(app, `[${0}].approval_status`, "")
      : getValue(props, `formInfo.current_approver.approval_status`, "");
  };
  console.log(props.formInfo);
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <NavLink to={route ? `${route}` : "#"}>
                <BackSvgComponent />
              </NavLink>
              <p className="header_text">
                {getValue(props, `formInfo.trip_id`, "Trips")}
              </p>
              <h6
                className="header_text__16 ms-3"
                style={getColorStyle(
                  getValue(props, `formInfo.approval_status`, "")
                )}
              >
                {formatText(getValue(props, `formInfo.approval_status`, ""))}
              </h6>
            </div>
          </div>
          {getValue(props, `permissions`, []).includes("update") && (
            <div className="d-flex align-items-center">
              {findCurrentApproverStausEnum(
                getValue(props, `formInfo.trip_approvers`, "")
              ) == "awaiting_approval" ? (
                <>
                  <button
                    className={`ascent-button ascent-button--header-buttons ascent-button--green`}
                    onClick={() => handleSubmitTrip("approve")}
                    disabled={submitTripLoading}
                    style={{ fontWeight: "400" }}
                  >
                    Approve
                  </button>
                  <button
                    className={`ascent-button ascent-button--header-buttons ascent-button--red`}
                    onClick={() => handleRejectTrip("reject")}
                    disabled={submitTripLoading}
                    style={{ fontWeight: "400" }}
                  >
                    Reject
                  </button>
                </>
              ) : (
                <div style={{ height: "48px" }}></div>
              )}
            </div>
          )}
          {getValue(props, `admin`, false) &&
            getValue(props, `formInfo.approval_status`, "") === "approved" &&
            getValue(props, `permissions`, []).includes("update") && (
              <button
                className={`ascent-button ascent-button--header-buttons ascent-button--green`}
                onClick={() => handleCompleteTrip()}
                disabled={submitTripLoading}
                style={{ fontWeight: "400" }}
              >
                {submitTripLoading ? "Please wait..." : "Complete"}
              </button>
            )}
        </div>
      </section>
    </div>
  );
}

export default TripsStickyHeader;
