import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import PerDiemRateLocationPopup from "../popup/per-diem-rate-location";
import { toast } from "react-toastify";
import {
  createPerDiem,
  createPerDiemDuration,
  createPerDiemRates,
  deletePerDiemDuration,
  deletePerDiemRates,
  getAllPerDiemDuration,
  getPerDiemByPolicy,
  updatePerDiem,
  updatePerDiemDuration,
  updatePerDiemRates,
} from "@services/per-diem.service";
import { useParams } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import AddSvgComponent from "@assets/svg-components/add";
import PerDiemRateTravelHoursPopup from "../popup/per-diem-rate-hours";
import {
  convertCurrentDate,
  convertCurrentTime,
  convertDate24Time,
} from "@common/date-helpers";
import SimpleReactValidator from "simple-react-validator";

interface PerDiemPayload {
  name?: string; // Optional field
  amount: any;
  currency: any;
  is_default: boolean;
  country: any;
  city: any;
  effective_date: any;
}

function PerDiem(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const mainParams = useParams();

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  console.log(urlParams, "urlParams");
  console.log(mainParams, "mainParams");
  /* -------------------------------------------------------------------------- */
  /*                             UseEffect section                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getPerDiemList();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                             UseState section                              */
  /* -------------------------------------------------------------------------- */
  //Per Diem Request
  const [perDiemReq, setPerDiemReq] = useState({
    per_diem_expense_type: "",
    is_duration_based_rates_configured: false,
    policy: parseInt(getValue(mainParams, `id`, "")),
  });

  //Per Diem Duration Request
  const [perDiemDurationReq, setPerDiemDurationReq] = useState({
    start_time: "",
    end_time: "",
    percentage: 0,
  });

  //Per Diem Location Request
  const [perDiemRateLocReq, setPerDiemRateLocReq] = useState<any>({
    name: "",
    amount: "",
    currency: "",
    is_default: false,
    country: "",
    city: "",
    effective_date: "",
  });

  /* -------------------------------------------------------------------------- */
  /*                             API section                              */
  /* -------------------------------------------------------------------------- */
  //Get API Per Diem List
  const [isLoading, setIsLoading] = useState(false);
  const [perDiemInfo, setPerDiemInfo] = useState({});
  const [perDiemID, setPerDiemID] = useState("");
  const [perDiemRateList, setPerDiemRateList] = useState([]);

  const getPerDiemList = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getPerDiemByPolicy(
        getValue(urlParams, `policy_id`, "")
          ? parseInt(getValue(urlParams, `policy_id`, ""))
          : parseInt(getValue(mainParams, `id`, ""))
      );
      if (resp) {
        if (getValue(resp, `data.id`, "")) {
          setPerDiemReq({
            ...perDiemReq,
            per_diem_expense_type: getValue(
              resp,
              `data.per_diem_expense_type.id`,
              ""
            ),
            is_duration_based_rates_configured: getValue(
              resp,
              `data.is_duration_based_rates_configured`,
              false
            ),
          });
          setPerDiemInfo(getValue(resp, `data`, {}));
          setPerDiemID(getValue(resp, `data.id`, ""));
          setPerDiemDurartionList(getValue(resp, `data.per_diem_duration`, []));
          setPerDiemRateList(getValue(resp, `data.per_diem_rates`, []));
          // getPerDiemDurationList(parseInt(getValue(mainParams, `id`, "")));
          setIsLoading(false);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  console.log(perDiemInfo, "-------->")

  /* -------------------------------------------------------------------------- */
  /*                          Per Diem Section  Start                           */
  /* -------------------------------------------------------------------------- */

  const [submitLoading1, setSubmitLoading1] = useState(false);
  const handleSubmitPerDiem = async () => {
    setSubmitLoading1(true);
    try {
      let payload = {
        policy: getValue(urlParams, `policy_id`, "")
          ? parseInt(getValue(urlParams, `policy_id`, ""))
          : parseInt(getValue(mainParams, `id`, "")),
        per_diem_expense_type: getValue(
          perDiemReq,
          `per_diem_expense_type`,
          null
        ),
        is_duration_based_rates_configured: getValue(
          perDiemReq,
          `is_duration_based_rates_configured`,
          false
        ),
      };

      let resp = await createPerDiem(payload);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSubmitLoading1(false);
        getPerDiemList();
      }
    } catch (error) {}
  };

  const handleUpdatePerDiem = async (obj: object) => {
    setSubmitLoading1(true);
    try {
      let resp = await updatePerDiem(perDiemID, obj);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getPerDiemList();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                          Per Diem Section  Ends                            */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                          Per Diem Duration Section                         */
  /* -------------------------------------------------------------------------- */
  // Add Duration Popup section State
  const [isPerDiemDurationOpen, setPerDiemDurationOpen] = useState(false);
  const handlePerDiemDurationModal = () => {
    setPerDiemDurationOpen(!isPerDiemDurationOpen);
  };

  /* ----------------------------   Get List For Duration Section   -------------------------------- */
  const [isLoading1, setIsLoading1] = useState(false);
  const [perDiemDurationList, setPerDiemDurartionList] = useState([]);
  const getPerDiemDurationList = async (id: string) => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading1(true);
      let resp = await getAllPerDiemDuration(id, queryRequest);
      if (resp) {
        setPerDiemDurartionList(getValue(resp, `data.mileage_rates`, []));
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };
  /* ----------------------------   Submit Section Per Diem Duration   -------------------------------- */

  const [editIdDurationPerDiem, setEditIdDurationPerDiem] = useState("");
  const [submitLoadingPerDiemDuration, setSubmitLoadingPerDiemDuration] =
    useState(false);
  const handleSubmitPerDiemDuration = async () => {
    try {
      setSubmitLoadingPerDiemDuration(true);
      let resp;
      if (editIdDurationPerDiem) {
        resp = await updatePerDiemDuration(
          editIdDurationPerDiem,
          perDiemDurationReq
        );
      } else {
        resp = await createPerDiemDuration(perDiemID, perDiemDurationReq);
      }
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSubmitLoadingPerDiemDuration(false);
        setPerDiemDurationReq({
          ...perDiemDurationReq,
          start_time: "",
          end_time: "",
          percentage: 0,
        });
        handlePerDiemDurationModal();
        getPerDiemList();
        setEditIdDurationPerDiem("");
      }
      setSubmitLoadingPerDiemDuration(false);
    } catch (error) {
      setSubmitLoadingPerDiemDuration(false);
    }
  };

  /* ----------------------------   Edit Section   -------------------------------- */
  const handleEditPerDiemDuration = (obj: object) => {
    setEditIdDurationPerDiem(getValue(obj, `id`, ""));
    setPerDiemDurationReq({
      ...perDiemDurationReq,
      start_time: getValue(obj, `start_time`, ""),
      end_time: getValue(obj, `end_time`, ""),
      percentage: parseInt(getValue(obj, `percentage`, ""), 10),
    });
    setPerDiemDurationOpen(true);
  };

  /* ----------------------------   Delete Section   -------------------------------- */
  const handleDeletePerDiemDuration = async (id: string) => {
    try {
      let resp = await deletePerDiemDuration(id);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getPerDiemList();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                          Per Diem Duration Section Ends                    */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                          Per Diem Location Section Starts                  */
  /* -------------------------------------------------------------------------- */

  // Add Location Popup section
  const [isPerDiemLocationOpen, setPerDiemLocationOpen] = useState(false);
  const [isPerDiemLocationDefaultRate, setIsPerDiemLocationDefaultRate] =
    useState(false);

  const handlePerDiemLocationModal = (isDefault: any) => {
    setIsPerDiemLocationDefaultRate(isDefault);
    setPerDiemLocationOpen(!isPerDiemLocationOpen);
  };
  const [editIdLocPerDiem, setEditIdLocPerDiem] = useState("");

  const [submitLoadingPerDiemLoc, setSubmitLoadingPerDiemLoc] = useState(false);

  const handleSubmitPerDiemLoc = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoadingPerDiemLoc(true);
        let resp;
  
        let payload;
        if (isPerDiemLocationDefaultRate) {
          payload = {
            amount: parseInt(getValue(perDiemRateLocReq, "amount", 0)),
            currency: getValue(perDiemRateLocReq, "currency", null),
            effective_date: getValue(perDiemRateLocReq, "effective_date", ""),
            is_default: isPerDiemLocationDefaultRate
              ? isPerDiemLocationDefaultRate
              : false,
          };
        } else {
          payload = {
            amount: parseInt(getValue(perDiemRateLocReq, "amount", 0)),
            currency: getValue(perDiemRateLocReq, "currency", null),
            country: getValue(perDiemRateLocReq, "country", null),
            city: getValue(perDiemRateLocReq, "city", null),
            effective_date: getValue(perDiemRateLocReq, "effective_date", ""),
            is_default: isPerDiemLocationDefaultRate
              ? isPerDiemLocationDefaultRate
              : false,
          };
        }
        if (editIdLocPerDiem) {
          resp = await updatePerDiemRates(editIdLocPerDiem, payload);
        } else {
          resp = await createPerDiemRates(perDiemID, payload);
        }
  
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoadingPerDiemLoc(false);
          setPerDiemRateLocReq({
            ...perDiemRateLocReq,
            name: "",
            amount: "",
            currency: "",
            is_default: false,
            country: "",
            city: "",
            effective_date: "",
          });
          setPerDiemLocationOpen(!isPerDiemLocationOpen);
          getPerDiemList();
          setEditIdLocPerDiem("");
        }
        setSubmitLoadingPerDiemLoc(false);
      } catch (error) {
        setSubmitLoadingPerDiemLoc(false);
      }
    }
    
  };

  /* ----------------------------   Edit Section   -------------------------------- */
  const handleEditPerDiemRate = (obj: object, isDefault: any) => {
    setEditIdLocPerDiem(getValue(obj, `id`, ""));

    setPerDiemRateLocReq({
      ...perDiemRateLocReq,
      name: getValue(obj, `name`, ""),
      amount: parseInt(getValue(obj, "amount", ""), 10),
      currency: getValue(obj, `currency.id`, ""),
      is_default: isPerDiemLocationDefaultRate
        ? isPerDiemLocationDefaultRate
        : false,
      country: getValue(obj, `country.id`, ""),
      city: getValue(obj, `city.id`, ""),
      effective_date: new Date(getValue(obj, `effective_date`, "")),
    });
    setIsPerDiemLocationDefaultRate(isDefault);
    setPerDiemLocationOpen(!isPerDiemLocationOpen);
  };

  /* ----------------------------   Delete Section   -------------------------------- */
  const handleDeletePerDiemRate = async (id: string) => {
    try {
      let resp = await deletePerDiemRates(id);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getPerDiemList();
      }
    } catch (error) {}
  };
  /* ---------------------------------------------------------------------------- */
  /*                          Per Diem Location Section Ends                      */
  /* ---------------------------------------------------------------------------- */

  /* ---------------------------------------------------------------------------- */
  /*                          Formats                                             */
  /* ---------------------------------------------------------------------------- */
  //Format Hours for Table
  const formatToHHMM = (timeString: any) => {
    if (!timeString) return "";
    return timeString.slice(0, 5); // Extracts the first 5 characters ("HH:mm")
  };

  //Format Percentage
  const formatPercentage = (percentageString: any) => {
    if (!percentageString) return "";
    return `${parseInt(percentageString)}%`; // Convert to integer and append "%"
  };

  const parseTime = (timeString: any) => {
    if (!timeString) return null; // If the time string is empty, return null
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    return date;
  };

  return (
    <>
      <div className="per-diem-tabs-content-wrap">
        <div className="w-40 mileage-expense-label-wrap form-group mb-5">
          <SingleSelectSearchDropdown
            placeholder={`Choose`}
            onChange={(e: any) => {
              setPerDiemReq({
                ...perDiemReq,
                per_diem_expense_type: getValue(e, `value`, ""),
              });
              {getValue(perDiemInfo, `id`, "")  && 
              handleUpdatePerDiem({
                policy: parseInt(getValue(mainParams, `id`, "")),
                per_diem_expense_type: getValue(e, `value`, ""),
                is_duration_based_rates_configured: getValue(
                  perDiemReq,
                  `is_duration_based_rates_configured`,
                  false
                ),
              });}
            }}
            label={`label`}
            width={"100%"}
            data={getValue(props, `expenseListPerDiem`, [])}
            value={getValue(perDiemReq, `per_diem_expense_type`, "")}
            selectKey={"id"}
            name={"Expense Type"}
            showLabel
          />
        </div>
          
          <div className="create-per-diem-travel-hours mb-3">
            <div className="d-flex">
              <div className="choicelist-wrapper custom-switch-wrapper">
                <label className="switch mt-0">
                  <input
                    id=""
                    type="checkbox"
                    checked={perDiemReq.is_duration_based_rates_configured}
                    onChange={() => {
                      const updatedConfig =
                        !perDiemReq.is_duration_based_rates_configured;
                      setPerDiemReq({
                        ...perDiemReq,
                        is_duration_based_rates_configured: updatedConfig,
                      });

                      {getValue(perDiemInfo, `id`, "")  && 
                      handleUpdatePerDiem({
                        policy: parseInt(getValue(mainParams, `id`, "")),
                        per_diem_expense_type: getValue(
                          perDiemReq,
                          `per_diem_expense_type`,
                          null
                        ),
                        is_duration_based_rates_configured: updatedConfig,
                      });}
                    }}
                  />

                  <span className="slider round"></span>
                </label>
              </div>

              <div className="title-wrap ms-3">
                <b>Create per diem based on travel hours</b> <br />
                <small className="text-muted">
                  Choose travel duration in hours and corresponding percentage
                  of per diem to be given for the same.
                </small>
              </div>
            </div>
          </div>
        
        {getValue(perDiemInfo, `id`, "") ? (
          <>
            <div className="create-per-diem-travel-hours mb-3">
              
              {getValue(perDiemDurationList, `length`, 0) > 0 ? (
                <>
                  <table className="datatable_table">
                    <thead className="datatable_table_thead">
                      <th className="datatable_table_th">
                        <h6>Duration</h6>
                        <p>From Time</p>
                      </th>
                      <th className="datatable_table_th">
                        <h6>Duration</h6>
                        <p>To Time</p>
                      </th>
                      <th className="datatable_table_th">
                        <h6>% for Per Diem Rate</h6>
                      </th>
                      <th className="datatable_table_th">
                        <h6>Action</h6>
                      </th>
                    </thead>
                    <tbody className="datatable_table_tbody">
                      {perDiemDurationList.map(
                        (item: object, index: number) => {
                          return (
                            <tr key={index} className="datatable_table_tr">
                              <td className="datatable_table_td">
                                <h6>
                                  {formatToHHMM(
                                    getValue(item, `start_time`, "")
                                  )}
                                </h6>
                              </td>
                              <td className="datatable_table_td">
                                <h6>
                                  {formatToHHMM(getValue(item, `end_time`, ""))}
                                </h6>
                              </td>

                              <td className="datatable_table_td">
                                {formatPercentage(
                                  getValue(item, `percentage`, "")
                                )}
                              </td>

                              <td className="datatable_table_td action-icon-td">
                                {/* {getValue(props, `permissions`, []).includes(
                                "update"
                              ) && ( */}
                                <UncontrolledDropdown
                                  className="table-action-icon-wrap"
                                  direction="start"
                                  container={"body"}
                                >
                                  <DropdownToggle>
                                    <div className="switch_header__dropdown m-0 align-items-center">
                                      <div className="close-image_container action-icon-wrap">
                                        <img
                                          src={"/images/more.svg"}
                                          className=" close_image"
                                        />
                                      </div>
                                    </div>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem>
                                      <div
                                        className={`more-dropdown-main-item `}
                                        onClick={() =>
                                          handleEditPerDiemDuration(item)
                                        }
                                      >
                                        <p>Edit</p>
                                      </div>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <div
                                        className={`more-dropdown-main-item `}
                                        onClick={() =>
                                          handleDeletePerDiemDuration(
                                            getValue(item, `id`, "")
                                          )
                                        }
                                      >
                                        <p>Delete</p>
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                {/* )} */}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  <div className="travel-duration-wrap mt-3">
                    <div
                      className="d-flex align-items-center justify-content-center rate_add_container cursor-pointer mt-3 "
                      onClick={handlePerDiemDurationModal}
                      style={{ width: "200px" }}
                    >
                      <AddSvgComponent color={"#fff"} />
                      <h6 className="header_text__14 ms-2 text-white">
                        Add Duration
                      </h6>
                    </div>
                  </div>
                </>
              ) : (
                <div className="travel-duration-wrap mt-3">
                    <h6 className="header_text__16 text-center">
                      Configure per diem based on travel hours
                    </h6>
                    <p className="small_text__14 text-center mt-2">
                      Choose travel duration in hours and corresponding percentage of per diem to be given for the same.
                    </p>
                    <div className="d-flex align-items-center justify-content-center">
                  <div
                    className="d-flex align-items-center justify-content-center rate_add_container cursor-pointer mt-3 "
                    onClick={handlePerDiemDurationModal}
                    style={{ width: "200px" }}
                  >
                    <AddSvgComponent color={"#fff"} />
                    <h6 className="header_text__14 ms-2 text-white">
                      Add Duration
                    </h6>
                  </div>
                  </div>
                </div>
              )}
            </div>

            <div className="create-per-diem-rates mb-4 mt-4">
              <div className="d-flex">
                <div className="title-wrap me-4">
                  <b>Per Diem Rates</b> <br />
                  <small className="text-muted">
                    Per diem is the daily allowance given to traveling
                    employees. Per diem is calculated based on rates added for a
                    particular travel destination or based on a default rate.
                  </small>
                </div>

                <UncontrolledDropdown className="rate-for-location-dropdown">
                  <DropdownToggle
                    color="primary"
                    caret
                    className="d-flex align-items-center justify-content-center add-rate-location-button"
                  >
                    <AddSvgComponent color={"#fff"} />
                    Add Rate for Location
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => handlePerDiemLocationModal(false)}
                    >
                      Add Rate for Location
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => handlePerDiemLocationModal(true)}
                    >
                      Add Default Rate
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              {getValue(perDiemRateList, `length`, 0) > 0 ? (
                <>
                  <div className="card mt-3">
                    <table className="datatable_table per-diem-rate-list">
                      <thead className="datatable_table_thead">
                        <th
                          className="datatable_table_th"
                          style={{ textAlign: "left" }}
                        >
                          <h6>Name</h6>
                        </th>
                        <th className="datatable_table_th">
                          <h6>Country</h6>
                          <p>City</p>
                        </th>
                        <th className="datatable_table_th">
                          <h6>Amount</h6>
                          <p>Currency</p>
                        </th>
                        <th className="datatable_table_th">
                          <h6>Effective Date</h6>
                        </th>
                        <th className="datatable_table_th">
                          <h6>Action</h6>
                        </th>
                      </thead>
                      <tbody className="datatable_table_tbody">
                        {perDiemRateList.map((item: object, index: number) => {
                          return (
                            <tr key={index} className="datatable_table_tr">
                              <td className="datatable_table_td">
                                <h6>{getValue(item, `name`, "")}</h6>
                              </td>
                              <td className="datatable_table_td">
                                <h6>{getValue(item, `country.name`, "")}</h6>
                                <p>{getValue(item, `city.name`, "")}</p>
                              </td>

                              <td className="datatable_table_td">
                                <h6>{getValue(item, `amount`, "")}</h6>
                                <p>
                                  {getValue(item, `currency.currency_code`, "")}
                                </p>
                              </td>

                              <td className="datatable_table_td">
                                <h6>{convertCurrentDate(getValue(item, `effective_date`, ""))}</h6>
                              </td>

                              <td className="datatable_table_td action-icon-td">
                                {/* {getValue(props, `permissions`, []).includes(
                                "update"
                              ) && ( */}
                                <UncontrolledDropdown
                                  className="table-action-icon-wrap"
                                  direction="start"
                                  container={"body"}
                                >
                                  <DropdownToggle>
                                    <div className="switch_header__dropdown m-0 align-items-center">
                                      <div className="close-image_container action-icon-wrap">
                                        <img
                                          src={"/images/more.svg"}
                                          className=" close_image"
                                        />
                                      </div>
                                    </div>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem>
                                      <div
                                        className={`more-dropdown-main-item `}
                                        onClick={() =>
                                          handleEditPerDiemRate(
                                            item,
                                            getValue(item, `is_default`, false)
                                          )
                                        }
                                      >
                                        <p>Edit</p>
                                      </div>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <div
                                        className={`more-dropdown-main-item `}
                                        onClick={() =>
                                          handleDeletePerDiemRate(
                                            getValue(item, `id`, "")
                                          )
                                        }
                                      >
                                        <p>Delete</p>
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                {/* )} */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <>
            <div className="mt-4 d-flex">
              <NavLink to={`${`/admin/user-controls/policies`}`}>
                <button className="ascent-button ascent-button--header-buttons ascent-button--primary">
                  Cancel
                </button>
              </NavLink>

              <button
                className={`ascent-button ascent-button--header-buttons ascent-button--secondary`}
                onClick={handleSubmitPerDiem}
                disabled={submitLoading1}
              >
                {submitLoading1 ? "Please wait..." : "Save"}
              </button>
            </div>
          </>
        )}
      </div>

      <PerDiemRateTravelHoursPopup
        perDiemDurationReq={perDiemDurationReq}
        setPerDiemDurationReq={setPerDiemDurationReq}
        handleModal={handlePerDiemDurationModal}
        isOpen={isPerDiemDurationOpen}
        request={perDiemDurationReq}
        setRequest={setPerDiemDurationReq}
        editId={editIdDurationPerDiem}
        setEditId={setEditIdDurationPerDiem}
        handleSubmit={handleSubmitPerDiemDuration}
        submitLoading={submitLoadingPerDiemDuration}
      />

      <PerDiemRateLocationPopup
        perDiemRateLocReq={perDiemRateLocReq}
        setPerDiemRateLocReq={setPerDiemRateLocReq}
        handleModal={handlePerDiemLocationModal}
        isOpen={isPerDiemLocationOpen}
        isPerDiemLocationDefaultRate={isPerDiemLocationDefaultRate}
        request={perDiemRateLocReq}
        setRequest={setPerDiemRateLocReq}
        editId={editIdLocPerDiem}
        setEditId={setEditIdLocPerDiem}
        handleSubmit={handleSubmitPerDiemLoc}
        submitLoading={submitLoadingPerDiemLoc}
      />
    </>
  );
}

export default PerDiem;
