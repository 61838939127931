import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";

export default function RolesTabNavigateion(props: any) {
  return (
    <div className="common-sticky-header role_details_tabHeader_wrap" style={{zIndex:'6'}}>
      <section className="inner-page-navigation-with-back-cta-buttons position-relative mt-4">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <ul className="inner-page-navigation-with-back-cta-buttons__navigation_left_temp d-inline-flex position-absolute cursor-pointer">
          <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  props.activeTab == 1 ? "active" : ""
                }`}
                onClick={() => props.handleActiveTab(1)}
              >
                Client
              </li>
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  props.activeTab == 2 ? "active" : ""
                }`}
                onClick={() => props.handleActiveTab(2)}
              >
                Admin
              </li>
              <li></li>
          </ul>
          {/* <div>
            <ul className="inner-page-navigation-with-back-cta-buttons__navigation_left d-inline-flex position-absolute cursor-pointer">
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${"active"}`}
              >
                Expenses
              </li>
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  props.activeTab == 2 ? "active" : ""
                }`}
              >
                Advances & Refunds
              </li>
              <li
                className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
                  props.activeTab == 3 ? "active" : ""
                }`}
              >
                History
              </li>
            </ul>
          </div> */}
          <button
            className={`ascent-button ascent-button--header-buttons ascent-button-secondary`}
          >
            
          </button>
        </div>
      </section>
    </div>
    // <div className="common-sticky-header mt-3 detail_border">
    //   <section className="inner-page-navigation-with-back-cta-buttons position-relative">
    //     <div className="container-fluid  d-flex justify-content-between align-items-center">
    //       <div>
    //         <ul className="inner-page-navigation-with-back-cta-buttons__navigation_left d-inline-flex position-absolute cursor-pointer">
    //           <li
    //             className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
    //               props.activeTab == 1 ? "active" : ""
    //             }`}
    //             onClick={() => props.handleActiveTab(1)}
    //           >
    //             Details
    //           </li>
    //           <li
    //             className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
    //               props.activeTab == 2 ? "active" : ""
    //             }`}
    //             onClick={() => props.handleActiveTab(2)}
    //           >
    //             Comments
    //           </li>
    //           <li></li>
    //           {/* <li
    //             className={`inner-page-navigation-with-back-cta-buttons__nav-list-item ${
    //               props.activeTab == 3 ? "active" : ""
    //             }`}
    //             onClick={() => props.handleActiveTab(3)}
    //           >
    //             History
    //           </li> */}
    //         </ul>
    //       </div>
    //       <button
    //         className={`ascent-button ascent-button--header-buttons ascent-button-secondary`}
    //       >
            
    //       </button>
    //     </div>
    //   </section>
    // </div>
  );
}


