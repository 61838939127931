import InputField from "@common/InputRuleFeild";
import { handleTextBoolean } from "@common/handleChange";
import Switch from "@components/list-management/switch";
import {
  createFlightPreference,
  createTripsLimit,
  editFlightPreference,
  editFlightPreferenceNew,
  getFlightsLimit,
  getTripsLimit,
  updateTripsLimit,
} from "@services/trips.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

function TripLimitPage(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const mainParams = useParams();

  /* -------------------------------------------------------------------------- */
  /*                             UseEffect section                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getTripsLimitList();
    getFlightLimitList();
    //Write getList
  }, []);

  
  /* -------------------------------------------------------------------------- */
  /*                               Mileage section                              */
  /* -------------------------------------------------------------------------- */
  const { tripRequest, setTripRequest } = props;
  
  const { internaltionFlightRequest, setInernationalFlightRequest } = props;
  const { domesticFlightRequest, setDomesticFlightRequest } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [editId, setEditId] = useState<any>();
  const [travelTypeId, setTravelTypeId] = useState<any>();

  const [state1, setState1] = useState<any>(false);
  const [state2, setState2] = useState<any>(false);

  const [isTripLimitEnabled, setIsTripLimitEnabled] = useState(false);

  
  const getTripsLimitList = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      setIsLoading(true);
      let resp = await getTripsLimit(
        getValue(urlParams, `policy_id`, "")
          ? parseInt(getValue(urlParams, `policy_id`, ""))
          : parseInt(getValue(mainParams, `id`, ""))
      );
      if (resp) {
        if (getValue(resp, `data`, "")) {
          setTripRequest({
            ...tripRequest,
            is_international: getValue(resp, `data.is_international`, false),
            is_domestic: getValue(resp, `data.is_domestic`, false),
            is_budget_limit: getValue(resp, `data.is_budget_limit`, false),
            budget_limit: getValue(resp, `data.budget_limit`, ""),
            is_hard_stop: getValue(resp, `data.is_hard_stop`, true),
            is_active: getValue(resp, `data.is_active`, false)
          });

          setEditId(getValue(resp, `data.id`, ""));
          setTravelTypeId(getValue(resp, `data.id`, ""));
          setIsLoading(false);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getFlightLimitList = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      let resp = await getFlightsLimit(
        getValue(urlParams, `policy_id`, "")
          ? parseInt(getValue(urlParams, `policy_id`, ""))
          : parseInt(getValue(mainParams, `id`, ""))
      );
      if (resp) {
        // if (getValue(resp, `data`, "")) {
          const internationalFlightData = getValue(
            resp,
            `data`,
            ""
          ).find((item: any) => item.travel_type === "international");

          if (internationalFlightData) {
            setInernationalFlightRequest({
              ...internaltionFlightRequest,
              policy_trip_limit: internationalFlightData.id,
              economy: internationalFlightData.economy,
              premium_economy: internationalFlightData.premium_economy,
              business: internationalFlightData.business,
              first: internationalFlightData.first,
            });
            // setState1(true);
          }

          const domesticFlightData = getValue(
            resp,
            `data`,
            ""
          ).find((item: any) => item.travel_type === "domestic");

          if (domesticFlightData) {
            setDomesticFlightRequest({
              ...domesticFlightRequest,
              policy_trip_limit: domesticFlightData.id,
              economy: domesticFlightData.economy,
              premium_economy: domesticFlightData.premium_economy,
              business: domesticFlightData.business,
              first: domesticFlightData.first,
            });
            // setState2(true);
          }

          if(internationalFlightData || domesticFlightData) {
            setState1(true);
          }
        // }
        // setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleChangeAmount = (e: any) => {
    const userInput = e.target.value;
    const regex = /^[0-9.]*$/; // Regular expression to allow only digits and a single decimal point
    let input = userInput.slice(0, 6);

    if (regex.test(input)) {
      return input ? parseFloat(input) : 0; // Convert to a number if valid input exists
    } else {
      return input ? parseFloat(input.replace(/[^0-9.]/g, "")) : 0;
    }
  };

  const handleCheckboxChange = (field: string) => {
    setTripRequest({
      ...tripRequest,
      [field]: tripRequest[field],
    });
  };

  const handleBudgetLimitToggle = (e: any) => {
    const isChecked = e.target.checked;
    setTripRequest({
      ...tripRequest,
      is_budget_limit: isChecked,
      budget_limit: isChecked ? tripRequest.budget_limit : "", // Clear budget limit if toggled off
    });
  };

  const handleHardStopChange = (isHardStop: boolean) => {
    setTripRequest({
      ...tripRequest,
      is_hard_stop: isHardStop,
    });
  };

  const [submitLoading1, setSubmitLoading1] = useState(false);
  const handleSubmitTrips = async () => {
    setSubmitLoading1(true);
    let tripLimitPayload = {
      //   policy: editId ? parseInt(getValue(mainParams, `id`, "")) : parseInt(getValue(urlParams, `policy_id`, "")),
      policy: getValue(urlParams, `policy_id`, "")
        ? parseInt(getValue(urlParams, `policy_id`, ""))
        : parseInt(getValue(mainParams, `id`, "")),
      is_international: getValue(tripRequest, `is_international`, false),
      is_domestic: getValue(tripRequest, `is_domestic`, false),
      is_budget_limit: getValue(tripRequest, `is_budget_limit`, false),
      // budget_limit: getValue(tripRequest, `budget_limit`, ""),
      is_hard_stop: getValue(tripRequest, `is_hard_stop`, true),
      ...(getValue(tripRequest, `budget_limit`, 0) !== "" && {
        budget_limit: parseFloat(getValue(tripRequest, `budget_limit`, 0)),
      }),
    };
    try {
      let resp;
      if (editId) {
        resp = await updateTripsLimit(editId, tripLimitPayload);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          getTripsLimitList();
        } else {
          setSubmitLoading1(false);
          getTripsLimitList();
        }
      } else {
        resp = await createTripsLimit(tripLimitPayload);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoading1(false);
          getTripsLimitList();
        } else {
          setSubmitLoading1(false);
        }
      }
    } catch (error) {}
  };

  const handleSubmitTripsCheckbox = async (field:any) => {
    setSubmitLoading1(true);
    let tripLimitPayload = {
      is_active: field === "is_active" ? true : false,
    };
    try {
      let resp;
      if (editId) {
        resp = await updateTripsLimit(editId, tripLimitPayload);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          getTripsLimitList();
        } else {
          setSubmitLoading1(false);
          getTripsLimitList();
        }
      }
    } catch (error) {}
  };

  //   International Flight Preference Checkbox
  //   const handleInternationalFlightChange = (field: string) => {
  //     setInernationalFlightRequest({
  //       ...internaltionFlightRequest,
  //       [field]: !internaltionFlightRequest[field],
  //     });
  //   };

  const handleFlightPreferenceChange = (requestType: string, field: string) => {
    if (requestType === "international") {
      setInernationalFlightRequest({
        ...internaltionFlightRequest,
        [field]: !internaltionFlightRequest[field],
      });
    } else if (requestType === "domestic") {
      setDomesticFlightRequest({
        ...domesticFlightRequest,
        [field]: !domesticFlightRequest[field],
      });
    }
  };

  const handleSubmitInternationTripPreference = async () => {
    let internationTripPreferncePaylaod = {
      // policy_trip_limit : editId ? parseInt(getValue(mainParams, `id`, "")) : parseInt(getValue(urlParams, `policy_id`, "")),
      // policy_trip_limit: getValue(urlParams, `policy_id`, "") ? parseInt(getValue(urlParams, `policy_id`, "")) : parseInt(getValue(mainParams, `id`, "")),
      // policy_trip_limit: internaltionFlightRequest.id,
      policy_trip_limit: editId,
      travel_type: "international",
      economy: getValue(internaltionFlightRequest, `economy`, false),
      premium_economy: getValue(
        internaltionFlightRequest,
        `premium_economy`,
        false
      ),
      business: getValue(internaltionFlightRequest, `business`, false),
      first: getValue(internaltionFlightRequest, `first`, false),
    };

    try {
      let resp;
      if (state1) {
        resp = await editFlightPreference(
          internaltionFlightRequest.policy_trip_limit,
          internationTripPreferncePaylaod
        );
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          getTripsLimitList();
        }
      } else {
        resp = await createFlightPreference(internationTripPreferncePaylaod);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          getTripsLimitList();
        }
      }
    } catch (error) {}
  };

  const handleSubmitDomesticTripPreference = async () => {
    let domesticTripPreferncePayload = {
      policy_trip_limit: editId,
      travel_type: "domestic",
      economy: getValue(domesticFlightRequest, `economy`, false),
      premium_economy: getValue(
        domesticFlightRequest,
        `premium_economy`,
        false
      ),
      business: getValue(domesticFlightRequest, `business`, false),
      first: getValue(domesticFlightRequest, `first`, false),
    };
    try {
      let resp;
      if (state2) {
        resp = await editFlightPreference(
          domesticFlightRequest.policy_trip_limit,
          domesticTripPreferncePayload
        );
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          getTripsLimitList();
        }
      } else {
        resp = await createFlightPreference(domesticTripPreferncePayload);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          getTripsLimitList();
        }
      }
    } catch (error) {}
  };

  // const  handleSubmitFlightPreference =async () => {
  //   try {

  //     if(tripRequest.is_international) {
  //       let internationTripPreferncePaylaod = {
  //         policy_trip_limit: editId,
  //         travel_type: "international",
  //         economy: getValue(internaltionFlightRequest, `economy`, false),
  //         premium_economy: getValue(
  //           internaltionFlightRequest,
  //           `premium_economy`,
  //           false
  //         ),
  //         business: getValue(internaltionFlightRequest, `business`, false),
  //         first: getValue(internaltionFlightRequest, `first`, false),
  //       };
  //       let resp = await createFlightPreference(internationTripPreferncePaylaod)
  //       if (resp) {
  //         toast.success(getValue(resp, `message`, ""));
  //         getTripsLimitList();
  //       }
  //     }

  //     if(tripRequest.domestic){
  //       let domesticTripPreferncePayload = {
  //         policy_trip_limit: editId,
  //         travel_type: "domestic",
  //         economy: getValue(domesticFlightRequest, `economy`, false),
  //         premium_economy: getValue(
  //           domesticFlightRequest,
  //           `premium_economy`,
  //           false
  //         ),
  //         business: getValue(domesticFlightRequest, `business`, false),
  //         first: getValue(domesticFlightRequest, `first`, false),
  //       };

  //       let resp = await createFlightPreference(domesticTripPreferncePayload);
  //       if (resp) {
  //         toast.success(getValue(resp, `message`, ""));
  //         getTripsLimitList();
  //       }

  //     }
  //   } catch (error) {}
  // }

  // const handleSubmitFlightPreference = async () => {
  //   alert();
  //   try {
  //     let policyFlightLimit = [];

  //     if (tripRequest.is_international) {
  //       policyFlightLimit.push({
  //         id: getValue(internaltionFlightRequest, `policy_trip_limit`, ""),
  //         travel_type: "international",
  //         economy: getValue(internaltionFlightRequest, `economy`, false),
  //         premium_economy: getValue(
  //           internaltionFlightRequest,
  //           `premium_economy`,
  //           false
  //         ),
  //         business: getValue(internaltionFlightRequest, `business`, false),
  //         first: getValue(internaltionFlightRequest, `first`, false),
  //         policy: getValue(urlParams, `policy_id`, "")
  //       ? parseInt(getValue(urlParams, `policy_id`, ""))
  //       : parseInt(getValue(mainParams, `id`, "")),
  //       });
  //     }

  //     if (tripRequest.is_domestic) {
  //       policyFlightLimit.push({
  //         id: getValue(domesticFlightRequest, `policy_trip_limit`, ""),
  //         travel_type: "domestic",
  //         economy: getValue(domesticFlightRequest, `economy`, false),
  //         premium_economy: getValue(
  //           domesticFlightRequest,
  //           `premium_economy`,
  //           false
  //         ),
  //         business: getValue(domesticFlightRequest, `business`, false),
  //         first: getValue(domesticFlightRequest, `first`, false),
  //         policy: getValue(urlParams, `policy_id`, "")
  //       ? parseInt(getValue(urlParams, `policy_id`, ""))
  //       : parseInt(getValue(mainParams, `id`, "")),
  //       });
  //     }

  //     if (policyFlightLimit.length > 0) {
  //       let payload = {
  //         policy_flight_limit: policyFlightLimit,
  //       };
        
  //       if(state1) {
  //         alert("Edit");
  //         let resp = await editFlightPreferenceNew(payload);
  //         if (resp) {
  //           toast.success(getValue(resp, `message`, ""));
  //           getFlightLimitList();
  //         }

  //       } else {
  //         alert("Create");
  //         let resp = await createFlightPreference(payload);
  //         if (resp) {
  //           toast.success(getValue(resp, `message`, ""));
  //           getFlightLimitList();
  //         }
  //       }
        
  //     }
  //   } catch (error) {
  //     console.error("Error submitting flight preference:", error);
  //   }
  // };

  const handleSubmitFlightPreference = async () => {
    try {
      let policyFlightLimit = [];
  
      if (tripRequest.is_international) {
        let internationalPayload: {
          id?: string;
          travel_type: string;
          economy: any;
          premium_economy: any;
          business: any;
          first: any;
          policy: number;
        } = {
          travel_type: "international",
          economy: getValue(internaltionFlightRequest, `economy`, false),
          premium_economy: getValue(
            internaltionFlightRequest,
            `premium_economy`,
            false
          ),
          business: getValue(internaltionFlightRequest, `business`, false),
          first: getValue(internaltionFlightRequest, `first`, false),
          policy: getValue(urlParams, `policy_id`, "")
            ? parseInt(getValue(urlParams, `policy_id`, ""))
            : parseInt(getValue(mainParams, `id`, "")),
        };
  
        const id = getValue(internaltionFlightRequest, `policy_trip_limit`, "");
        if (id) {
          internationalPayload.id = id;
        }
  
        policyFlightLimit.push(internationalPayload);
      }
  
      if (tripRequest.is_domestic) {
        let domesticPayload: {
          id?: string;
          travel_type: string;
          economy: any;
          premium_economy: any;
          business: any;
          first: any;
          policy: number;
        } = {
          travel_type: "domestic",
          economy: getValue(domesticFlightRequest, `economy`, false),
          premium_economy: getValue(
            domesticFlightRequest,
            `premium_economy`,
            false
          ),
          business: getValue(domesticFlightRequest, `business`, false),
          first: getValue(domesticFlightRequest, `first`, false),
          policy: getValue(urlParams, `policy_id`, "")
            ? parseInt(getValue(urlParams, `policy_id`, ""))
            : parseInt(getValue(mainParams, `id`, "")),
        };
  
        const id = getValue(domesticFlightRequest, `policy_trip_limit`, "");
        if (id) {
          domesticPayload.id = id;
        }
  
        policyFlightLimit.push(domesticPayload);
      }
  
      if (policyFlightLimit.length > 0) {
        let payload = {
          policy_flight_limit: policyFlightLimit,
        };
  
        if (state1) {
          let resp = await editFlightPreferenceNew(payload);
          if (resp) {
            toast.success(getValue(resp, `message`, ""));
            getFlightLimitList();
          }
        } else {
          // alert("Create");
          let resp = await createFlightPreference(payload);
          if (resp) {
            toast.success(getValue(resp, `message`, ""));
            getFlightLimitList();
          }
        }
      }
    } catch (error) {
      console.error("Error submitting flight preference:", error);
    }
  };

  const handleCheckboxChangeMain = async (field: boolean) => {
    // If 'is_active' is being toggled and editId is present, submit the trip
    // if (field === "is_active" && editId) {
      setSubmitLoading1(true);
      let tripLimitPayload = {
        is_active: !field ,
        policy: getValue(urlParams, `policy_id`, "")
        ? parseInt(getValue(urlParams, `policy_id`, ""))
        : parseInt(getValue(mainParams, `id`, "")),
      };
      try {
        let resp;
        // if (editId) {
          if (editId) {
            try {
              const resp = await updateTripsLimit(editId, tripLimitPayload);
              if (resp) {
                toast.success(getValue(resp, `message`, ""));
              } 
            } catch (error) {
              console.error("Error updating trip limit:", error);
            } finally {
              setSubmitLoading1(false);
              getTripsLimitList();
            }
          }  else {
            resp = await createTripsLimit(tripLimitPayload);
            if (resp) {
              toast.success(getValue(resp, `message`, ""));
              setSubmitLoading1(false);
              getTripsLimitList();
            } else {
              setSubmitLoading1(false);
            }
          }
          
        // }
        
        // else {
        //   setTripRequest ({
        //     ...tripRequest,
        //     is_active: !tripRequest.is_active
        //   })
        // }
        
      } catch (error) {}
  
    // }
    
  };

  return (
    <>
      <div className="expense-limits-tabs-content-wrap ">
        <div className="d-flex align-items-center mb-3">
          <label className="checkpoint-active-details__label me-3">
            Do you want to Enable Trip Limit for this Policy <span>*</span>
          </label>
          <div className="choicelist-wrapper custom-switch-wrapper">
            <label className="switch mt-0">
              <input
                id="123"
                type="checkbox"
                checked={tripRequest.is_active}
                // onChange={() =>
                //   setTripRequest({
                //     ...tripRequest,
                //     is_active: !tripRequest.is_active,
                //   })
                // }
                onChange={() => handleCheckboxChangeMain(tripRequest.is_active)}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>

        <div
          className={`enable-trip-limit-wrap 
          ${
            !tripRequest.is_active ? "disabled" : ""
          }
          `}
        >
          <div className="expense-limits-header-text">
            <h6 className="header_text">Trips Limits</h6>
            <p className="sub-text">
              Trips limits will be applicable for all the categories that are
              enabled.
            </p>
          </div>

          <div className="user-details-wrapper__form user-details-wrapper__form--org-profile three-grid">
            <div className="user-details-wrapper__form-group">
              <label className="checkpoint-active-details__label form-label-error">
                Travel Type <span>*</span>
              </label>
              <div className="d-flex">
                <div className="d-flex">
                  <input
                    type="checkbox"
                    className="common-checkbox"
                    id={`international`}
                    checked={tripRequest.is_international}
                    onChange={() =>     setTripRequest({
                      ...tripRequest,
                      is_international: !tripRequest.is_international,
                    })}
                  />
                  <p className="small_text__14 mb-2 ms-2">International</p>
                </div>

                <div className="ms-2 d-flex">
                  <input
                    type="checkbox"
                    className="common-checkbox"
                    id={`domestic`}
                    checked={tripRequest.is_domestic}
                    onChange={() =>
                    setTripRequest({
                      ...tripRequest,
                      is_domestic: !tripRequest.is_domestic,
                    })
                    }
                  />
                  <p className="small_text__14 mb-2 ms-2">Domestic</p>
                </div>
              </div>
            </div>

            {/* <div className="user-details-wrapper__form-group">
            <div className="d-flex align-items-center">
                <label className="checkpoint-active-details__label form-label-error me-3">
                Budget Limit <span>*</span>
                </label>
                <Switch
                is_active={getValue(props, `is_active`, "")}
                onChange={props.handleChangeStatus}
                id={getValue(props, `id`, "")}
            />
            </div>

            <div>
                
            </div>
          </div> */}
            <div className="user-details-wrapper__form-group">
              <div className="">
                <div className="d-flex align-items-center">
                  <label className="checkpoint-active-details__label form-label-error me-3">
                    Budget Limit <span>*</span>
                  </label>
                  <div className="choicelist-wrapper custom-switch-wrapper">
                    <label className="switch mt-0">
                      <input
                        type="checkbox"
                        checked={tripRequest.is_budget_limit}
                        onChange={handleBudgetLimitToggle}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>

                {tripRequest.is_budget_limit && (
                  // <div className="user-details-wrapper__form-group">
                  //   <label className="checkpoint-active-details__label form-label-error me-3">
                  //     Enter Budget Amount<span>*</span>
                  //   </label>

                  <div
                    className="input-group edit-expense-amount-edit-group"
                    style={{ marginTop: "8px" }}
                  >
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter Budget Amount"
                      className="form-control w-100"
                      name="edit_amount"
                      id="edit_amount"
                      value={tripRequest.budget_limit}
                      onChange={(e: any) =>
                        setTripRequest({
                          ...tripRequest,
                          budget_limit: handleChangeAmount(e),
                        })
                      }
                    />
                  </div>
                  // </div>
                )}
              </div>
            </div>

            <div className="user-details-wrapper__form-group">
              <div className="user-details-wrapper__form-group mt-3">
                <label className="checkpoint-active-details__label form-label-error">
                  Exception Handling <span>*</span>
                </label>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center mt-1">
                    <input
                      type="radio"
                      className="form-radio"
                      checked={tripRequest.is_hard_stop}
                      onChange={() => handleHardStopChange(true)}
                    />
                    <label className="ms-2">Hard Stop</label>
                  </div>
                  <div className="d-flex align-items-center mt-1 ms-3">
                    <input
                      type="radio"
                      className="form-radio"
                      checked={!tripRequest.is_hard_stop}
                      onChange={() => handleHardStopChange(false)}
                    />
                    <label className="ms-2">Soft Stop</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="user-details-wrapper__cta-btns mb-3 text-right"
            style={{ marginTop: "15px" }}
          >
            <button
              className={`ascent-button ascent-button--header-buttons ascent-button--secondary`}
              onClick={handleSubmitTrips}
              //   disabled={isChanged}
            >
              {
                //   isLoading || !props.permissionAPITriggered
                //     ? "Please wait..."
                //     :
                editId ? "Update" : "Save"
              }
            </button>{" "}
            &nbsp;&nbsp;
            <NavLink to={`/admin/user-controls/policies`}>
              <button className="ascent-button ascent-button--header-buttons ascent-button--primary">
                Cancel
              </button>
            </NavLink>
          </div>

          <div className="border-bottom mb-3"></div>

          <div className="expense-limits-header-text">
            <h6 className="header_text">Flight Limits</h6>
            <p className="sub-text">
              Flight limits will be applicable for all the categories that are
              enabled.
            </p>
          </div>
          <div className="d-flex w-100 preferenc-card-wrap">
            {/* {tripRequest.is_international && ( */}
            <div className={`card p-2 mb-2 me-3 w-50           ${
            !tripRequest.is_international ? "disabled" : ""
          }`}>
              <div className="user-details-wrapper__form-group">
                <label className="checkpoint-active-details__label form-label-error">
                  Flight Preference for International Travel
                </label>
                <div className="d-flex">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      className="common-checkbox"
                      id={`economy`}
                      checked={internaltionFlightRequest.economy}
                      onChange={() =>
                        handleFlightPreferenceChange("international", "economy")
                      }
                    />
                    <p className="small_text__14 mb-2 ms-2">Economy</p>
                  </div>

                  <div className="ms-2 d-flex">
                    <input
                      type="checkbox"
                      className="common-checkbox"
                      id={`premium_economy`}
                      checked={internaltionFlightRequest.premium_economy}
                      onChange={() =>
                        handleFlightPreferenceChange(
                          "international",
                          "premium_economy"
                        )
                      }
                    />
                    <p className="small_text__14 mb-2 ms-2">Premium Economy</p>
                  </div>

                  <div className="ms-2 d-flex">
                    <input
                      type="checkbox"
                      className="common-checkbox"
                      id={`business`}
                      checked={internaltionFlightRequest.business}
                      onChange={() =>
                        handleFlightPreferenceChange(
                          "international",
                          "business"
                        )
                      }
                    />
                    <p className="small_text__14 mb-2 ms-2">Business Class</p>
                  </div>

                  <div className="ms-2 d-flex">
                    <input
                      type="checkbox"
                      className="common-checkbox"
                      id={`first`}
                      checked={internaltionFlightRequest.first}
                      onChange={() =>
                        handleFlightPreferenceChange("international", "first")
                      }
                    />
                    <p className="small_text__14 mb-2 ms-2">First Class</p>
                  </div>
                </div>
              </div>

              {/* <div className="button-wrap text-right">
                  <button
                    className={`btn btn-sm ascent-button--secondary btn-preference-submit`}
                    onClick={handleSubmitInternationTripPreference}
                    disabled={!isMainFormSubmitted} // Disable until the main form is submitted
                  >
                    {getValue(mainParams, `id`, "") ? "Update" : "Save"}
                  </button>{" "}
                </div> */}
            </div>
            {/* )}

            {tripRequest.is_domestic && ( */}
            <div className={`card p-2 mb-2 me-3 w-50 ${
            !tripRequest.is_domestic ? "disabled" : ""
          }`}>
              <div className="user-details-wrapper__form-group">
                <label className="checkpoint-active-details__label form-label-error">
                  Flight Preference for Domestic Travel
                </label>
                <div className="d-flex">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      className="common-checkbox"
                      id={`economy`}
                      checked={domesticFlightRequest.economy}
                      onChange={() =>
                        handleFlightPreferenceChange("domestic", "economy")
                      }
                    />
                    <p className="small_text__14 mb-2 ms-2">Economy</p>
                  </div>

                  <div className="ms-2 d-flex">
                    <input
                      type="checkbox"
                      className="common-checkbox"
                      id={`premium_economy`}
                      checked={domesticFlightRequest.premium_economy}
                      onChange={() =>
                        handleFlightPreferenceChange(
                          "domestic",
                          "premium_economy"
                        )
                      }
                    />
                    <p className="small_text__14 mb-2 ms-2">Premium Economy</p>
                  </div>

                  <div className="ms-2 d-flex">
                    <input
                      type="checkbox"
                      className="common-checkbox"
                      id={`business`}
                      checked={domesticFlightRequest.business}
                      onChange={() =>
                        handleFlightPreferenceChange("domestic", "business")
                      }
                    />
                    <p className="small_text__14 mb-2 ms-2">Business Class</p>
                  </div>

                  <div className="ms-2 d-flex">
                    <input
                      type="checkbox"
                      className="common-checkbox"
                      id={`first`}
                      checked={domesticFlightRequest.first}
                      onChange={() =>
                        handleFlightPreferenceChange("domestic", "first")
                      }
                    />
                    <p className="small_text__14 mb-2 ms-2">First Class</p>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
          </div>
          <div className="button-wrap text-right">
            <button
              className={`btn button-sm ascent-button--secondary btn-preference-submit`}
              onClick={handleSubmitFlightPreference}
              // disabled={!isMainFormSubmitted} // Disable until the main form is submitted
            >
              {editId ? "Update" : "Save"}
            </button>{" "}
            &nbsp;&nbsp;
            <NavLink to={`/admin/user-controls/policies`}>
              <button className="btn button-sm ascent-button--primary btn-preference-submit" style={{padding: "5px 10px"}}>
                Cancel
              </button>
            </NavLink>

          </div>
        </div>
      </div>
    </>
  );
}

export default TripLimitPage;
