import { getValue } from "@utils/lodash";
import React from "react";

function DashboardExpenseSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        // width="48"
        // height="48"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "48"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "48"
        }
        viewBox="0 0 48 48"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_17148"
              data-name="Rectangle 17148"
              width="48"
              height="48"
              transform="translate(-11943 -4994)"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g
          id="Group_10010"
          data-name="Group 10010"
          transform="translate(11943 4994)"
        >
          <g
            id="Mask_Group_116"
            data-name="Mask Group 116"
            clip-path="url(#clip-path)"
          >
            <g
              id="sticky_note_2_black_24dp"
              transform="translate(-11943 -4994)"
            >
              <rect
                id="Rectangle_17149"
                data-name="Rectangle 17149"
                width="48"
                height="48"
                fill="none"
              />
              <path
                id="Path_46261"
                data-name="Path 46261"
                d="M38,10V28H28V38H10V10H38m0-4H10a4.012,4.012,0,0,0-4,4V38a4.012,4.012,0,0,0,4,4H30L42,30V10A4.012,4.012,0,0,0,38,6Z"
                fill="#05a357"
                opacity="0.11"
              />
            </g>
          </g>
          <g
            id="Mask_Group_117"
            data-name="Mask Group 117"
            clip-path="url(#clip-path)"
          >
            <g
              id="sticky_note_2_black_24dp-2"
              data-name="sticky_note_2_black_24dp"
              transform="translate(-11943 -4994)"
            >
              <rect
                id="Rectangle_17150"
                data-name="Rectangle 17150"
                width="48"
                height="48"
                fill="none"
              />
              <path
                id="Path_46262"
                data-name="Path 46262"
                d="M24,28H14V24H24Zm10-8H14V16H34Z"
                fill="#05a357"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default DashboardExpenseSvgComponent;
