import { getValue } from "@utils/lodash";
import { NavLink } from "react-router-dom";

function StickyHeader(props: any) {
  const { isChanged, handleSubmit, submitLoading, id, route } = props;
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <p className="header_text ms-2"> {getValue(props, `title`, "")}</p>
          </div>
          <div className="d-flex align-items-center">
            {/* <button className=" ascent-button ascent-button--header-buttons ascent-button--primary mx-3">
              Cancel
            </button> */}
            {/* <button
              onClick={handleSubmit}
              disabled={isChanged || submitLoading}
              className="ms-1 ascent-button ascent-button--header-buttons ascent-button--secondary mx-3"
            >
              {submitLoading ? "Please wait..." : "Update"}
            </button> */}
            {getValue(props, `permissions`, []).includes("update") && (
              <button
                onClick={handleSubmit}
                disabled={isChanged || submitLoading}
                className="ascent-button ascent-button--header-buttons ascent-button--primary"
              >
                {submitLoading
                  ? "Please wait..."
                  : props.id
                  ? "Update"
                  : "Save"}
              </button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default StickyHeader;
