import InputField from "@common/InputRuleFeild";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import { concatString } from "@common/text-helpers";
import { getValue, setValue } from "@utils/lodash";
import React from "react";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";

function PolicyForm(props: any) {
  const {
    request,
    setRequest,
    simpleValidator,
    handleUploadDocuments,
    fileTypes,
  } = props;
  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  const handleRemoveUsers = (index: number, id: string) => {
    if (id) {
      let filtered = getValue(request, `policy_admins`, []).map(
        (item: object, i: number) => {
          if (i == index) {
            return {
              ...item,
              action: "DELETE",
            };
          } else {
            return {
              ...item,
            };
          }
        }
      );
      setValue(request, `policy_admins`, filtered);
      setRequest({ ...request });
    } else {
      let filtered = getValue(request, `policy_admins`, []).filter(
        (_item: object, i: number) => i !== index
      );
      setValue(request, `policy_admins`, filtered);
      setRequest({ ...request });
    }
  };
  const handleChange = (e: any, index: number, id: string) => {
    setValue(request, `policy_admins[${index}].user`, getValue(e, `id`, ""));
    setValue(request, `policy_admins[${index}].action`, !id ? "ADD" : "UPDATE");
    setRequest({ ...request });
  };
  const handleAddUser = () => {
    let obj = {
      user: "",
      action: "ADD",
    };
    getValue(request, `policy_admins`, []).push(obj);
    setRequest({ ...request });
  };

  return (
    <div className="w-50 policy-setting-tabs-content-wrap">
      {/* m-5 w-50 px-3  --- Old Code */}
      {/* w-50 policy-setting-tabs-content-wrap  --- New Code  */}
      <div className="policy-setting-header-text">
        <h6>Policy Settings</h6>
        <p className="sub-text">Provide basic policy details, upload travel policy, and configure other settings.</p>
      </div>
      <div className="user-details-wrapper__form-group form-group">
        <label className="checkpoint-active-details__label form-label-error">
          Policy Name <span>*</span>
        </label>
        <InputField
          inputType="TEXT"
          placeholder="Enter Policy Name"
          className="form-control w-100"
          name="policy_name"
          id="policy_name"
          value={getValue(request, `policy_name`, "")}
          onChange={(e: any) => handleChangeText(e)}
          label="Policy Name"
          validator={simpleValidator}
          
        />
      </div>
      <div className="user-details-wrapper__form-group form-group">
        <label className="checkpoint-active-details__label form-label-error">
          Policy Description <span>*</span> 
        </label>
        <InputField
          inputType="TEXTAREA"
          placeholder="Enter Policy Description"
          className="form-control w-100"
          name="description"
          id="description"
          value={getValue(request, `description`, "")}
          onChange={(e: any) => handleChangeText(e)}
          label="Policy Description"
          validator={simpleValidator}
        />
      </div>
      <div className="user-details-wrapper__form-group form-group mb-5">
        <label className="checkpoint-active-details__label">
          Policy Document <span></span>
        </label>
        <DragAndDropFileComponent
          uploadFile={handleUploadDocuments}
          name="file"
          types={fileTypes}
          multiple
          classes="bulk-upload_drag-n-drop-wrapper position-absolute"
          fileName={getValue(props, `importedFile.name`, "")}
          setImportTedFile={props.setImportTedFile}
        />
        <div className="mt-3">
          {getValue(props, `documents.length`, 0) > 0
            ? getValue(props, `documents`, []).map(
                (item: object, index: number) => {
                  return (
                    <div
                      className="d-flex justify-content-between align-items-center px-2 mt-2"
                      style={{
                        background: "rgb(247, 251, 255)",
                        padding: "5px",
                      }}
                    >
                      <p>{concatString(getValue(item, `name`, ""), 60)}</p>
                      <div onClick={() => props.handleRemoveDocuments(index)}>
                        <CloseSmallSvgComponent />
                      </div>
                    </div>
                  );
                }
              )
            : null}
        </div>
      </div>
      {/* <div className="w-100">
        <div className="user-details-wrapper__form-group form-group">
          <label className="checkpoint-active-details__label">
            Policy Admin <span></span>
          </label>
          {getValue(request, `policy_admins.length`, 0) > 0 &&
            getValue(request, `policy_admins`, []).map(
              (item: object, index: number) => {
                if (getValue(item, `action`, "") !== "DELETE")
                  return (
                    <div className="d-flex align-items-center mb-2">
                      <SingleSelectSearchDropdown
                        label={"label"}
                        name="Policy Admin"
                        value={getValue(item, `user`, "")}
                        onChange={(e: any) =>
                          handleChange(e, index, getValue(item, `id`, ""))
                        }
                        placeholder={"Choose Policy Admin"}
                        width={"100%"}
                        validator={simpleValidator}
                        lookup_api={"users"}
                      />
                      <div
                        className="close-image_container ms-3"
                        onClick={() =>
                          handleRemoveUsers(index, getValue(item, `id`, ""))
                        }
                      >
                        <img
                          className="close_image "
                          src={`/images/close-black.svg`}
                        />
                      </div>
                    </div>
                  );
              }
            )}
          <div
            className=" d-flex align-items-center mt-4"
            onClick={() => handleAddUser()}
          >
            <img
              className="custom_field_button_container_image"
              src={`/images/add_circle.svg`}
            />
            <h6 className="header_text_blue ms-2">Add New Policy Admin</h6>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default PolicyForm;
