import { getValue } from "@utils/lodash";
import React from "react";

function FlightTakeOffSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="flight_takeoff-24px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path id="Path_9" data-name="Path 9" d="M0,0H24V24H0Z" fill="none" />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M2.5,19h19v2H2.5ZM22.07,9.64a1.5,1.5,0,0,0-1.84-1.06L14.92,10,8.02,3.57l-1.93.51,4.14,7.17L5.26,12.58,3.29,11.04l-1.45.39,2.59,4.49L21,11.49a1.522,1.522,0,0,0,1.07-1.85Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#fd9933"
          }
        />
      </svg>
    </div>
  );
}

export default FlightTakeOffSvgComponent;
