import LinkSvgComponent from "@assets/svg-components/link";
import { getColorStyle } from "@common/color";
import { convertCurrentDate } from "@common/date-helpers";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

function ExpenseHeaderRightSection(props: any) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="expense-detail-wrapper_right_container_header_section align-items-center">
        <div className="expense-detail_headerleft_section">
          <h5 className="header_text__16 color_primary expense-detail_headertitle">
            {getValue(props, `formInfo.expense_type.expense_type_name`, "")}
          </h5>
          <p className="expense-detail_header_created">
            Created on :{" "}
            {convertCurrentDate(getValue(props, `formInfo.created_at`, ""))}
          </p>
        </div>
        <div className="expense-detail_headerright_section d-flex align-items-center">
          <div className="">
            {getValue(props, `formInfo.expense_report.report_name`, "") ? (
              <p className="small_text__12 d-flex align-items-center ">
                Report :{" "}
                <span
                  className="header_text__14 color_primary expense-detail_headertitle cursor-pointer "
                  onClick={() =>
                    navigate(
                      `/client/reports/detail/${getValue(
                        props,
                        `formInfo.expense_report.id`,
                        ""
                      )}`
                    )
                  }
                >
                  <LinkSvgComponent color={"#408dfb"} /> {""}
                  {getValue(props, `formInfo.expense_report.report_name`, "")}
                </span>
              </p>
            ) : null}
            <p
              className=" expense-detail-wrapper_status_text"
              style={getColorStyle(`${getValue(props, `formInfo.status`, "")}`)}
            >
              {formatText(getValue(props, `formInfo.status`, ""))}
            </p>
          </div>
          {/* <div className="switch_header__dropdown">
                <div
                  className="dropdown-content d-flex cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/client/expenses/edit/${getValue(params, `id`, "")}`
                    )
                  }
                >
                  <img
                    src="/images/edit-gray.svg"
                    width="24px"
                    height="24px"
                    className="img-fluid"
                  />
                
                </div>
                <p className="switch_header__dropdown_text">Edit</p>
              </div> */}

          {/* <div className="more-dropdown-main ms-2 align-items-center">
                <UncontrolledDropdown>
                  <DropdownToggle>
                    <div className="switch_header__dropdown align-items-center">
                      <div className="dropdown-content d-flex cursor-pointer">
                        <img
                          src="/images/more.svg"
                          width="24px"
                          height="24px"
                          className="img-fluid"
                        />
                      </div>
                      <p className="switch_header__dropdown_text">More</p>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <div className={`more-dropdown-main-item `}>
                        <p>Split</p>
                      </div>
                    </DropdownItem>
                    <DropdownItem>
                      <div className={`more-dropdown-main-item `}>
                        <p>Clone</p>
                      </div>
                    </DropdownItem>
                    <DropdownItem>
                      <div className={`more-dropdown-main-item `}>
                        <p>Delete</p>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div> */}
          {/* <div className="close-image_container">
                <img
                  src="/images/close-black.svg"
                  width="26px"
                  height="26px"
                  className="img-fluid cursor-pointer close_image"
                />
              </div> */}
        </div>
      </div>
    </div>
  );
}

export default ExpenseHeaderRightSection;
