import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import { formHandleTextArrayChangeByKey } from "@common/handleChange";
import { getValue } from "@utils/lodash";
import InputField from "@common/InputRuleFeild";
import TripCityComponent from "../common/trip_city";
import TripDateComponent from "../common/trip_date";
import AddSvgComponent from "@assets/svg-components/add";

function TripCreateTrainSection(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               Car CRUD Section                             */
  /* -------------------------------------------------------------------------- */
  const addSection = () => {
    let obj = {
      departure_date: "",
      departure_city: "",
      departure_city_name: "",
      arrival_city: "",
      arrival_city_name: "",
      // is_personal_travel: false,
    };
    props.itenaries.push(obj);
    props.setItenaries([...props.itenaries]);
  };
  const removeSection = (index: number) => {
    let filtered =
      getValue(props, `itenaries.length`, 0) > 0
        ? getValue(props, `itenaries`, []).filter(
            (_item: object, i: number) => index !== i
          )
        : [];
    props.setItenaries(filtered);
    props.hideValidation()
  };

  return (
    <div className="trips-train-tab-main-container"> {/*px-4 mt-4 w-100*/}
      
      <div className="mt-4">
        {getValue(props, `itenaries.length`, 0) > 0 ? (
          getValue(props, `itenaries`, []).map(
            (item: object, index: number) => {
              return (
                <div className="multiple-dyno-wrap">
                  <div className="d-flex justify-content-sm-between align-items-center w-100">
                    <div className="w-100">
                      <div className="d-flex flex-wrap_wrap">
                        <div className="">
                          <TripCityComponent
                            item={item}
                            index={index}
                            validator={props.validator}
                            setItenaries={props.setItenaries}
                            label={"Departure City"}
                            city_key={"departure_city"}
                            city_name_key={"departure_city_name"}
                            locationIcon
                          />
                        </div>
                        <div className="">
                          <TripCityComponent
                            item={item}
                            index={index}
                            validator={props.validator}
                            setItenaries={props.setItenaries}
                            label={"Arrival City"}
                            city_key={"arrival_city"}
                            city_name_key={"arrival_city_name"}
                            locationIcon
                          />
                        </div>
                        <div className="">
                          <TripDateComponent
                            validator={props.validator}
                            item={item}
                            index={index}
                            date_key={"departure_date"}
                            setItenaries={props.setItenaries}
                            label={"Departure Date"}
                          />
                        </div>
                      </div>

                      <div className="flight_description_wrap mb-16">
                        <InputField
                          inputType="TEXTAREA"
                          placeholder="Add Description (Max 200 Characters)"
                          rows={2}
                          name="description"
                          value={getValue(item, `description`, "")}
                          onChange={(e: any) =>
                            formHandleTextArrayChangeByKey(
                              index,
                              e,
                              props.setItenaries
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className=" mt-2" onClick={() => removeSection(index)}>
                      <CloseSmallSvgComponent />
                    </div>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div className="mt-5">
            <div className="trips_header_bg d-flex align-items-center p-2 px-4 mt-4">
              <h6 className="header_text__18 ">Train</h6>
            </div>
            <p className="text-center mt-3 small_text__14">
              No Trains have been included. Kindly select the option below to
              add them.
            </p>
            <div
              className="d-flex justify-content-center align-items-center cursor-pointer  mt-3"
              onClick={addSection}
            >
              <div className="border border-radius__5 d-flex justify-content-center p-2 px-3">
                <AddSvgComponent size={28} color={"#408dfb"} />
                <h6 className="header_text__18 ms-2 color_primary">
                  Add Train
                </h6>
              </div>
            </div>
          </div>
        )}
      </div>

      {getValue(props, `itenaries.length`, 0) > 0 && (
        <div
          className="custom_field_button_container mt-3"
          onClick={addSection}
        >
          <img
            className="custom_field_button_container_image"
            src={`/images/add_circle.svg`}
          />
          <h6 className="header_text__16 color_primary ms-2">
            {getValue(props, `itenaries.length`, 0) > 0 && "Add another train"}
          </h6>
        </div>
      )}
    </div>
  );
}

export default TripCreateTrainSection;
