import { getValue } from "@utils/lodash";
import React from "react";

function ForwardSvgComponent(props: any) {
  return (
    <>
      <div className="outer-border-rotated-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#3c4043"
          }
          width={
            getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
          }
          height={
            getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
          }
        >
          <path d="M200-160v-480h447L503-783l57-57 240 240-241 241-56-57 144-144H280v400h-80Z" />
        </svg>
      </div>
    </>
  );
}

export default ForwardSvgComponent;
