import { getValue } from "@utils/lodash";
import React from "react";

function ApprovalSvgComponent(props: any) {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="18"
        // height="18"
        viewBox="0 0 18 18"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <g id="verified_black_24dp" transform="translate(-0.025 -0.025)">
          <g id="Group_8688" data-name="Group 8688">
            <rect
              id="Rectangle_14563"
              data-name="Rectangle 14563"
              width="18"
              height="18"
              transform="translate(0.025 0.025)"
              fill="none"
            />
          </g>
          <g
            id="Group_8690"
            data-name="Group 8690"
            transform="translate(0.752 1.128)"
          >
            <g id="Group_8689" data-name="Group 8689">
              <path
                id="Path_40165"
                data-name="Path 40165"
                d="M17.547,9.39l-1.835-2.1.256-2.775L13.252,3.9,11.83,1.5,9.273,2.6,6.716,1.5,5.295,3.9l-2.715.609.256,2.783L1,9.39l1.835,2.1-.256,2.783,2.715.617,1.421,2.407,2.557-1.106,2.557,1.1,1.421-2.4,2.715-.617L15.711,11.5ZM14.576,10.5l-.421.489.06.639L14.35,13.1l-1.429.323-.632.143-.331.557-.745,1.264L9.875,14.8l-.6-.256-.594.256-1.339.579L6.6,14.128l-.331-.557-.632-.143L4.2,13.105l.135-1.474.06-.639L3.978,10.5,3.008,9.4l.97-1.113L4.4,7.8l-.068-.647L4.2,5.689l1.429-.323.632-.143.331-.557L7.333,3.4l1.339.579.6.256.594-.256L11.206,3.4l.745,1.264.331.557.632.143,1.429.323-.135,1.467-.06.639.421.489.97,1.106Z"
                transform="translate(-1 -1.5)"
                // fill="#3c4043"
                fill={
                  getValue(props, `color`, "")
                    ? getValue(props, `color`, "")
                    : "#3c4043"
                }
              />
              <path
                id="Path_40166"
                data-name="Path 40166"
                d="M9.148,12.292,7.4,10.54,6.29,11.661l2.858,2.866,5.521-5.536L13.555,7.87Z"
                transform="translate(-2.311 -3.079)"
                // fill="#3c4043"
                fill={
                  getValue(props, `color`, "")
                    ? getValue(props, `color`, "")
                    : "#3c4043"
                }
              />
            </g>
          </g>
        </g>
      </svg> */}

      <svg xmlns="http://www.w3.org/2000/svg" 
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        viewBox="0 0 18 18">
        <g id="verified_black_24dp" transform="translate(-0.025 -0.025)">
          <g id="Group_8688" data-name="Group 8688">
            <rect id="Rectangle_14563" data-name="Rectangle 14563" width="18" height="18" transform="translate(0.025 0.025)" fill="none"/>
          </g>
          <g id="Group_8690" data-name="Group 8690" transform="translate(0.752 1.128)">
            <g id="Group_8689" data-name="Group 8689">
              <path id="Path_40165" data-name="Path 40165" d="M17.547,9.39l-1.835-2.1.256-2.775L13.252,3.9,11.83,1.5,9.273,2.6,6.716,1.5,5.295,3.9l-2.715.609.256,2.783L1,9.39l1.835,2.1-.256,2.783,2.715.617,1.421,2.407,2.557-1.106,2.557,1.1,1.421-2.4,2.715-.617L15.711,11.5ZM14.576,10.5l-.421.489.06.639L14.35,13.1l-1.429.323-.632.143-.331.557-.745,1.264L9.875,14.8l-.6-.256-.594.256-1.339.579L6.6,14.128l-.331-.557-.632-.143L4.2,13.105l.135-1.474.06-.639L3.978,10.5,3.008,9.4l.97-1.113L4.4,7.8l-.068-.647L4.2,5.689l1.429-.323.632-.143.331-.557L7.333,3.4l1.339.579.6.256.594-.256L11.206,3.4l.745,1.264.331.557.632.143,1.429.323-.135,1.467-.06.639.421.489.97,1.106Z" transform="translate(-1 -1.5)" fill={
                  getValue(props, `color`, "")
                    ? getValue(props, `color`, "")
                    : "#3c4043"
                } />
              <path id="Path_40166" data-name="Path 40166" d="M9.148,12.292,7.4,10.54,6.29,11.661l2.858,2.866,5.521-5.536L13.555,7.87Z" transform="translate(-2.311 -3.079)" 
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#3c4043"
              }/>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}

export default ApprovalSvgComponent;
