import DeleteSvgComponent from "@assets/svg-components/delete";
import EditSvgComponent from "@assets/svg-components/edit";
import { getColorStyle } from "@common/color";
import { convertCurrentDate } from "@common/date-helpers";
import Switch from "@components/list-management/switch";
import { getValue } from "@utils/lodash";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

function CurrencyTable(props: any) {
  const { currencyData } = props;
  return (
    <table className="datatable_table">
      <thead className="datatable_table_thead">
        <th className="datatable_table_th"></th>
        <th className="datatable_table_th">
          <h6 className="">Name</h6>
          <p className="">Currency Type</p>
        </th>
        <th className="datatable_table_th">
          <h6 className="">Decimal Places</h6>
        </th>
        <th className="datatable_table_th">
          <h6 className="">Added On</h6>
        </th>
        <th className="datatable_table_th">
          <h6 className="">Action</h6>
        </th>
      </thead>
      <tbody className="datatable_table_tbody">
        {currencyData.map((item: object, index: number) => {
          return (
            <tr key={index} className="datatable_table_tr">
              <td className="datatable_table_td">
                <img
                  src="/images/payments.svg"
                  className="currency_tbody_td_img img-fluid"
                />
              </td>
              <td className="datatable_table_td">
                <div className="d-flex align-items-center">
                  <NavLink
                    to={`/admin/organisation/currency/edit/${getValue(
                      item,
                      `id`,
                      ""
                    )}`}
                  >
                    <h6 className="">{getValue(item, `currency_code`, "")}</h6>
                  </NavLink>
                  {getValue(item, `is_base_currency`, false) && (
                    <>
                      <span className="dot-holder-seperator"></span>
                      <div style={getColorStyle("completed")}>
                        Base Currency
                      </div>
                    </>
                  )}
                </div>
                <p className="">{getValue(item, `currency_symbol`, "")}</p>
              </td>
              <td className="datatable_table_td">
                <h6
                  className=""
                  // style={getColorStyle(getValue(item, `status`, ""))}
                >
                  {getValue(item, `decimal_places`, "")}
                </h6>
              </td>
              <td className="datatable_table_td">
                <h6 className="">
                  {convertCurrentDate(getValue(item, `created_at`, ""))}
                </h6>
              </td>
              <td className="datatable_table_td action-icon-td">
                <div className="d-flex align-items-center">
                  {/* <div className="d-flex align-items-center "> */}
                  {/* <NavLink
                      to={`/admin/organisation/currency/edit/${getValue(
                        item,
                        `id`,
                        ""
                      )}`}
                    >
                      <EditSvgComponent />
                    </NavLink> */}
                  <UncontrolledDropdown
                    className="table-action-icon-wrap me-4"
                    direction="start"
                    container={"body"}
                  >
                    <DropdownToggle>
                      <div className="switch_header__dropdown m-0 align-items-center">
                        <div className="close-image_container action-icon-wrap">
                          <img
                            src={"/images/more.svg"}
                            className=" close_image"
                          />
                        </div>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <div className={`more-dropdown-main-item `}>
                          <NavLink
                            to={`/admin/organisation/currency/edit/${getValue(
                              item,
                              `id`,
                              ""
                            )}`}
                            className="d-block"
                          >
                            Edit
                          </NavLink>
                        </div>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  {getValue(props, `permissions`, []).includes("update") && (
                    <div className="choicelist-wrapper custom-switch-wrapper">
                      {!getValue(item, `is_base_currency`, false) && (
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={getValue(item, `is_active`, false)}
                            onChange={() =>
                              props.handleChangeActiveStatus(
                                getValue(item, `id`, ""),
                                !getValue(item, `is_active`, "")
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      )}
                    </div>
                  )}
                  {/* <div
                        onClick={() =>
                          props.handleDeleteFun(
                            getValue(item, `id`, ""),
                            getValue(item, `currency_code`, "")
                          )
                        }
                      >
                        <DeleteSvgComponent />
                      </div> */}
                  {/* </div> */}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default CurrencyTable;
