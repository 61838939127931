import { getValue, setValue } from "@utils/lodash";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useEffect, useRef, useState } from "react";
import InputField from "@common/InputRuleFeild";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import {
  createAnalyticsReport,
  getShowAndHideColumnsList,
} from "@services/analytics.service";
import {
  convertElementaryArrayToJSONArray,
  formatText,
} from "@common/text-helpers";
import AddSvgComponent from "@assets/svg-components/add";
import RemoveCircleSvgComponent from "@assets/svg-components/remove-circle";
import { getReportsFilterkey } from "@services/analytics.service";
import { useParams } from "react-router-dom";
import { handleSelectArrayChangeStaticValue } from "@common/handleChange";
import Analyticsbuilder from "./analyticsbuilder";
import { convertCurrentDate } from "@common/date-helpers";
import { convertDate } from "@common/date-helpers";
import { toast } from "react-toastify";
import BackSvgComponent from "@assets/svg-components/back";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Loader from "@components/common/Loader";
import NoAccessPage from "@components/common/NoAccess";

function CreateAnalytics(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [request, setRequest] = useState({
    report_type: "",
    report_name: "",
    report_description: "",
    columns_shown: [],
    report_filter: [],
  });

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props.permissionAPITriggered) {
      getColumns();
      getFilterKeys();
    }
  }, [props.permissionAPITriggered]);

  /* ------------------------- Columns Section -------------------------------- */

  const [shownColumns, setShowColumns] = useState<any>([]);
  const [hiddenColumns, setHiddenColumns] = useState<any>([]);

  const getColumns = async () => {
    try {
      let resp = await getShowAndHideColumnsList("");
      if (resp) {
        const extracted = extractDefaultShownTrue(
          getValue(resp, `data[${params.name}]`, {})
        );
        setShowColumns(
          extracted.filter((item: object) =>
            getValue(item, `is_default_shown`, false)
          )
        );
        setHiddenColumns(
          extracted.filter(
            (item: object) => !getValue(item, `is_default_shown`, false)
          )
        );
      }
    } catch (error) {}
  };

  function extractDefaultShownTrue(jsonData: any) {
    const dataArray = [];
    for (const key in jsonData) {
      if (Object.prototype.hasOwnProperty.call(jsonData, key)) {
        dataArray.push(jsonData[key]);
      }
    }
    return dataArray;
  }

  /* ------------------------- Filter Key Section --------------------------- */
  const [filterKeys, setFilterKeys] = useState([]);
  const getFilterKeys = async () => {
    try {
      let resp = await getReportsFilterkey("");
      if (resp) {
        let allKeys = extractFilterKeys(
          getValue(resp, `data[${params.name}]`, [])
        );
        setFilterKeys(allKeys);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleAddFields = (obj: any) => {
    shownColumns.push(obj);
    let filtered = hiddenColumns.filter(
      (item: object) => getValue(item, `key`, "") !== getValue(obj, `key`, "")
    );
    setHiddenColumns(filtered);
    setShowColumns([...shownColumns]);
  };

  const handleRemoveColumns = (obj: any) => {
    hiddenColumns.push(obj);
    let filtered = shownColumns.filter(
      (item: object) => getValue(item, `key`, "") !== getValue(obj, `key`, "")
    );
    setShowColumns(filtered);
    setHiddenColumns([...hiddenColumns]);
  };
  /* ----------------------- Filter key Section Helpers ---------------------------- */

  function extractFilterKeys(jsonData: any) {
    let array = Object.keys(jsonData);
    return convertElementaryArrayToJSONArray(array);
  }

  const handleAddFilters = () => {
    let filterObj = {
      key: "",
      value: "",
      custom_from_date: "",
      custom_to_date: "",
    };
    getValue(request, `report_filter`, []).push(filterObj);
    setFilterKeys([...filterKeys]);
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        request.report_type = getValue(params, `type`, "");
        request.columns_shown = shownColumns.map((item: object) =>
          getValue(item, `key`, "")
        );
        let resp = await createAnalyticsReport(request);
        if (resp) {
          setSubmitLoading(false);
          toast.success(getValue(resp, `message`, ""));
          navigate(
            `/admin/analytics/${getValue(params, `type`, "")}/${getValue(
              params,
              `name`,
              ""
            )}/list`
          );
          reset();
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const reset = () => {
    setRequest({
      ...request,
      report_type: "",
      report_name: "",
      report_description: "",
      columns_shown: [],
      report_filter: [],
    });
  };
  const [isLoading, setIsLoading] = useState(false);
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_dashboard"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div
                onClick={() =>
                  navigate(
                    `/admin/analytics/${getValue(
                      params,
                      `type`,
                      ""
                    )}/${getValue(params, `name`, "")}/list`
                  )
                }
              >
                <BackSvgComponent />
              </div>
              <h4 className="dashbaord-wrapper__header-title">
                Create {formatText(params.type)} Report
              </h4>
            </div>
            {getValue(props, `permissions`, []).includes("create") && (
              <button
                className={`ascent-button ascent-button--header-buttons ascent-button--${"secondary"}`}
                onClick={handleSubmit}
                disabled={submitLoading}
              >
                {submitLoading ? "Please wait..." : "Create"}
              </button>
            )}
          </div>
          <div className="analytics-create-container">
            <div className="">
              <div className="m-auto w-50 p-4">
                <div className="form-group">
                  <label className="form-label">Report Name</label>
                  <InputField
                    inputType="TEXT"
                    value={getValue(request, `report_name`, "")}
                    name={"report_name"}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        report_name: e.target.value,
                      })
                    }
                    label={"Report Name"}
                    placeholder="Enter Report Name"
                    validator={simpleValidator}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Report Description</label>
                  <InputField
                    inputType="TEXTAREA"
                    value={getValue(request, `report_description`, "")}
                    name={"report_description"}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        report_description: e.target.value,
                      })
                    }
                    label={"Report Description"}
                    placeholder="Enter Report Description"
                  />
                </div>
              </div>
              <div className="p-4 w-100">
                <h6 className="header_text__18 color_primary">
                  {" "}
                  Show/Hide Columns{" "}
                </h6>

                <div className="d-flex position-relative">
                  <div className="w-100">
                    <h6 className="header_text__16 color_danger  p-2">
                      Columns Hidden
                    </h6>
                    <div className="analytics_box">
                      <div>
                        {getValue(hiddenColumns, `length`, 0) > 0 &&
                          hiddenColumns.map((col: any) => {
                            return (
                              <div className="border border-radius__5 mb-2 p-2 create-box d-flex justify-content-between align-items-center">
                                <h6 className="header_text__16 p-1">
                                  {formatText(getValue(col, `key`, ""))}
                                  <span className="color_danger">
                                    {getValue(col, `is_mandatory`, false)
                                      ? "*"
                                      : ""}
                                  </span>
                                </h6>
                                <div
                                  className="mx-2"
                                  onClick={() => handleAddFields(col)}
                                >
                                  <AddSvgComponent size={22} />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="w-100">
                    <h6 className="header_text__16 color_success p-2">
                      Columns Shown
                    </h6>
                    <div className="analytics_box">
                      <div>
                        {getValue(shownColumns, `length`, 0) > 0 &&
                          shownColumns.map((col: any) => {
                            return (
                              <div className="border border-radius__5 mb-2 p-2 create-box d-flex justify-content-between align-items-center">
                                <h6 className="header_text__16 p-1">
                                  {formatText(getValue(col, `key`, ""))}
                                  <span className="color_danger">
                                    {getValue(col, `is_mandatory`, false)
                                      ? "*"
                                      : ""}
                                  </span>
                                </h6>
                                {!getValue(col, `is_default_shown`, false) && (
                                  <div
                                    className="mx-2"
                                    onClick={() => handleRemoveColumns(col)}
                                  >
                                    <RemoveCircleSvgComponent
                                      size={22}
                                      color={"#d72e38"}
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 w-50 mb-5">
              <h6 className="header_text__18 color_primary"> Filters </h6>
              <div>
                {getValue(request, `report_filter.length`, 0) > 0 &&
                  getValue(request, `report_filter`, []).map(
                    (item: object, i: number) => {
                      return (
                        <div className="d-flex">
                          <div className="mb-3">
                            <SingleSelectSearchDropdown
                              data={filterKeys}
                              label={"label"}
                              value={getValue(item, `key`, "")}
                              selectKey={"value"}
                              onChange={(e: any) =>
                                setRequest((object: any) => {
                                  return {
                                    ...object,
                                    ["report_filter"]: object[
                                      "report_filter"
                                    ].map((x: any, index: number) => {
                                      if (index !== i) return x;
                                      return {
                                        ...x,
                                        key: getValue(e, `value`, ""),
                                        value: null,
                                        custom_from_date: null,
                                        custom_to_date: null,
                                      };
                                    }),
                                  };
                                })
                              }
                              width={"100%"}
                            />
                          </div>
                          <div>
                            <Analyticsbuilder
                              item={item}
                              i={i}
                              request={request}
                              setRequest={setRequest}
                            />
                          </div>
                          {getValue(item, `value`, "") === "CUSTOM" && (
                            <div className="d-flex align-items-center">
                              <div>
                                <InputField
                                  inputType="DATETYPE-DOB"
                                  placeholder="Enter From Date"
                                  className="form-control release-date-wrapper__vendor-form-control--calendar"
                                  name="date"
                                  id="date"
                                  value={
                                    getValue(item, `custom_from_date`, "")
                                      ? new Date(
                                          getValue(item, `custom_from_date`, "")
                                        )
                                      : null
                                  }
                                  selected={
                                    getValue(item, `custom_from_date`, "")
                                      ? new Date(
                                          getValue(item, `custom_from_date`, "")
                                        )
                                      : null
                                  }
                                  onChange={(e: any) =>
                                    setRequest((object: any) => {
                                      return {
                                        ...object,
                                        ["report_filter"]: object[
                                          "report_filter"
                                        ].map((x: any, index: number) => {
                                          if (index !== i) return x;
                                          return {
                                            ...x,
                                            custom_from_date: convertDate(e),
                                            custom_to_date: null,
                                          };
                                        }),
                                      };
                                    })
                                  }
                                  label="From Date"
                                  // minDate={new Date()}
                                />
                              </div>
                              <div>
                                <InputField
                                  inputType="DATETYPE-DOB"
                                  placeholder="Enter To Date"
                                  className="form-control release-date-wrapper__vendor-form-control--calendar"
                                  name="date"
                                  id="date"
                                  value={
                                    getValue(item, `custom_to_date`, "")
                                      ? new Date(
                                          getValue(item, `custom_to_date`, "")
                                        )
                                      : null
                                  }
                                  selected={
                                    getValue(item, `custom_to_date`, "")
                                      ? new Date(
                                          getValue(item, `custom_to_date`, "")
                                        )
                                      : null
                                  }
                                  onChange={(e: any) =>
                                    getValue(item, `custom_from_date`, "")
                                      ? setRequest((object: any) => {
                                          return {
                                            ...object,
                                            ["report_filter"]: object[
                                              "report_filter"
                                            ].map((x: any, index: number) => {
                                              if (index !== i) return x;
                                              return {
                                                ...x,
                                                custom_to_date: convertDate(e),
                                              };
                                            }),
                                          };
                                        })
                                      : toast.error("Please select from date")
                                  }
                                  label="To Date"
                                  minDate={
                                    getValue(item, `custom_from_date`, "")
                                      ? new Date(
                                          getValue(item, `custom_from_date`, "")
                                        )
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
              </div>
              <div
                className="d-flex align-items-center cursor-pointer mt-2"
                onClick={() => handleAddFilters()}
              >
                <AddSvgComponent color={"#408dfb"} />
                <h6 className="header_text__14 color_primary">Add Filters</h6>
              </div>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default CreateAnalytics;
