import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import BulkUpload from "../BulkUpload";
import Search from "../Search";
import "./uploaded-details.scss";
import "./add-filter.scss";
import Select, { StylesConfig } from "react-select";
import { useNavigate } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import DownloadSvgComponent from "@assets/svg-components/download";
import BulkUploadDropdown from "@components/BulkUploadDropdown";
import CommonDownloadSection from "@components/Download";
import EyeSvgComponent from "@assets/svg-components/eye";
import LogsScreen from "@components/Logs";
import { Tooltip } from "react-tooltip";
import FileLogSvgComponent from "@assets/svg-components/file-log";

const SearchHeader = (props: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const customStyles: StylesConfig = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#dadce0",
      };
    },
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: 40,
      width: "260px",
      border: state.isFocused ? "1px solid #dadce0" : "1px solid #dadce0",
      boxShadow: state.isFocused ? "0px 0px 0px #dadce0" : "none",
      "&:hover": {
        // border: "1px solid #ff8b67",
        // boxShadow: "0px 0px 6px #ff8b67"
      },
    }),
  };

  const handleNavigateReportLogs = (id: string) => {
    navigate(`/report-file-logs/${id}`);
  };

  return (
    <section className="add-filter-wrapper add-filter-wrapper--border w-100 d-flex align-items-center justify-content-between">
      <div className="add-filter-wrapper__add d-flex align-items-center">
        {props.add != "false" &&
          (getValue(props, `isAdd`, false) ? (
            <div
              data-tooltip-id="addTooltip"
              className="add-filter-wrapper__add-icon d-flex cursor-pointer"
              onClick={() => props.handleAddFun()}
              style={{
                backgroundColor: "#fd9933",
                height: "36px",
                width: "36px",
                borderRadius: "50%",
                marginRight: "15px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                style={{ margin: "auto", textAlign: "center" }}
              >
                <path
                  d="M11 19V13H5V11H11V5H13V11H19V13H13V19Z"
                  fill="#ffffff"
                />
              </svg>
            </div>
          ) : (
            <NavLink to={props.route ? `${props.route}` : "#"} className="pe-3">
              <div
                className="add-filter-wrapper__add-icon d-flex"
                data-tooltip-id="addTooltip"
              >
                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                  <path
                    d="M11 19V13H5V11H11V5H13V11H19V13H13V19Z"
                    fill="#ffffff"
                  />
                </svg>
              </div>
            </NavLink>
          ))}
        <Tooltip id="addTooltip" content="Add New" place="bottom" />
        {props.reportFilter && (
          <div
            className="d-flex justify-content-between gap"
            style={{ zIndex: "8" }}
          >
            <Select
              styles={customStyles}
              options={props.filterOptions}
              onChange={(e: any) =>
                props.handleNavigateURL(
                  getValue(props, `filter_key`, "")
                    ? getValue(props, `filter_key`, "")
                    : "status",
                  getValue(e, `value`, "")
                )
              }
              value={getValue(props, `filterOptions`, []).filter(
                (item: object) =>
                  getValue(item, `value`, "") ==
                  urlParams[getValue(props, `filter_key`, "")]
              )}
              isClearable
            />
          </div>
        )}

        {props.smartFilter && (
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={props.setFilterFormVisibility}
          >
            <img
              src="/images/filter-icon.svg"
              className="img-fluid cursor-pointer mx-2"
              height={24}
              width={24}
            />
            {props.isHeadingVisibleByDefault && (
              <>
                {props.isFilterHeadingVisible && (
                  <>
                    <h6 className="add-filter-wrapper__filter-status">
                      {props.headingText || "Filter Status"}
                    </h6>
                  </>
                )}
                {props.isFilterSubHeadingVisible && (
                  <h6 className="add-filter-wrapper__add-filter-title">
                    All <span>ADD FILTER</span>
                  </h6>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="add-filter-wrapper__filter d-flex align-items-center">
        {props.search !== "false" ? (
          <Search
            searchInput={getValue(props, `searchInput`, "")}
            showSearchTextbox={getValue(props, `showSearchTextbox`, "")}
            toggleSearch={props.toggleSearch}
            toggleSearchClose={props.toggleSearchClose}
            handleChangeSearch={props.handleChangeSearch}
            placeholder={props.placeholder}
          />
        ) : (
          <div style={{ height: "40px" }}></div>
        )}
        {getValue(props, `commonUpload`, false) && (
          <BulkUploadDropdown
            url={getValue(props, `downloadURL`, "")}
            dropdownOptions={getValue(props, `dropdownOptions`, [])}
          />
        )}
        {getValue(props, `download`, false) && (
          <CommonDownloadSection download_type={props.download_type} />
        )}
        {getValue(props, `download_with_logs`, false) && (
          <div className="cursor-pointer filterbar-icon-button-holder">
            {/* ms-2 */}
            <div onClick={props.handleDownload}>
              <div className="text-center">
                {/* ms-4 */}
                <DownloadSvgComponent size={24} color="#5f6368" />
              </div>
              <p className="small_text__12">
                {props.downloadLoading ? "Please wait..." : "DOWNLOAD"}
              </p>
            </div>
          </div>
        )}
        {getValue(props, `logs`, false) && (
          <LogsScreen log_name={props.log_name} />
        )}
        {getValue(props, `report_logs`, false) && (
          <div
            className="cursor-pointer filterbar-icon-button-holder"
            // ms-3
            onClick={() =>
              handleNavigateReportLogs(getValue(props, `log_name`, ""))
            }
          >
            <div className="text-center">
              {/* ms-1 */}
              <FileLogSvgComponent size={24} color="#5f6368" />
            </div>
            <p className="small_text__12">{"LOGS"}</p>
          </div>
        )}

        {props.isBulkUploadVisible === true && <BulkUpload />}
        {getValue(props, `downloadSelfComplianceReport`, false) && (
          <div
            className=" d-block justify-content-center cursor-pointer"
            onClick={props.handleDownloadSelfComplianceReport}
          >
            <img
              src="/images/download-gray.svg"
              style={{
                height: "24px",
                width: "24px",
                margin: "0px auto",
                marginLeft: "22px",
              }}
            />
            <p style={{ fontSize: "12px" }}>
              {getValue(props, `downloadLoading`, false)
                ? "Please wait..."
                : "DOWNLOAD"}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default SearchHeader;
