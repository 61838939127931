import ViolationSvgComponent from "@assets/svg-components/violation";
import { getValue } from "@utils/lodash";
import React from "react";

function ExpenseViolationSection(props: any) {
  const message = (htmlString: string) => {
    if (htmlString) return htmlString.replace(/₹ null/g, "");
    else return "";
  };
  return (
    <div className="expense_violation_container">
      <div className="p-3">
        <h6 className="header_text color_violation">
          Warning Violations (
          {getValue(props, `formInfo.policy_violations.length`, 0)})
        </h6>
        {getValue(props, `formInfo.policy_violations`, []).map(
          (item: object) => {
            return (
              <div className="d-flex align-items-center">
                <ViolationSvgComponent size={14} color={"#b15620"} />
                <p className="desc_text color_violation_desc ms-2">
                  {message(getValue(item, `display_msg`, ""))}
                </p>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

export default ExpenseViolationSection;
