import DocumentSvgComponent from "@assets/svg-components/document";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import ViolationSvgComponent from "@assets/svg-components/violation";
import { getColorStyle, getSingleColor } from "@common/color";
import { convertCurrentDate } from "@common/date-helpers";
import { formatAmount, formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Tooltip } from "react-tooltip";

function ExpenseTable(props: any) {
  return (
    <div>
      <div>
        <table className="datatable_table">
          <thead className="datatable_table_thead">
            <th className="datatable_table_th" style={{ width: "70px" }}>
              {/* <label
                className="m-0 position-relative"
                htmlFor="header-checkbox"
              >
                <input
                  type="checkbox"
                  className="default-checkbox"
                  id="header-checkbox"
                  onChange={() =>
                    props.handleCheckAll(
                      getValue(props, `list.length`, 0) ===
                        getValue(props, `selectedIDs.length`, 0)
                        ? "All"
                        : "None"
                    )
                  }
                  checked={
                    getValue(props, `list.length`, 0) > 0 &&
                    getValue(props, `list.length`, 0) ===
                      getValue(props, `selectedIDs.length`, 0)
                  }
                />
                <div className="custom-checkbox">
                  <img
                    src="/images/uploaded-details/tick.svg"
                    className="img-fluid uploaded-tick"
                  />
                </div>
              </label> */}
            </th>
            <th className="datatable_table_th">
              <h6 className="">Expense Type</h6>
              <p className="roles_thead_p">Expense No</p>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Expense Date</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Submit to</h6>
              <p className="roles_thead_p">Email</p>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Total Amount</h6>
            </th>

            <th className="datatable_table_th">
              <h6 className="">Status</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Action</h6>
            </th>
          </thead>
          <tbody className="datatable_table_tbody">
            {getValue(props, `list`, []).map((item: any, index: number) => {
              return (
                <tr key={index} className="datatable_table_tr">
                  <td className="datatable_table_td" style={{ width: "70px" }}>
                    {/* <label
                      className="m-0 position-relative"
                      htmlFor={`header-checkbox-${index}`}
                    >
                      <input
                        type="checkbox"
                        className="default-checkbox"
                        id={`header-checkbox-${index}`}
                        onChange={() =>
                          props.handleCheckUsers(getValue(item, `id`, ""))
                        }
                        checked={getValue(props, `selectedIDs`, []).includes(
                          getValue(item, `id`, "")
                        )}
                      />
                      <div className="custom-checkbox">
                        <img
                          src="/images/uploaded-details/tick.svg"
                          className="img-fluid uploaded-tick"
                        />
                      </div>
                    </label> */}
                    <ExpenseSvgComponent
                      color={getSingleColor(getValue(item, `status`, ""))}
                      size={22}
                    />
                  </td>
                  <td
                    className="datatable_table_td align-items-center"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <div className="d-flex">
                      <div>
                        <h6 className=" color_primary">
                          {getValue(item, `expense_type.expense_type_name`, "")}
                        </h6>
                        <p className="roles_tbody_td_p">
                          {getValue(item, `expense_no`, "")}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    className="datatable_table_td align-items-center"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <div className="d-flex">
                      <div>
                        <h6 className="">
                          {convertCurrentDate(
                            getValue(item, `expense_date`, "")
                          )}
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6>{getValue(item, `submits_to.name`, "-")} </h6>
                    <p>{getValue(item, `submits_to.email`, "")}</p>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6
                      className={` ${
                        parseInt(getValue(item, `amount`, 0)) < 0
                          ? "color_danger"
                          : ""
                      }`}
                    >
                      {getValue(item, `claimed_currency_symbol`, "")}{" "}
                      {formatAmount(getValue(item, `amount`, ""))}
                    </h6>
                  </td>
                  {/* <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {concatString(getValue(item, `base_amount`, ""), 30)}
                    </h6>
                  </td> */}
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6
                      className=""
                      style={getColorStyle(`${getValue(item, `status`, "")}`)}
                    >
                      {formatText(getValue(item, `status`, ""))}
                    </h6>
                  </td>
                  <td className="datatable_table_td">
                    <div className="d-flex align-items-center ">
                      {/* <div
                        className="action_container"
                        onClick={() =>
                          props.handleNavigate(getValue(item, `id`, ""))
                        }
                      >
                        <img
                          src="/images/edit-gray.svg"
                          className="action_container_image img-fluid"
                        />
                      </div>
                      <div className="action_container ms-4">
                        <img
                          src="/images/delete-gray.svg"
                          className="action_container_image img-fluid"
                          onClick={() =>
                            props.handleDeleteFun(
                              getValue(item, `id`, ""),
                              getValue(item, `title`, "")
                            )
                          }
                        />
                      </div> */}

                      <div
                        data-tooltip-id={
                          getValue(item, `receipts.length`, 0) > 0
                            ? "my-tooltip-document"
                            : ""
                        }
                        data-tooltip-content="This expense contains receipts"
                        className={`margin-action-icon`}
                      >
                        <DocumentSvgComponent
                          color={
                            getValue(item, `receipts.length`, 0) > 0
                              ? "#276EF1"
                              : "lightgray"
                          }
                        />
                      </div>
                      <div
                        data-tooltip-id={
                          getValue(item, `policy_violations.length`, 0) > 0
                            ? "my-tooltip"
                            : ""
                        }
                        data-tooltip-content="This expense violated certain policies"
                        className={`margin-action-icon`}
                      >
                        <ViolationSvgComponent
                          color={
                            getValue(item, `is_policy_violated`, "")
                              ? "#ff8a00"
                              : "lightgray"
                          }
                          size={20}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Tooltip id="my-tooltip" place="bottom" />
      <Tooltip id="my-tooltip-document" place="bottom" />
    </div>
  );
}

export default ExpenseTable;
