import { getValue } from "@utils/lodash";
import React from "react";

function DashboardSvgComponent(props: any) {
  return (
    <>
      {/* <svg
        id="dashboard_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        // width="18"
        // height="18"
        viewBox="0 0 18 18"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <path
          id="Path_39125"
          data-name="Path 39125"
          d="M0,0H18V18H0Z"
          fill="none"
        />
        <path
          id="Path_39126"
          data-name="Path 39126"
          d="M15,4.5V6H12V4.5h3m-7.5,0V9h-3V4.5h3m7.5,6V15H12V10.5h3m-7.5,3V15h-3V13.5h3M16.5,3h-6V7.5h6ZM9,3H3v7.5H9Zm7.5,6h-6v7.5h6ZM9,12H3v4.5H9Z"
          transform="translate(-0.75 -0.75)"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#d7242e"
          }
        />
      </svg> */}

      <svg id="dashboard_black_24dp" xmlns="http://www.w3.org/2000/svg" 
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"} 
        viewBox="0 0 18 18">
        <path id="Path_39125" data-name="Path 39125" d="M0,0H18V18H0Z" fill="none"/>
        <path id="Path_39126" data-name="Path 39126" d="M15,4.5V6H12V4.5h3m-7.5,0V9h-3V4.5h3m7.5,6V15H12V10.5h3m-7.5,3V15h-3V13.5h3M16.5,3h-6V7.5h6ZM9,3H3v7.5H9Zm7.5,6h-6v7.5h6ZM9,12H3v4.5H9Z" transform="translate(-0.75 -0.75)" fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#d7242e"
          }/>
      </svg>
    </>
  );
}

export default DashboardSvgComponent;
