import { getValue } from "@utils/lodash";
import { NavLink } from "react-router-dom";
import BackSvgComponent from "@assets/svg-components/back";

function CreateTripsStickyHeader(props: any) {
  const {
    handleSubmit,
    submitLoading,
    handleCreateTrip,
    route,
    tripLoading,
  } = props;
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <NavLink to={route ? `${route}` : "#"}>
              <BackSvgComponent />
            </NavLink>
            <p className="header_text">
              {" "}
              {getValue(
                props,
                `title`,
                getValue(props, `id`, "") ? "Update Trip" : "New Trip"
              )}
            </p>
          </div>
          {getValue(props, `permissions`, []).includes("update") && (
            <div className="d-flex align-items-center">
              <button
                onClick={handleSubmit}
                className="ascent-button ascent-button--header-buttons ascent-button--primary"
                disabled={submitLoading || tripLoading}
              >
                {submitLoading
                  ? "Please wait..."
                  : getValue(props, `id`, "")
                  ? "Update"
                  : "Save as Draft"}
              </button>
              {getValue(props, `id`, "") ? null : (
                <button
                  onClick={handleCreateTrip}
                  disabled={tripLoading || submitLoading}
                  className="ascent-button ascent-button--header-buttons ascent-button--secondary"
                >
                  {tripLoading ? "Please wait..." : "Save & Submit"}
                </button>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default CreateTripsStickyHeader;
