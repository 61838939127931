import { getValue } from "@utils/lodash";

function PolicySettingSvgComponent(props: any) {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" id="video_settings_black_24dp" 
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        viewBox="0 0 24 24">
        <g id="Group_10242" data-name="Group 10242">
          <rect id="Rectangle_17557" data-name="Rectangle 17557" width="24" height="24" fill="none"/>
        </g>
        <g id="Group_10244" data-name="Group 10244">
          <g id="Group_10243" data-name="Group 10243">
            <path id="Path_46857" data-name="Path 46857" d="M3,6H21v5h2V6a2.006,2.006,0,0,0-2-2H3A2.006,2.006,0,0,0,1,6V18a2.006,2.006,0,0,0,2,2h9V18H3Z" fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#5f6368"
            }/>
            <path id="Path_46859" data-name="Path 46859" d="M22.71,18.43a4.128,4.128,0,0,0,.01-.86l1.07-.85a.248.248,0,0,0,.06-.32l-1.03-1.79a.254.254,0,0,0-.31-.11l-1.28.5a3.439,3.439,0,0,0-.75-.42l-.2-1.36a.249.249,0,0,0-.25-.22H17.96a.257.257,0,0,0-.25.21l-.2,1.36a4.151,4.151,0,0,0-.74.42l-1.28-.5a.243.243,0,0,0-.31.11l-1.03,1.79a.248.248,0,0,0,.06.32l1.07.86a4.129,4.129,0,0,0-.01.86l-1.07.85a.248.248,0,0,0-.06.32l1.03,1.79a.254.254,0,0,0,.31.11l1.27-.5a3.439,3.439,0,0,0,.75.42l.2,1.36a.249.249,0,0,0,.25.21h2.07a.257.257,0,0,0,.25-.21l.2-1.36a4.151,4.151,0,0,0,.74-.42l1.28.5a.243.243,0,0,0,.31-.11l1.03-1.79a.248.248,0,0,0-.06-.32ZM19,19.5A1.5,1.5,0,1,1,20.5,18,1.5,1.5,0,0,1,19,19.5Z"  fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#5f6368"
            }
            />
          </g>
        </g>
      </svg>
    </>
  );
}

export default PolicySettingSvgComponent;
