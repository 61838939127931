import React from "react";
import { getValue } from "@utils/lodash";

function TripsDetailSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 18 18"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        // fill={
        //   getValue(props, `color`, "")
        //     ? getValue(props, `color`, "")
        //     : "#fd9933"
        // }
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_17691"
              data-name="Rectangle 17691"
              width="18"
              height="18"
              fill="#fff"
            />
          </clipPath>
        </defs>
        <g
          id="Mask_Group_186"
          data-name="Mask Group 186"
          clip-path="url(#clip-path)"
        >
          <g id="menu_open_black_24dp">
            <path
              id="Path_46984"
              data-name="Path 46984"
              d="M18,0H0V18H18Z"
              fill="none"
            />
            <path
              id="Path_46985"
              data-name="Path 46985"
              d="M15.75,13.5H6V12h9.75Zm0-3.75H8.25V8.25h7.5Zm0-5.25V6H6V4.5ZM2.25,11.693,4.935,9,2.25,6.307,3.307,5.25,7.057,9l-3.75,3.75Z"
              fill="#d7242e"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default TripsDetailSvgComponent;
