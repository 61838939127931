import React, { useState } from "react";
import InputField from "@common/InputRuleFeild";
import { getValue } from "@utils/lodash";
import "./travel_documents.scss";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { StylesConfig } from "react-select";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import DocumentImageList from "@common/FileUpload/document-image-list";
function TravelDocumentPopup(props: any) {
  const {
    showUpdatePopup,
    setShowUpdatePopup,
    request,
    handleChangeText,
    editorState,
    onEditorStateChange,
    submitLoading,
    handleSubmit,
    editId,
    setRequest,
    simpleValidator,
    countryList,
    handleUploadDocuments,
    fileTypes,
  } = props;
  const [typeList] = useState([
    { value: "visa", label: "Visa" },
    { value: "passport", label: "Passport" },
    { value: "esta", label: "ESTA" },
    { value: "others", label: "Others" },
  ]);
  const customStyles: StylesConfig = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#dadce0",
      };
    },
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: 43,
      border: state.isFocused ? "1px solid #dadce0" : "1px solid #dadce0",
      boxShadow: state.isFocused ? "0px 0px 0px #dadce0" : "none",
      "&:hover": {
        // border: "1px solid #ff8b67",
        // boxShadow: "0px 0px 6px #ff8b67"
      },
    }),
  };
  let country_ID =
    getValue(countryList, `length`, 0) > 0
      ? countryList.filter(
          (item: any) =>
            getValue(item, `id`, "") ===
            getValue(request, `document_country`, "")
        )[0]
      : "";
  return (
    <div
      className={`compelte-task-wrapper ${
        showUpdatePopup ? "compelte-task-wrapper--active" : ""
      }`}
      style={{ width: "60%", zIndex: "101" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">Travel Documents</h6>
        <img
          src="/images/close-white.svg"
          className="img-fluid navigation-bar-wrapper__close-btn"
          onClick={() => {
            setShowUpdatePopup(false);
            props.reset();
          }}
        />
      </div>

      <div className=" travel_document_container">
        <div className="travel_document_container_item_left">
          <div className="expense-create-wrapper_left_container">
            <h6 className="expense-create-wrapper_left_container_header">
              Documents
            </h6>
            <DragAndDropFileComponent
              uploadFile={handleUploadDocuments}
              name="file"
              types={fileTypes}
              multiple
              classes="bulk-upload_drag-n-drop-wrapper position-absolute"
              fileName={getValue(props, `importedFile.name`, "")}
              setImportTedFile={props.setImportTedFile}
            />
          </div>
          <div className="expense-detail-wrapper_report_documentsection w-100">
            <div className="d-flex  mt-3" style={{ flexWrap: "wrap" }}>
              <DocumentImageList
                documents={getValue(props, `documents`, [])}
                containerStayle={{ height: "100px", width: "150px" }}
                textContainerStayle={{ height: "40px", width: "150px" }}
                textStyle={{ fontSize: "12px" }}
                length={10}
                travelDocuments
                handleRemoveDocuments={props.handleRemoveDocuments}
              />
            </div>
          </div>
        </div>
        <div className="travel_document_container_item_right">
          {/* <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              User <span>*</span>{" "}
            </label>
            <SingleSelectSearchDropdown
              label={"label"}
              name="Type"
              value={getValue(request, `user`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  user: getValue(e, `id`, ""),
                })
              }
              placeholder={"Choose Type"}
              data={typeList}
              width={"100%"}
              validator={simpleValidator}
              lookup_api={"users"}
            />
          </div> */}
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Type <span>*</span>{" "}
            </label>
            <SingleSelectSearchDropdown
              label={"label"}
              selectKey={"value"}
              name="Type"
              value={getValue(request, `document_type`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  document_type: getValue(e, `value`, ""),
                })
              }
              placeholder={"Choose Type"}
              data={typeList}
              width={"100%"}
              validator={simpleValidator}
            />
          </div>

          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Country <span>*</span>{" "}
            </label>
            <InputField
              inputType="INPUT_REACT_SELECT"
              placeholder="Choose Country"
              className="form-control w-100"
              name="document_country"
              id="document_country"
              options={countryList}
              value={country_ID}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  document_country: getValue(e, `id`, ""),
                })
              }
              label="Country"
              validator={simpleValidator}
              styles={customStyles}
            />
          </div>

          <div className="user-details-wrapper__form-group form-group mt-3">
            <label className="checkpoint-active-details__label form-label-error">
              Issued On <span>*</span>{" "}
            </label>
            <InputField
              inputType="DATETYPE-DOB"
              placeholder="Enter Document Issued On"
              className="form-control release-date-wrapper__vendor-form-control--calendar"
              name="document_issued_on"
              id="document_issued_on"
              value={getValue(request, `document_issued_on`, "")}
              selected={getValue(request, `document_issued_on`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  document_issued_on: e,
                })
              }
              label="Document Issued On"
              validator={simpleValidator}
              maxDate={new Date()}
            />
          </div>

          <div className="user-details-wrapper__form-group form-group mt-3">
            <label className="checkpoint-active-details__label form-label-error">
              Document No <span>*</span>{" "}
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Document No"
              className="form-control w-100"
              name="document_number"
              id="document_number"
              label="Document No"
              value={getValue(request, `document_number`, "")}
              // onChange={(e: any) => handleChangeText(e)}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  document_number: e.target.value,
                })
              }
              validator={simpleValidator}
            />
          </div>

          <div className="user-details-wrapper__form-group form-group mt-3">
            <label className="checkpoint-active-details__label form-label-error">
              Valid From <span>*</span>{" "}
            </label>
            <InputField
              inputType="DATETYPE-DOB"
              placeholder="Enter Document Valid From"
              className="form-control release-date-wrapper__vendor-form-control--calendar"
              name="document_valid_from"
              id="document_valid_from"
              value={getValue(request, `document_valid_from`, "")}
              selected={getValue(request, `document_valid_from`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  document_valid_from: e,
                })
              }
              label="Document Valid From"
              validator={simpleValidator}
              maxDate={new Date()}
            />
          </div>

          <div className="user-details-wrapper__form-group form-group mt-3">
            <label className="checkpoint-active-details__label form-label-error">
              Expires On <span>*</span>{" "}
            </label>
            <InputField
              inputType="DATETYPE-DOB"
              placeholder="Enter Document Expired On"
              className="form-control release-date-wrapper__vendor-form-control--calendar"
              name="document_expires_on"
              id="document_expires_on"
              value={getValue(request, `document_expires_on`, "")}
              selected={getValue(request, `document_expires_on`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  document_expires_on: e,
                })
              }
              label="Document Expired On"
              validator={simpleValidator}
              minDate={
                getValue(request, `document_valid_from`, "")
                  ? getValue(request, `document_valid_from`, "")
                  : new Date()
              }
            />
          </div>
        </div>
      </div>
      {getValue(props, `permissions`, []).includes("update") && (
        <div className="fixed_moda_footer_wrapper">
          <div style={{ marginRight: "20px" }}>
            <button
              className="ascent-button ascent-button--header-buttons ascent-button--primary "
              onClick={() => {
                setShowUpdatePopup(false);
                props.reset();
              }}
            >
              Cancel
            </button>
            <button
              className="ascent-button ascent-button--header-buttons ascent-button--secondary ms-3"
              onClick={() => handleSubmit()}
              disabled={submitLoading}
            >
              {submitLoading ? "Please wait..." : editId ? "Update" : "Create"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TravelDocumentPopup;
