import DeleteSvgComponent from "@assets/svg-components/delete";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import { concatString, formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";

function TravelDocumentTable(props: any) {
  return (
    <div>
      <div>
        <table className="datatable_table">
          <thead className="datatable_table_thead">
            <th className="datatable_table_th" style={{ width: "70px" }}>
              <label
                className="m-0 position-relative"
                htmlFor="header-checkbox"
              >
                <input
                  type="checkbox"
                  className="default-checkbox"
                  id="header-checkbox"
                  onChange={() =>
                    props.handleCheckAll(
                      getValue(props, `list.length`, 0) ===
                        getValue(props, `selectedIDs.length`, 0)
                        ? "All"
                        : "None"
                    )
                  }
                  checked={
                    getValue(props, `list.length`, 0) > 0 &&
                    getValue(props, `list.length`, 0) ===
                      getValue(props, `selectedIDs.length`, 0)
                  }
                />
                <div className="custom-checkbox">
                  <img
                    src="/images/uploaded-details/tick.svg"
                    className="img-fluid uploaded-tick"
                  />
                </div>
              </label>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Document Number</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Document Type</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Document Country</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Document Valid From</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Document Issues On</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Document Expires On</h6>
            </th>
            <th className="datatable_table_th">Action</th>
          </thead>
          <tbody className="datatable_table_tbody">
            {getValue(props, `list`, []).map((item: any, index: number) => {
              return (
                <tr key={index} className="datatable_table_tr">
                  <td className="datatable_table_td" style={{ width: "70px" }}>
                    <label
                      className="m-0 position-relative"
                      htmlFor={`header-checkbox-${index}`}
                    >
                      <input
                        type="checkbox"
                        className="default-checkbox"
                        id={`header-checkbox-${index}`}
                        onChange={() =>
                          props.handleCheckUsers(getValue(item, `id`, ""))
                        }
                        checked={getValue(props, `selectedIDs`, []).includes(
                          getValue(item, `id`, "")
                        )}
                      />
                      <div className="custom-checkbox">
                        <img
                          src="/images/uploaded-details/tick.svg"
                          className="img-fluid uploaded-tick"
                        />
                      </div>
                    </label>
                  </td>
                  <td
                    className="datatable_table_td align-items-center position-relative"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <div className="d-flex">
                      <div>
                        <h6>{getValue(item, `document_number`, "")}</h6>
                      </div>
                    </div>
                    {getValue(item, `document_primary`, "") && (
                      <img src="/images/star.svg" className="primary_image" />
                    )}
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {formatText(getValue(item, `document_type`, ""))}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {getValue(item, `document_country.name`, "")}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {convertCurrentDate(
                        getValue(item, `document_valid_from`, "")
                      )}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {convertCurrentDate(
                        getValue(item, `document_issued_on`, "")
                      )}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {convertCurrentDate(
                        getValue(item, `document_expires_on`, "")
                      )}
                    </h6>
                  </td>
                  {getValue(props, `permissions`, []).includes("delete") ? (
                    <td
                      className="datatable_table_td"
                      onClick={() =>
                        props.handleDelete(getValue(item, `id`, ""))
                      }
                    >
                      <DeleteSvgComponent />
                    </td>
                  ) : (
                    <td className="datatable_table_td"></td>
                  )}
                  {/* <td className="datatable_table_td">
                  <div className="more-dropdown">
                    <UncontrolledDropdown>
                      <DropdownToggle>
                        <div className="switch_header__dropdown m-0">
                          <div className="dropdown-content d-flex cursor-pointer">
                            <img
                              src="/images/more.svg"
                              width="24px"
                              height="24px"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() =>
                            props.handleNavigate(getValue(item, `id`, ""))
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>Edit</p>
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            props.sendInviteUser(getValue(item, `id`, ""))
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>View</p>
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            props.handleDeleteFun(
                              getValue(item, `id`, ""),
                              getValue(item, `title`, "")
                            )
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>Delete</p>
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TravelDocumentTable;
