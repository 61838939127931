import { getValue } from "@utils/lodash";
import React from "react";

function ModulesSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="task_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        // width="18"
        // height="18"
        viewBox="0 0 18 18"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <g id="Group_10268" data-name="Group 10268">
          <path
            id="Path_46901"
            data-name="Path 46901"
            d="M0,0H18V18H0Z"
            fill="none"
          />
        </g>
        <g
          id="Group_10269"
          data-name="Group 10269"
          transform="translate(3 1.5)"
        >
          <path
            id="Path_46902"
            data-name="Path 46902"
            d="M11.5,2h-6A1.5,1.5,0,0,0,4.008,3.5L4,15.5A1.5,1.5,0,0,0,5.492,17H14.5A1.5,1.5,0,0,0,16,15.5v-9Zm3,13.5h-9V3.5h5.25V7.25H14.5ZM7.615,10.288,6.55,11.345,9.2,14,13.45,9.755,12.392,8.7l-3.18,3.18Z"
            transform="translate(-4 -2)"
            // fill="#3c4043"
            fill={
              getValue(props, `color`, "")
                ? getValue(props, `color`, "")
                : "#3c4043"
            }
          />
        </g>
      </svg>
    </div>
  );
}

export default ModulesSvgComponent;
