import { convertCurrentDateWithTime } from "@common/date-helpers";
import { getFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";

function DetailContainerComments(props: any) {
  const {
    loading,
    allComments,
    handleEdit,
    handleDeleteFun,
    comments,
    setComments,
    handleRemoveSelected,
    handleSubmit,
  } = props;
  const [user, setUser] = useState({});
  useEffect(() => {
    let user =
      getValue(localStorage, `user`, "") &&
      JSON.parse(getValue(localStorage, `user`, ""));
    setUser(user);
  }, []);
  return (
    <div className="">
      <div className="comments-popup p-3">
        <div className="d-flex align-items-start justify-content-between w-100 mb-3">
          <div className="comments-popup-wrapper__comments-wrapper">
            <p className="comment_text">
                {getFirstLetter(getValue(user, `email`, ""))}
            </p>
          </div>
          <textarea
            placeholder={"Add a comment"}
            value={comments}
            name="comments"
            onChange={(e) => setComments(e.target.value)}
            className="comments-popup__form-control form-control ms-2"
          />
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="ascent-button ascent-button--header-buttons ascent-button--primary mx-3"
            onClick={() => setComments("")}
            disabled={!comments}
          >
            Clear
          </button>
          <button
            className={`ascent-button ascent-button--header-buttons ascent-button--${"primary"}`}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
      <div>
        {loading ? (
          <p className="text-center mt-2">Please wait...</p>
        ) : getValue(allComments, `length`, 0) > 0 ? (
          allComments.map((item: object) => {
            return (
              <div className="comments-popup-wrapper__comments">
                <div>
                  <div className="d-flex align-items-start mb-3">
                    <div className="py-1">
                      <div className="vendorInfo-wrapper d-flex">
                        <p className="vendorInfo-wrapper__text">
                          {getValue(item, `is_system`, "")
                            ? "S"
                            : getValue(item, `created_by.first_name`, "")
                                .charAt(0)
                                .toUpperCase()}
                          {getValue(item, `created_by.last_name`, "")
                            .charAt(0)
                            .toUpperCase()}
                        </p>
                      </div>
                    </div>
                    <div className="mx-3">
                      <div className="d-flex align-items-center">
                        <div>
                          <h3 className="comments-popup-wrapper__comments-title vendorInfo-wrapper__comments-title">
                            {getValue(item, `is_system`, "") ? (
                              "System"
                            ) : (
                              <>
                                {getValue(item, `created_by.first_name`, "")}{" "}
                                {getValue(item, `created_by.last_name`, "")}
                              </>
                            )}
                          </h3>
                        </div>
                        <div className="mx-3">
                          {!getValue(item, `is_system`, false) && (
                            <>
                              <ul className="d-flex align-items-center">
                                {/* <li className="comments-popup-wrapper__comments-list-item">
                                  <a
                                    className="cursor-pointer"
                                    onClick={() => handleEdit(item)}
                                  >
                                    Edit
                                  </a>
                                </li> */}
                                <li className="comments-popup-wrapper__comments-list-item">
                                  <a
                                    className="cursor-pointer"
                                    onClick={() =>
                                      handleDeleteFun(
                                        getValue(item, `id`, ""),
                                        getValue(item, `comments`, "")
                                      )
                                    }
                                  >
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                      <p className="comments-popup-wrapper__comments-text mb-2">
                        Posted on{" "}
                        {convertCurrentDateWithTime(
                          getValue(item, `created_at`, "")
                        )}
                        {"  "}
                      </p>
                      <p className="comments-popup-wrapper__comments-text">
                        {getValue(item, `comments`, "")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center mt-2 mb-3"> There are no comments.</p>
        )}
      </div>
    </div>
  );
}

export default DetailContainerComments;
