import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllTrips = (queryRequest: string) =>
  get(`${config.API_URL}/trip?${queryRequest}`);

export const getAllTripsAdmin = (queryRequest: string) =>
  get(`${config.API_URL}/trip/admin?${queryRequest}`);

export const getSpecificTrip = (id: string) =>
  get(`${config.API_URL}/trip/${id}`);

export const createTrip = (payload: object) =>
  post(`${config.API_URL}/trip`, payload);

export const updateTrip = (id: string, payload: object) =>
  patch(`${config.API_URL}/trip/${id}`, payload);

export const deleteSpecificTrip = (id: string) =>
  Delete(`${config.API_URL}/trip/${id}`);

export const getAllTripForm = (queryRequest: string) =>
  get(`${config.API_URL}/trip/form?${queryRequest}`);

export const getAllTripFormEdit = (id: string) =>
  get(`${config.API_URL}/trip/${id}/form-edit`);

//trip upload

export const uploadTripReceipts = (id: string, payload: object) =>
  patch(`${config.API_URL}/trip/${id}/upload/documents`, payload);

export const deleteTripReceipts = (id: string, receiptId: string) =>
  Delete(`${config.API_URL}/trip/${id}/document?${receiptId}`);

//trip submission

export const submitTrip = (payload: string) =>
  post(`${config.API_URL}/trip/submit`, payload);

export const approveTrip = (id: string) =>
  get(`${config.API_URL}/trip/${id}/approve`);

// export const rejectTrip = (id: string) =>
//   get(`${config.API_URL}/trip/${id}/reject`);

export const recallTrip = (id: string) =>
  get(`${config.API_URL}/trip/${id}/recall`);

export const rejectTrip = (id: string, payload: object) =>
  patch(`${config.API_URL}/trip/${id}/reject`, payload);


export const sentbackUserTrip = (id: string, payload: object) =>
patch(`${config.API_URL}/trip/${id}/sent-back`, payload);


export const getTripValidateApproverFlowType = (id: string) =>
  get(`${config.API_URL}/trip/${id}/validate/approver-flow-type`);

//airports

export const getAllAirports = (queryRequest: string) =>
  get(`${config.API_URL}/airport?${queryRequest}`);

//trip submission Admin

export const adminApproveTrip = (id: string, payload: object) =>
  patch(`${config.API_URL}/trip/${id}/admin/approve`, payload);

export const adminRejectTrip = (id: string, payload: object) =>
  patch(`${config.API_URL}/trip/${id}/admin/reject`, payload);

//comments
export const tripComments = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/trip/${id}/comment?${queryRequest}`);

//advanced upload

export const tripTravelModeKeys = (queryRequest: string) =>
  get(`${config.API_URL}/trip/travel-modes/get-keys?${queryRequest}`);

export const tripAddAttachment = (id: string, payload: object) =>
  post(`${config.API_URL}/trip/${id}/attachment`, payload);

export const tripGetAllAttachments = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/trip/${id}/attachment?${queryRequest}`);

export const tripGetSpecificAttachments = (id: string) =>
  get(`${config.API_URL}/trip/attachment/${id}`);

export const tripUpdateAttachment = (id: string, payload: object) =>
  patch(`${config.API_URL}/trip/${id}/attachment`, payload);

export const tripUpdateAttadchment = (id: string, receiptId: string) =>
  Delete(`${config.API_URL}/trip/${id}/document?${receiptId}`);

export const tripUpdateAttachmentDocuments = (id: string, payload: object) =>
  patch(`${config.API_URL}/trip/attachment/${id}/document`, payload);

export const tripDeleteAttachmentDocuments = (
  id: string,
  queryRequest: string
) => Delete(`${config.API_URL}/trip/attachment/${id}/document?${queryRequest}`);

export const completeTrip = (id: string) =>
  get(`${config.API_URL}/trip/${id}/complete`);


//Trips Services
export const createTripsLimit = (payload: object) =>
  post(`${config.API_URL}/policy/trip-limit`, payload);

export const getTripsLimit = (id: any) =>
  get(`${config.API_URL}/policy/${id}/trip-limit`);

export const updateTripsLimit = (id: string, payload: object) => 
  patch(`${config.API_URL}/policy/trip-limit/${id}`, payload);

export const createFlightPreference = (payload: object) =>
  // post(`${config.API_URL}/policy/trip-limit/flight-limit`, payload);
  post(`${config.API_URL}/policy/bulk/flight-limit`, payload);


export const editFlightPreference = (id: string,payload: object) =>
  patch(`${config.API_URL}/policy/trip-limit/flight-limit/${id}`, payload);

  export const editFlightPreferenceNew = (payload: object) =>
  // patch(`${config.API_URL}/policy/trip-limit/flight-limit/${id}`, payload);
  patch(`${config.API_URL}/policy/bulk/flight-limit`, payload);

export const getFlightsLimit = (id: any) =>
  get(`${config.API_URL}/policy/${id}/flight-limit`);
