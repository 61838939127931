import { getValue } from "@utils/lodash";
import React from "react";

function DoneSvgComponent(props:any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="icon text-policy-violation align-middle mr-2 flex-shrink-0"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#3c4043"
          }
      >
        <path d="m308.5 193.6-76.2 81.3c-.4.4-.9.4-1.4.1l-29.6-23.7c-9-7.3-22.3-5.8-29.5 3.2-7.3 9-5.8 22.3 3.2 29.5l45.5 36.5c3.9 3.1 8.5 4.6 13.1 4.6 5.6 0 11.2-2.2 15.3-6.6l90.2-96.2c7.9-8.5 7.5-21.8-1-29.7-8.4-7.9-21.7-7.5-29.6 1z"></path>
        <path d="M434.4 77.6C386.7 30 323.4 3.7 256 3.7S125.3 30 77.6 77.6 3.7 188.6 3.7 256s26.2 130.7 73.9 178.4c47.7 47.7 111 73.9 178.4 73.9s130.7-26.2 178.4-73.9c47.7-47.7 73.9-111 73.9-178.4S482 125.3 434.4 77.6zM256 466.3C140 466.3 45.7 372 45.7 256 45.7 140 140 45.7 256 45.7c116 0 210.3 94.3 210.3 210.3 0 116-94.3 210.3-210.3 210.3z"></path>
      </svg>
    </div>
  );
}

export default DoneSvgComponent;
