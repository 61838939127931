import { getValue } from "@utils/lodash";
import React from "react";

function ExpenseAmountSection(props: any) {
  return (
    <div className="expense_amount_section p-3">
      <h6 className="header_text__22">
        {getValue(props, `formInfo.claimed_currency_symbol`, "")}{" "}
        {getValue(props, `formInfo.amount`, "")}
      </h6>
      {getValue(props, `formInfo.bc_currency_symbol`, "") !==
        getValue(props, `formInfo.claimed_currency_symbol`, "") && (
        <p className="small_text__14 mt-2">
          Amount in {getValue(props, `formInfo.bc_currency`, "")}
          {": "}
          {getValue(props, `formInfo.bc_amount`, "")} {"( "}1{" "}
          {getValue(props, `formInfo.claimed_currency_symbol`, "")} ={" "}
          {getValue(props, `formInfo.conversion_rate`, "")}{" "}
          {getValue(props, `formInfo.bc_currency`, "")}
          {" )"}
        </p>
      )}
    </div>
  );
}

export default ExpenseAmountSection;
