import { convertCurrentDate } from "@common/date-helpers";
import { getFirstLetterOfEachString } from "@common/text-helpers";
import ProfileCircle from "@components/ProfileCircle";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

function RejectCommentPopup(props) {
  const POSITION_TYPES = [
    "bottom right",
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "left center",
    "center center",
  ];

  const popupRef = useRef();
  const handleClose = (e) => {
    e.stopPropagation();
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };

  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      className="d-flex justify-content-between align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      {props.header}
    </div>
  ));
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (props.value) {
      setNotes(props.value);
    }
  }, []);
  const { rejectComment } = props;
  return (
    <Popup
      trigger={(open) => <CustomButtons open={open} header={props.header} />}
      position={POSITION_TYPES}
      closeOnDocumentClick
      on={"hover"}
      contentStyle={{
        width: "350px",
        zIndex: 9999,
        background: "white",
        boxShadow:
          "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      }}
      nested
      ref={popupRef}
      onOpen={handleOpen}
    >
      <div className="mt-4 px-3 border-bottom">
        <p className="header_text__14 mb-2">
          {getValue(props, `type`, "") === "sent_back"
            ? "Sent back"
            : "Rejected"}{" "}
          from
        </p>
        <div className="d-flex align-items-center mt-1">
          <div className="profile_circle bg-success">
            <h5 className="title">
              {getFirstLetterOfEachString(
                getValue(rejectComment, `created_by.first_name`, "")
              )}
            </h5>
          </div>
          <div className="">
            <p className="header_text">
              {getValue(rejectComment, `created_by.first_name`, "")}{" "}
              {getValue(rejectComment, `created_by.middle_name`, "")}{" "}
              {getValue(rejectComment, `created_by.last_name`, "")}
            </p>
            <p className="small_text mt-1">
              {getValue(rejectComment, `created_by.email`, "")}
            </p>
            <p className="small_text mt-1">
              {convertCurrentDate(getValue(rejectComment, `created_at`, ""))}
            </p>
          </div>
        </div>
        <div className="p-3 small_text__14">
          {/* <h6 className="header_text__16 color_danger">Reject Reason</h6> */}
          <div className="m-auto mt-2">
            <h6 className="header_text__14">Comment : </h6>
            <p className="small_text__16">{props.comment}</p>
          </div>
        </div>
      </div>
      {/* <div className="p-3">
        <h6 className="header_text__16 color_danger">Reject Reason</h6>
        <div className="m-auto mt-2">{props.comment}</div>
      </div> */}
    </Popup>
  );
}

export default RejectCommentPopup;
