import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllExpenseTypes = (queryRequest: string) =>
  get(`${config.API_URL}/expense-type?${queryRequest}`);

export const getSpecificExpenseType = (id: string) =>
  get(`${config.API_URL}/expense-type/${id}`);

export const createExpenseType = (payload: object) =>
  post(`${config.API_URL}/expense-type`, payload);

export const updateExpenseType = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-type/${id}`, payload);

export const deleteExpenseType = (id: string) =>
  Delete(`${config.API_URL}/expense-type/${id}`);

export const updateExpenseTypeBulk = (payload: object) =>
  patch(`${config.API_URL}/expense-type/bulk/active-inactive`, payload);

export const attachExpenseType = (id: string, payload: object) =>
  patch(`${config.API_URL}/form-properties/${id}/attach/expense-type`, payload);

export const removeAttachedExpenseType = (id: string,queryRequest:string) =>
  Delete(`${config.API_URL}/form-properties/${id}/remove/expense-type?${queryRequest}`);
