import { getValue } from "@utils/lodash";
import React from "react";
import { NavLink } from "react-router-dom";

function StickyHeader(props: any) {
  const { isChanged, handleSubmit, submitLoading, id, route } = props;
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <NavLink to={`${route}`}>
              <div className="common_image_container">
                <img
                  src="/images/back-link.svg"
                  className="img-fluid common_image_big"
                />
              </div>
            </NavLink>
            <div className="inner-page-navigation-with-back-cta-button__header">
              <h4 className="inner-page-navigation-with-back-cta-button__header-title">
                {getValue(props, `title`, "")}
              </h4>
            </div>
          </div>
          <div>
            <NavLink to={`${route}`}>
              <button className="ascent-button ascent-button--header-buttons ascent-button--primary mx-3">
                Cancel
              </button>
            </NavLink>

            <button
              className={`ascent-button ascent-button--header-buttons ascent-button--${
                !isChanged ? "secondary" : "primary"
              }`}
              onClick={handleSubmit}
              disabled={isChanged || submitLoading}
            >
              {submitLoading ? "Please wait..." : id ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StickyHeader;
