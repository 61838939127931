import "./form.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: any;
  onChange: any;
  label: string;
  validator: any;
  placeholder: string;
  mandatory: boolean;
  maxDate: any;
  minDate: any;
  hideLabel: boolean;
};

export default function DateMonthYearPicker({
  disabled,
  name,
  type,
  value,
  onChange,
  validator,
  label,
  placeholder,
  mandatory,
  maxDate,
  minDate,
  hideLabel,
}: Props) {

  // Function to check if the provided value is in "YYYY-MM-DD" format
  const isValidDateFormat = (input: any) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(input);
  };

  // Function to check if the provided value is in "YYYY-MM-DDTHH:mm:ss" format
  const isValidDateTimeFormat = (input: any) => {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    return dateTimeRegex.test(input);
  };

  // Check if the value is a valid date
  const isValidDate =
    (value instanceof Date && !isNaN(value.getTime())) ||
    isValidDateFormat(value) ||
    isValidDateTimeFormat(value);

  // If the value is in "YYYY-MM-DD" or "YYYY-MM-DDTHH:mm:ss" format, convert it to a Date object
  const dateValue =
    isValidDateFormat(value) || isValidDateTimeFormat(value)
      ? new Date(value)
      : null;

  // let selected:any = value && new Date(value) != "Invalid Date"
  return (
    <div
    // className={`${"signup-form-wrapper__form-group"} ${
    //   validator && validator.current.message(label, value, "required")
    //     ? "signup-form-wrapper__form-group--error"
    //     : ""
    // } form-group mb-3`}
    >
      {!hideLabel && (
        <label className={mandatory ? "form-label-error" : "form-label"}>
          {label} {mandatory && "*"}
        </label>
      )}
      <div
      // style={{
      //   borderLeft: mandatory ? "1px solid red" : "",
      //   borderRadius: mandatory ? "5px" : "",
      // }}
      >
        <DatePicker
          className={`custom-form-control-date`}
          // selected={isValidDate ? new Date(value) : null}
          selected={
            isValidDate
              ? dateValue
              : value
              ? new Date(value)
                ? new Date(value)
                : null
              : null
          }
          onChange={onChange}
          peekNextMonth
          // startDate={startDate}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          maxDate={maxDate}
          minDate={minDate}
          placeholderText={placeholder}
          dateFormat="d MMM yyyy"
        />
      </div>
      {/* {validator && (
        <p
          className={`${"error-text"} ${
            // getValue(props, `validLeft`, "") ? "" :
            "text-center"
          }`}
        >
          {validator && validator.current.message(label, value, "required")}
        </p>
      )} */}
      {validator && (
        <p className={`${"error-text"}`}>
          {validator.current.message(label, value, "required")}
        </p>
      )}
    </div>
  );
}
