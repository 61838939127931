import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import SearchSvgComponent from "@assets/svg-components/search";
import { getLookupAPIs } from "@components/common/CustomSearchableDropdown/lookup-apis";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

function TripsAirportPopup(props) {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  const [info, setinfo] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    fetchDefaultData();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */

  const handleFetchData = async (search_text) => {
    if (search_text) {
      try {
        setIsLoading(true);
        let resp = await getLookupAPIs("airports", "", search_text);
        if (resp) {
          setinfo(resp);
          setIsLoading(false);
          handleOpen();
        } else {
          setinfo([]);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      fetchDefaultData();
      //   onChange(null);
    }
  };
  const data = [
    {
      id: "BOM",
      name: "Mumbai Chhatrapati Shivaji Airport",
      city: "Mumbai",
      alias_city: null,
      country_name: "India",
      value: "BOM",
      label: " BOM - Mumbai chhatrapati shivaji airport",
    },
    {
      id: "DEL",
      name: "Delhi Indira Gandhi Intl",
      city: "Delhi",
      alias_city: "Delhi",
      country_name: "India",
      value: "DEL",
      label: " DEL - Delhi indira gandhi intl",
    },
    {
      id: "BLR",
      name: "Bengaluru Intl Airport",
      city: "Bangalore",
      alias_city: "Bangalore",
      country_name: "India",
      value: "BLR",
      label: " BLR - Bengaluru intl airport",
    },
    {
      id: "PNQ",
      name: "Lohegaon Airport",
      city: "Pune",
      alias_city: null,
      country_name: "India",
      value: "PNQ",
      label: " PNQ - Lohegaon airport",
    },
    {
      id: "HYD",
      name: "Shamshabad Rajiv Gandhi Intl Airport",
      city: "Hyderabad",
      alias_city: null,
      country_name: "India",
      value: "HYD",
      label: " HYD - Shamshabad rajiv gandhi intl airport",
    },
    {
      id: "MAA",
      name: "Chennai International Airport",
      city: "Chennai",
      alias_city: null,
      country_name: "India",
      value: "MAA",
      label: " MAA - Chennai international airport",
    },
    {
      id: "CJB",
      name: "Peelamedu Airport",
      city: "Coimbatore",
      alias_city: null,
      country_name: "India",
      value: "CJB",
      label: " CJB - Peelamedu airport",
    },
    {
      id: "GOI",
      name: "Dabolim Airport",
      city: "Goa In",
      alias_city: "Goa In",
      country_name: "India",
      value: "GOI",
      label: " GOI - Dabolim airport",
    },
    {
      id: "DXB",
      name: "Dubai Intl Airport",
      city: "Dubai",
      alias_city: null,
      country_name: "United Arab Emirates",
      value: "DXB",
      label: " DXB - Dubai intl airport",
    },
  ];
  const fetchDefaultData = async () => {
    setinfo(data);
    // try {
    //   setIsLoading(true);
    //   let resp = await getLookupAPIs("airports", "", "");
    //   if (resp) {
    //     setinfo(resp);
    //     setIsLoading(false);
    //   } else {
    //     setinfo([]);
    //     setIsLoading(false);
    //   }
    // } catch (error) {
    //   setIsLoading(false);
    // }
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Popup Section                            */
  /* -------------------------------------------------------------------------- */
  const POSITION_TYPES = [
    "bottom right",
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "left center",
    "center center",
  ];

  const popupRef = useRef();
  const handleClose = () => {
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };
  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      className="d-flex justify-content-between align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      {props.header}
    </div>
  ));
  /* -------------------------------------------------------------------------- */
  /*                             InputSearch Section                            */
  /* -------------------------------------------------------------------------- */
  const inputRef = useRef(null);
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);
  useEffect(() => {
    if (query) {
      setInputChange(true);
    }
  }, [query]);

  return (
    <Popup
      trigger={(open) => (
        <CustomButtons
          open={open}
          reports={props.reports}
          header={props.header}
        />
      )}
      position={POSITION_TYPES}
      closeOnDocumentClick
      on={"click"}
      contentStyle={{
        width: "284px",
        zIndex: 9999,
        background: "white",
        boxShadow:
          "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      }}
      nested
      ref={popupRef}
      onOpen={handleOpen}
    >
      <div className="trips_popup_airport_container mb-2">
        <div className="d-flex position-relative p-2 border-bottom">
          <div className="trips_search_image position-absolute">
            <SearchSvgComponent size={16} color={"#408dfb"} />
          </div>
          <input
            className="trip_search_input w-100"
            ref={inputRef}
            type="text"
            value={inputChange ? (query ? query : "") : ""}
            name="searchTerm"
            autoComplete="off"
            placeholder={getValue(props, `placeholder`, "Search airports")}
            onChange={(e) => {
              //   !e.target.value && onChange(null);
              handleFetchData(e.target.value);
              setQuery(e.target.value);
            }}
            style={{
              minWidth: getValue(props, `customStyle`, false) && "50px",
            }}
          />
          {query ? (
            <div
              className="trips_delete_image position-absolute"
              onClick={() => {
                handleFetchData("");
                setQuery("");
              }}
            >
              <CloseSmallSvgComponent />
            </div>
          ) : null}
        </div>
        <div className="trips_popup_airport_container_main px-2 mt-1">
          {getValue(info, `length`, 0) > 0 ? (
            info.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className={`p-2 cursor-pointer trips_popup_options mt-1 border-radius__5 ${
                      getValue(item, `value`, "") === getValue(props, `value`)
                        ? "active"
                        : ""
                    }`}
                    onClick={(e) => {
                      props.onChange(item);

                      handleClose();
                      setQuery("");
                    }}
                  >
                    <h6 className="header_text__14">
                      {getValue(item, `city`, "")} ({getValue(item, `id`, "")})
                    </h6>
                    <p className="small_text">{getValue(item, `name`, "")}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-center mt-4">No Airports Found</p>
          )}
        </div>
      </div>
    </Popup>
  );
}

export default TripsAirportPopup;
