import { config } from "../env";
import { get } from "./helpers/http-handler";

export const dashboardUsereSummary = (queryRequest: string) =>
  get(`${config.API_URL}/dashboard/user/summary?${queryRequest}`);

export const dashboardExpenseTypeSummary = (queryRequest: string) =>
  get(`${config.API_URL}/dashboard/expense-type/summary?${queryRequest}`);

export const dashboardExpenseSummary = (queryRequest: string) =>
  get(`${config.API_URL}/dashboard/expense-report/summary?${queryRequest}`);

export const dashboardTripSummary = (queryRequest: string) =>
  get(`${config.API_URL}/dashboard/trip/summary?${queryRequest}`);

  export const dashboardProjectSummary = (queryRequest: string) =>
  get(`${config.API_URL}/dashboard/project/summary?${queryRequest}`);

  export const dashboardExpenseGraph = (queryRequest: string) =>
  get(`${config.API_URL}/dashboard/expense-graph/summary?${queryRequest}`);

  export const dashboardDepartmentSummary = (queryRequest: string) =>
  get(`${config.API_URL}/dashboard/department/summary?${queryRequest}`);
