import { getValue } from "@utils/lodash";
import React from "react";

function ListmanagementSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="dns_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        // width="18.004"
        // height="18.004"
        viewBox="0 0 18.004 18.004"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <path
          id="Path_46925"
          data-name="Path 46925"
          d="M0,0H18V18H0Z"
          fill="none"
        />
        <path
          id="Path_46926"
          data-name="Path 46926"
          d="M15,12v3H4.5V12H15m.75-1.5h-12a.752.752,0,0,0-.75.75v4.5a.752.752,0,0,0,.75.75h12a.752.752,0,0,0,.75-.75v-4.5A.752.752,0,0,0,15.753,10.5ZM6,14.628A1.125,1.125,0,1,1,7.126,13.5,1.129,1.129,0,0,1,6,14.628ZM15,4.5v3H4.5v-3H15m.75-1.5h-12A.752.752,0,0,0,3,3.75v4.5A.752.752,0,0,0,3.75,9h12a.752.752,0,0,0,.75-.75V3.75A.752.752,0,0,0,15.753,3ZM6,7.126A1.125,1.125,0,1,1,7.126,6,1.129,1.129,0,0,1,6,7.126Z"
          transform="translate(-0.75 -0.75)"
          //   fill="#3c4043"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#3c4043"
          }
        />
      </svg>
    </div>
  );
}

export default ListmanagementSvgComponent;
