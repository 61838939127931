import DownloadSvgComponent from "@assets/svg-components/download";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import { getColorStyle } from "@common/color";
import { handleDownloadOnClick } from "@common/downloadfile";
import { getValue } from "@utils/lodash";
import { Tooltip } from "react-tooltip";

function FileLogsTable(props: any) {
  const dummy = () => {};
  return (
    <div>
      <div>
        <table className="datatable_table">
          <thead className="datatable_table_thead">
            <th className="datatable_table_th" style={{ width: "70px" }}></th>
            <th className="datatable_table_th">
              <h6>Job ID</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Type</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Attached Count</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Inserted Count</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Removed Count</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Failed Count</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Updated Count</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Total Count</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Status</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Create On</h6>
            </th>
            <th className="datatable_table_th"></th>
          </thead>
          <tbody className="datatable_table_tbody">
            {getValue(props, `list`, []).map((item: any, index: number) => {
              return (
                <tr key={index} className="datatable_table_tr">
                  <td className="datatable_table_td" style={{ width: "70px" }}>
                    <ExpenseSvgComponent color={"#fabb2d"} size={22} />
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6 className=" color_primary">
                      {getValue(item, `job_id`, "-")}
                    </h6>
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6>{getValue(item, `type_formatted`, "-")}</h6>
                  </td>

                  <td className="datatable_table_td align-items-center">
                    <h6>{getValue(item, `attatched_count`, "-")}</h6>
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6>{getValue(item, `inserted_count`, "-")}</h6>
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6>{getValue(item, `removed_count`, "-")}</h6>
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6>{getValue(item, `failed_count`, "-")}</h6>
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6>{getValue(item, `updated_count`, "-")}</h6>
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6>{getValue(item, `total_count`, "-")}</h6>
                  </td>

                  <td className="datatable_table_td align-items-center">
                    <h6 style={getColorStyle(getValue(item, `status`, ""))}>
                      {getValue(item, `status`, "-")}
                    </h6>
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <p className="header_text__12">
                      {getValue(item, `created_at`, "-")}
                    </p>
                  </td>
                  <td
                    className="datatable_table_td align-items-center"
                    onClick={() =>
                      getValue(item, `url`, "")
                        ? handleDownloadOnClick(
                            getValue(item, `url`, ""),
                            getValue(item, `job_id`, "")
                          )
                        : dummy()
                    }
                  >
                    {getValue(item, `url`, "") ? (
                      <div className="d-flex align-items-center">
                        <DownloadSvgComponent color={"#408dfb"} size={26} />
                        <p className="small_text__14 color_primary">DOWNLOAD</p>
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Tooltip id="my-tooltip" place="bottom" />
      <Tooltip id="my-tooltip-document" place="bottom" />
    </div>
  );
}

export default FileLogsTable;
