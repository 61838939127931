import { getValue } from "@utils/lodash";
import React from "react";

function HotelSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="hotel_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          id="Path_46301"
          data-name="Path 46301"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_46302"
          data-name="Path 46302"
          d="M7,14a3,3,0,1,0-3-3A3,3,0,0,0,7,14Zm0-4a1,1,0,1,1-1,1A1,1,0,0,1,7,10ZM19,7H11v8H3V5H1V20H3V17H21v3h2V11A4,4,0,0,0,19,7Zm2,8H13V9h6a2.006,2.006,0,0,1,2,2Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#fd9933"
          }
        />
      </svg>
    </div>
  );
}

export default HotelSvgComponent;
