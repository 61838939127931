import BackSvgComponent from "@assets/svg-components/back";
import EyeSvgComponent from "@assets/svg-components/eye";
import InfoSvgComponent from "@assets/svg-components/info";
import { getColorStyle } from "@common/color";
import { formatText } from "@common/text-helpers";
import RejectCommentPopup from "@components/common/reject-popup";
import { getValue } from "@utils/lodash";
import { NavLink } from "react-router-dom";

function StickyHeader(props: any) {
  const { formInfo, handleSubmit, submitLoading, id, route } = props;
  const findCurrentApproverStaus = (arr: any) => {
    let user =
      getValue(localStorage, `user`, {}) &&
      JSON.parse(getValue(localStorage, `user`, {}));
    let app =
      getValue(arr, `length`, 0) > 0
        ? arr.filter(
            (item: object) =>
              getValue(item, `approver.id`, "") === getValue(user, `id`, "")
          )
        : [];
    return getValue(app, `length`, 0) > 0
      ? formatText(getValue(app, `[${0}].approval_status`, ""))
      : formatText(
          getValue(props, `formInfo.current_approver.approval_status`, "")
        );
  };
  const findCurrentApproverStausEnum = (arr: any) => {
    let user =
      getValue(localStorage, `user`, {}) &&
      JSON.parse(getValue(localStorage, `user`, {}));
    let app =
      getValue(arr, `length`, 0) > 0
        ? arr.filter(
            (item: object) =>
              getValue(item, `approver.id`, "") === getValue(user, `id`, "")
          )
        : [];
    return getValue(app, `length`, 0) > 0
      ? getValue(app, `[${0}].approval_status`, "")
      : getValue(props, `formInfo.current_approver.approval_status`, "");
  };
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <NavLink to={route ? `${route}` : "#"}>
              <BackSvgComponent />
            </NavLink>
            <p className="header_text"> {getValue(props, `title`, "")}</p>
            <div className="d-flex align-items-center">
              <h6
                className="header_text__16 ms-3"
                style={
                  getValue(props, `formInfo.approvers.length`, 0) > 0
                    ? getColorStyle(
                        findCurrentApproverStausEnum(
                          getValue(props, `formInfo.approvers`, [])
                        )
                      )
                    : getColorStyle(
                        getValue(props, `formInfo.approval_status`, "")
                      )
                }
              >
                {getValue(props, `formInfo.approvers.length`, 0) > 0
                  ? findCurrentApproverStaus(
                      getValue(props, `formInfo.approvers`, [])
                    )
                  : formatText(getValue(props, `formInfo.approval_status`, ""))}
              </h6>
              {getValue(props, `formInfo.approval_status`, "") ===
                "rejected" && (
                <RejectCommentPopup
                  header={
                    <div className="ms-2 cursor-pointer">
                      <EyeSvgComponent color={"#EC6861"} />
                    </div>
                  }
                  comment={getValue(props, `rejectComment.comments`, "")}
                />
              )}
              {/* {getValue(props, `formInfo.approval_status`, "") ===
                "sent_back" && (
                <RejectCommentPopup
                  header={
                    <div className="ms-2 cursor-pointer">
                      <EyeSvgComponent color={"#EC6861"} />
                    </div>
                  }
                  type="sent_back"
                  comment={getValue(props, `sent_backComment.comments`, "")}
                />
              )} */}
            </div>
          </div>

          <div className="d-flex align-items-center">
            {getValue(props, `histoyList.length`, 0) > 0 && (
              <div
                className="mx-2 border p-2 border-radius__5 d-flex align-items-center"
                onClick={props.toggleHistory}
              >
                <InfoSvgComponent color={"gray"} />
                <h6 className="header_text__16 cursor-pointer ms-1">History</h6>
              </div>
            )}
            {getValue(props, `permissions`, []).includes("update") && (
              <>
                {(getValue(props, `formInfo.approval_status`, "") ===
                  "recalled" ||
                  getValue(props, `formInfo.approval_status`, "") ===
                    "unsubmitted") && (
                  <div
                    className="header_square"
                    onClick={props.handleOpenPopup}
                  >
                    <img
                      src="/images/edit-gray.svg"
                      className="header_square_image img-fluid"
                    />
                  </div>
                )}
                {findCurrentApproverStausEnum(
                  getValue(props, `formInfo.approvers`, "")
                ) == "awaiting_approval" ? (
                  <>
                    <button
                      className={`ms-2 ascent-button ascent-button--header-buttons ascent-button--green`}
                      onClick={() => handleSubmit("approve")}
                      disabled={submitLoading}
                      style={{ fontWeight: "400" }}
                    >
                      Approve
                    </button>
                    <button
                      className={`ms-2 ascent-button ascent-button--header-buttons ascent-button--red`}
                      onClick={() => handleSubmit("reject")}
                      disabled={submitLoading}
                      style={{ fontWeight: "400" }}
                    >
                      Reject
                    </button>
                    <button
                      className={`ms-2 ascent-button ascent-button--header-buttons ascent-button--orange`}
                      onClick={() => handleSubmit("sent-back")}
                      disabled={submitLoading}
                      style={{ fontWeight: "400" }}
                    >
                      Send Back to user
                    </button>
                  </>
                ) : (
                  <div style={{ height: "48px" }}></div>
                )}
              </>
            )}
            {/* {findCurrentApproverStausEnum(
              getValue(props, `formInfo.approvers`, "")
            ) === "rejected" ? (
              <>
                <button
                  className={`ms-2 ascent-button ascent-button--header-buttons ascent-button--green`}
                  onClick={() => handleSubmit("approve")}
                  disabled={submitLoading}
                  style={{ fontWeight: "400" }}
                >
                  Approve
                </button>
              </>
            ) : (
              <div style={{ height: "48px" }}></div>
            )}

            {findCurrentApproverStausEnum(
              getValue(props, `formInfo.approvers`, "")
            ) === "approved" ? (
              <>
                <button
                  className={`ms-2 ascent-button ascent-button--header-buttons ascent-button--red`}
                  onClick={() => handleSubmit("reject")}
                  disabled={submitLoading}
                  style={{ fontWeight: "400" }}
                >
                  Reject
                </button>
              </>
            ) : (
              <div style={{ height: "48px" }}></div>
            )} */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default StickyHeader;
