import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import "./email-template.scss";
import { getValue } from "@utils/lodash";
import Loader from "@components/common/Loader";
import {
  createEmailTemplate,
  deleteEmailTemplate,
  getEmailTemplateKeys,
  getEmailTemplateTypes,
  getEmailTemplates,
  getSpecificEmailTemplate,
  updateEmailTemplate,
  updateExpenseEmailTemplateBulk,
} from "@services/email-template.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { useNavigate } from "react-router-dom";
import Notfound from "@components/Not-Found";
import { formatText } from "@common/text-helpers";
import PaginationPage from "@components/pagination/pagination";
import { toast } from "react-toastify";
import SearchHeader from "@components/common/SearchHeader";
import EmailTemplateEditPopup from "./add-popup";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
  convertFromRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import base64 from "base-64";
import SimpleReactValidator from "simple-react-validator";
import DeleteModal from "@common/popup/DeleteModal";
import GearLoader from "@components/common/GearLoader";
import EmailTemplateTable from "./table";
import NavigationBar from "@components/NotificationBar";
import NoAccessPage from "@components/common/NoAccess";
import RichTextEditor from "react-rte";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";

export default function EmailTemplate(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const [templateList, setTemplateList] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [typeList, setTypeList] = useState([]);
  const [request, setRequest] = useState({
    email_content: "",
    subject: "",
    // type: "EXECUTOR_REMAINDER_TEMPLATE",
    type: "",
  });
  const reset = () => {
    setRequest({
      ...request,
      email_content: "",
      subject: "",
      type: "",
    });
    // setEditorState(EditorState.createEmpty());
    setEditorState(RichTextEditor.createEmptyValue());
    setEditId("");
    setKeys({});
  };
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  useEffect(() => {
    //  const contentState = convertFromRaw(content);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
      getKeys(request.type);
      getTypes();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      getKeys(request.type);
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      let payload = {
        page_no: params.page_no ? parseInt(params.page_no) : 1,
        page_size: params.limit ? parseInt(params.limit) : limit,
        search_text: params.search_text,
      };
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading(true);
      let resp = await getEmailTemplates(queryRequest);
      if (resp) {
        setTemplateList(getValue(resp, `data.result`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [keysInfo, setKeys] = useState({});
  const getKeys = async (type: string) => {
    let payload = {
      // type: type ? type : "EXECUTOR_REMAINDER_TEMPLATE",
      type: type,
    };
    if (type) {
      let queryRequest = QueryRequestHelper(payload);
      try {
        let resp = await getEmailTemplateKeys(queryRequest);
        if (resp) {
          setKeys(getValue(resp, `data`, {}));
        } else {
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (getValue(request, `type`, "")) {
      getKeys(getValue(request, `type`, ""));
    }
  }, [getValue(request, `type`, "")]);

  const getTypes = async () => {
    try {
      let resp = await getEmailTemplateTypes("");
      if (resp) {
        setTypeList(
          getValue(resp, `data`, []).map((item: any) => ({
            value: item,
            label: formatText(item),
          }))
        );
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);

    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      // removing null values
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setSearchInput(e.target.value);
    let payload: any = {
      ...params,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteEntity = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteEmailTemplate(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Edit section                             */
  /* -------------------------------------------------------------------------- */

  const [editId, setEditId] = useState("");
  const handleEditEntity = async (id: string) => {
    setEditId(id);
    try {
      let resp = await getSpecificEmailTemplate(id);
      if (resp) {
        setRequest({
          ...request,
          subject: getValue(resp, `data.subject`, ""),
          type: getValue(resp, `data.type`, ""),
        });

        let content = base64.decode(getValue(resp, `data.email_content`, ""));

        // setEditorState(
        //   EditorState.createWithContent(
        //     ContentState.createFromBlockArray(convertFromHTML(content))
        //   )
        // );
        setEditorState(RichTextEditor.createValueFromString(content, "html"));
        setShowUpdatePopup(true);
      }
    } catch (error) {}
  };

  const handleUpdate = async (editId: string, status: boolean) => {
    try {
      let resp = await updateEmailTemplate(editId, { status: status });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
      }
    } catch (error) {}
  };
  /* ---------------------------  Insert Modal  ----------------------------- */

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const handlePopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
    setRequest({
      email_content: "",
      subject: "",
      // type: "EXECUTOR_REMAINDER_TEMPLATE",
      type: "",
    });
  };

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  const [editorState, setEditorState] = useState(
    RichTextEditor.createEmptyValue()
  );
  const onEditorStateChange = (editorState: any) => {
    // console.log(editorState.toString('html'));
    setEditorState(editorState);
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      // let data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      let data = editorState.toString("html");
      try {
        let payload = {
          email_content: base64.encode(data),
          subject: getValue(request, `subject`, ""),
          type: getValue(request, `type`, ""),
        };
        setSubmitLoading(true);
        if (editId) {
          let resp = await updateEmailTemplate(editId, payload);
          if (resp) {
            toast.success(getValue(resp, `message`, ""));
            setSubmitLoading(false);
            setShowUpdatePopup(false);
            getData();
            setEditId("");
          } else {
            setSubmitLoading(false);
          }
        } else {
          let resp = await createEmailTemplate(payload);
          if (resp) {
            toast.success(getValue(resp, `message`, ""));
            setSubmitLoading(false);
            setShowUpdatePopup(false);
            getData();
          } else {
            setSubmitLoading(false);
          }
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);

  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(templateList, `length`, 0) > 0
          ? templateList.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };
  const handleChangeActiveStatus = async (value: string) => {
    try {
      let resp = await updateExpenseEmailTemplateBulk({
        ids: selectedIDs,
        is_active: value === "active" ? true : false,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      } else {
        getData();
      }
    } catch (error) {}
  };
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  const handleNavigateUserEntity = () => {
    if (getValue(selectedIDs, `[${0}]`, "")) {
      // navigate(
      //   `/admin/user/entity-mapping/${getValue(selectedIDs, `[${0}]`, "")}`
      // );
    } else {
      toast.error("Please select Email Template");
    }
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      <>
        <div className="dashboard-wrapper__header">
          <h4 className="dashbaord-wrapper__header-title">Email Templates</h4>
        </div>
        {getValue(selectedIDs, `length`, 0) > 0 && (
          <NavigationBar
            selectedIDs={selectedIDs}
            // isMarkInactiveSectionVisible={true}
            handleChangeActiveStatus={handleChangeActiveStatus}
            text={"MARK INACTIVE"}
            handleRemoveNavItems={handleRemoveNavItems}
            multi={
              getValue(props, `permissions`, []).includes("update")
                ? true
                : false
            }
            permissions={getValue(props, `permissions`, [])}
            entityMapping={
              getValue(props, `products`, []).includes("SELFCOMP")
                ? true
                : false
            }
            handleNavigateUserEntity={handleNavigateUserEntity}
          />
        )}
        <div className="admin-wrapper position-relative">
          <div className="admin-wrapper position-relative">
            <SearchHeader
              searchInput={search_text}
              showSearchTextbox={showSearchTextbox}
              toggleSearch={toggleSearch}
              toggleSearchClose={toggleSearchClose}
              handleChangeSearch={handleChangeSearch}
              isAdd={
                getValue(props, `permissions`, []).includes("create")
                  ? true
                  : false
              }
              add={
                getValue(props, `permissions`, []).includes("create")
                  ? "true"
                  : "false"
              }
              handleAddFun={() => handlePopup()}
            />
          </div>
        </div>
        <div
          className={
            getValue(selectedIDs, `length`, 0) > 0
              ? "datatable_main_container_notifiction"
              : "datatable_main_container"
          }
          style={{ overflowY: isLoading ? "hidden" : "auto" }}
        >
          {!isLoading &&
          !getValue(props, `subTabPermissionList`, []).includes(
            "admin_user_controls_email_templates"
          ) ? (
            <NoAccessPage />
          ) : isLoading || !props.permissionAPITriggered ? (
            // <GearLoader />
            <SkeletonTable />
          ) : (
            <>
              {getValue(templateList, `length`, 0) > 0 ? (
                <>
                  <EmailTemplateTable
                    templateList={templateList}
                    selectedIDs={selectedIDs}
                    handleCheckUsers={handleCheckUsers}
                    handleCheckAll={handleCheckAll}
                    handleEditEntity={handleEditEntity}
                    handleDeleteFun={handleDeleteEntity}
                    permissions={getValue(props, `permissions`, [])}
                  />
                </>
              ) : (
                <>
                  {Object.keys(params).length !== 0 ? (
                    <Notfound />
                  ) : (
                    <Notfound />
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className="fixed_footer_wrapper d-flex justify-content-between">
          {templateList.length > 0 && (
            <img
              src="/images/building-illustration.svg"
              className="img-fluid"
            />
          )}
          {totalCount > 10 ? (
            <PaginationPage
              totalCount={totalCount}
              limit={limit}
              pageNumber={page_no}
              handleChangePagination={handleChangePagination}
              handleChangeLimit={handleChangeLimit}
            />
          ) : (
            <p className="show-rows-count mt-3 d-flex">
              Total Count:&nbsp;&nbsp;{" "}
              <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
            </p>
          )}
        </div>
      </>

      <EmailTemplateEditPopup
        showUpdatePopup={showUpdatePopup}
        setShowUpdatePopup={setShowUpdatePopup}
        keysInfo={keysInfo}
        request={request}
        handleChangeText={handleChangeText}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
        editId={editId}
        typeList={typeList}
        setRequest={setRequest}
        getKeys={getKeys}
        validator={simpleValidator}
        reset={reset}
      />
      <DeleteModal
        isOpen={isDelete}
        handleModal={handleDelete}
        handleSubmit={handleDeleteFunction}
        deleteValue={deleteValue}
      />
    </DashboardLayout>
  );
}
