import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";
import FlightSvgComponent from "@assets/svg-components/trips/flight";
import MealSvgComponent from "@assets/svg-components/trips/meal";
import SeatSvgComponent from "@assets/svg-components/trips/seat";
import {
  airTravelClass,
  airTravelType,
  mealType,
  seatType,
} from "@common/constants";
import { getValue } from "@utils/lodash";
import TripsPopup from "../../Dropdown/trip-popup";
import { findValueFromOptions, formatText } from "@common/text-helpers";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";

function TripsCreateFlightHeader(props: any) {
  const { request, setRequest } = props;
  const handleChangeState = (name: string, value: string) => {
    setRequest({
      ...request,
      [name]: value,
    });
  };

  return (
    <div className=" mt-4">
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div className="d-flex align-items-center trip-selector-wrapper">
          {airTravelType.map((item: object) => {
            return (
              <div className="trip-selector_checkbox-wrapper d-flex align-items-center">
                
                <label className="radio-button-container">
                  <input
                      type="radio"
                      className="form-radio"
                      checked={
                        getValue(item, `value`, "") ===
                        getValue(props, `request.air_travel_type`, "")
                          ? true
                          : false
                      }
                      onChange={() => {
                        handleChangeState(
                          "air_travel_type",
                          getValue(item, `value`, "")
                        );
                        props.hideValidation();
                      }}
                    />
                  <span className="checkmark"></span>

                  <div
                    className={`d-flex align-items-center border border-radius__5 trip-selector  cursor-pointer`}
                    onClick={() => {
                      handleChangeState(
                        "air_travel_type",
                        getValue(item, `value`, "")
                      );
                      props.hideValidation();
                    }}
                  >
                    <p className="small_text__14 checkbox-inner-label">
                      {getValue(item, `label`, "")}
                    </p>
                  </div>
                </label>
                
                {/* <input
                    type="radio"
                    className="form-radio"
                    checked={
                      getValue(item, `value`, "") ===
                      getValue(props, `request.air_travel_type`, "")
                        ? true
                        : false
                    }
                    onChange={() => {
                      handleChangeState(
                        "air_travel_type",
                        getValue(item, `value`, "")
                      );
                      props.hideValidation();
                    }}
                  /> */}
                {/* <div
                  className={`d-flex align-items-center border border-radius__5 trip-selector  cursor-pointer`}
                  onClick={() => {
                    handleChangeState(
                      "air_travel_type",
                      getValue(item, `value`, "")
                    );
                    props.hideValidation();
                  }}
                >
                  <p className="small_text__14 checkbox-inner-label">
                    {getValue(item, `label`, "")}
                  </p>
                </div> */}
              </div>
            );
          })}
        </div>
        <div className="d-flex align-items-center">
          {/* <TripsPopup
            header={
              <div
                className={`d-flex align-items-center border p-2 border-radius__5 mx-2 cursor-pointer`}
              >
                <FlightSvgComponent />
                <p className="small_text__14 ms-2">
                  {getValue(props, `request.class`, "")
                    ? formatText(getValue(props, `request.class`, ""))
                    : "Flight Preference"}
                </p>
                {getValue(props, `request.class`, "") ? (
                  <div
                    className="mx-1"
                    onClick={() => handleChangeState("class", "")}
                  >
                    <CloseSmallSvgComponent />
                  </div>
                ) : (
                  <ArrowDownSvgComponent color={"gray"} />
                )}
              </div>
            }
            options={airTravelClass}
            value={getValue(props, `request.class`, "")}
            onChange={(e: any) => handleChangeState("class", e)}
          /> */}
          {getValue(props, `travelModes.seat_preference`, false) && (
            <TripsPopup
              header={
                <div
                  className={`d-flex align-items-center border border-radius__5 cursor-pointer flight-preference-wrap`}
                >
                  <SeatSvgComponent />
                  <p className="small_text__14 flight-preference-wrap_text">
                    {getValue(props, `request.seat_type`, "")
                      ? formatText(getValue(props, `request.seat_type`, ""))
                      : "Seat Preference"}
                  </p>
                  {getValue(props, `request.seat_type`, "") ? (
                    <div
                      className="mx-1"
                      onClick={() => handleChangeState("seat_type", "")}
                    >
                      <CloseSmallSvgComponent />
                    </div>
                  ) : (
                    <ArrowDownSvgComponent color={"gray"} />
                  )}
                </div>
              }
              options={seatType}
              value={getValue(props, `request.seat_type`, "")}
              onChange={(e: any) => handleChangeState("seat_type", e)}
            />
          )}
          {getValue(props, `travelModes.meal_preference`, false) && (
            <TripsPopup
              header={
                <div
                  className={`d-flex align-items-center border border-radius__5 cursor-pointer flight-preference-wrap`}
                >
                  <MealSvgComponent />
                  <p className="small_text__14 ms-2">
                    {getValue(props, `request.meal_type`, "")
                      ? findValueFromOptions(
                          mealType,
                          getValue(props, `request.meal_type`, "")
                        )
                      : "Meal Preference"}
                  </p>
                  {getValue(props, `request.meal_type`, "") ? (
                    <div
                      className="mx-1"
                      onClick={() => handleChangeState("meal_type", "")}
                    >
                      <CloseSmallSvgComponent />
                    </div>
                  ) : (
                    <ArrowDownSvgComponent color={"gray"} />
                  )}
                </div>
              }
              options={mealType}
              value={getValue(props, `request.meal_type`, "")}
              onChange={(e: any) => handleChangeState("meal_type", e)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TripsCreateFlightHeader;
