import React from "react";
import TripsPopup from "../../Dropdown/trip-popup";
import { getValue } from "@utils/lodash";
import { findValueFromOptions } from "@common/text-helpers";
import { airTravelClass, timePreferences } from "@common/constants";
import { formHandleSelectArrayChangeByKey } from "@common/handleChange";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";

function TripFlightPreferenceSection(props: any) {
  const { item, index, validator, label, setItenaries, preference_key, flightPreference } = props;
  return (
    <div>
      <div className="d-flex">
        <label className="trip-label ms-0">{label}</label>
        <span className="form-label-error_red">*</span>
      </div>
      <TripsPopup
        header={
          <div
            className={`d-flex align-items-center ${
              validator &&
              validator.current.message(
                "Flight Preference",
                getValue(item, `${preference_key}`, ""),
                "required"
              )
                ? "border_red"
                : "border"
            } border-radius__5 cursor-pointer flight_preference_section justify-content-between`}
          >
            <div className="d-flex align-items-center">
              {/* <p className="small_text__14">{label}</p>
              <div className="vr mx-2"></div> */}
              <p className="small_text__14">
                {getValue(item, `${preference_key}`, "")
                  ? findValueFromOptions(
                    flightPreference,
                    // airTravelClass,
                      getValue(item, `${preference_key}`, "")
                    )
                  : "Flight Preference"
                  }
              </p>
            </div>
            {getValue(item, `${preference_key}`, "") ? (
              <div
                className="mx-1"
                onClick={() =>
                  formHandleSelectArrayChangeByKey(
                    index,
                    "",
                    preference_key,
                    setItenaries
                  )
                }
              >
                <CloseSmallSvgComponent />
              </div>
            ) : (
              <ArrowDownSvgComponent color={"gray"} />
            )}
          </div>
        }
        options={flightPreference} //-- Commented for Time being
        // options={airTravelClass}
        value={getValue(item, `${preference_key}`, "")}
        onChange={(e: any) =>
          formHandleSelectArrayChangeByKey(
            index,
            { value: e },
            preference_key,
            setItenaries
          )
        }
      />
    </div>
  );
}

export default TripFlightPreferenceSection;
